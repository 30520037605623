import axios from "axios";
import history from "../history";
import api from "./apis";

import {
 
  ERROR_MESSAGE,
  GET_USERS,
  LOADED,
  LOADING,
  SUCCESS_MESSAGE,
  VALIDATION_ERROR
} from "./type";

// ==========================Create==============
export const createUser = (data) => async (dispatch) => {
  
  dispatch({type: LOADING});
  const object = {
    name: data.name,
    email: data.email,
    mobile_number: data.mobile_number,
    password: data.password,
    authenticated: data.authenticated,
    access: data?.access,
  };

  try {
    const response = await api(localStorage.getItem("token")).post(
      "/user/create",
      { ...object }
    );

    if (response.status === 200) {
      
    dispatch({type: LOADED});
    dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
    return response.status;
    }
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const getUsers = () => async (dispatch) => {
  
  dispatch({type: LOADING});
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/user/all"
    );
    
    dispatch({type: LOADED});
    return ( response.data.users );
    
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const getUsersById = (id) => async (dispatch) => {
  
  dispatch({type: LOADING});
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/user?user=${id}`
    );
    
    dispatch({type: LOADED});
    return response.data.user
    // dispatch({ type: GET_USERS, payload: response.data.users });
    
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

// ========================Update user==========================
export const updateUser = (data, id) => async (dispatch) => {
  dispatch({type: LOADING});
  const object = {
    user: id,
    name: data.name,
    email: data.email,
    mobile_number: data.mobile_number,
    authenticated: data?.authenticated,
    remark:data.remark ? data.remark : '',
    access: data?.access,
  };
  dispatch({type: LOADED});

  try {
    const response = await api(localStorage.getItem("token")).patch("/user/update",{ ...object });

    if (response.status === 201) {
      
    dispatch({type: LOADED});
    dispatch({type: SUCCESS_MESSAGE,payload: "User is updated succesfully"});
    
    return response.status;
    }
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const updateUserPassword = (user, data ) => async (dispatch) => {
  dispatch({type: LOADING});

  const object = {
    user:user,
    password:data.password,
    confirm_password: data.confirm_password,
  };
  dispatch({type: LOADED});

  try {
    const response = await api(localStorage.getItem("token")).patch("/user/password/update",{ ...object });

    if (response.status === 201) {
      
    dispatch({type: LOADED});
    dispatch({type: SUCCESS_MESSAGE,payload: "User password is updated successfully"});
    return response.status
    }
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const deleteUser = ( user ) => async (dispatch) => {
  dispatch({type: LOADING});
  console.log(user)

  try {
    const response = await api(localStorage.getItem("token")).patch(
        "/user/delete",
        { user: user }
      );

    if (response.status === 201) {
      
      dispatch({type: LOADED});
      dispatch({type: SUCCESS_MESSAGE,payload: "User is deleted succesfully"});
      
      return response.status
    }
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const updateAuth = (user, auth) => async (dispatch) => {
  dispatch({type: LOADING});
  
  const object = {
    user: user,
    authenticated: auth,
  };
  dispatch({type: LOADED});

  try {
    const response = await api(localStorage.getItem("token")).patch("/user/authenticate",{ ...object });

    if (response.status === 201) {
      
    dispatch({type: LOADED});
    dispatch({type: SUCCESS_MESSAGE,payload: "User updated succesfully"});
    
    return response.status
    }
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const updateOwnPassword = (user, old_password, password, confirm_password) => async (dispatch) => {
  dispatch({type: LOADING});

  const object = {
    user: user,
    old_password: old_password,
    password: password,
    confirm_password: confirm_password,
  };
  
  dispatch({type: LOADED});

  try {
    const response = await api(localStorage.getItem("token")).patch("/user/password/update-self",{ ...object });

    if (response.status === 201) {
      
    dispatch({type: LOADED});
    dispatch({type: SUCCESS_MESSAGE,payload: "Your password is updated succesfully"});
    localStorage.setItem('token', response.data.token)
    return response.status;
    // history.push('/userList')
    }
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};