import React, { useState, useRef } from 'react';
import { Button, Descriptions,Empty , Select, Form, Row, Col, Tabs, Tag, Popconfirm } from 'antd';
import { CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import {getGponDetails, updateGponLocation, deleteCable, deleteGpon, cableHandle} from '../../action/gpon'
import {connect}  from 'react-redux'
import * as L from "leaflet";
import {
    useMapEvents,
  } from 'react-leaflet'
import { assignCore, removeCore } from '../../action/gpon';
import { PointBox } from '../../styles/globalStyle';

import DeleteConfirmation from '../../components/deleteConfirmation';
import './style.css'

const { TabPane } = Tabs;
const { Option } = Select;

const InCable = ({
    mapData,
    details, closeFnc, 
    visible, refresh, updateGponLocation, deleteCable,
    deleteFnc, refreshApi, deleteGpon, cableHandle, assignCore, removeCore,
    }) => {
        const LeafIcon = L.Icon.extend({
            options: {
              iconSize:     [40, 40],
           }
          });
        const icon = (data) => new LeafIcon({
            iconUrl:  `https://cdn.iconscout.com/icon/free/png-256/location-marker-1719247-1460733.png`,
            iconSize: [20, 20],
            iconAnchor: [12, 12],
            popupAnchor: [0, 0],
          })
    // const [id, setId] = useState(null);
    const [editDesData, setEditDesData] = useState(null);
    const [editDesModalVisible, setEditDesModalVisible] = useState(false);
    const [position, setPosition] = useState(null);
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const [core1, setCore1] = useState(null);
    const [core2, setCore2] = useState(null);
    const [cableId1, setCableId1] = useState(null);
    const [cableId2, setCableId2] = useState(null);
    const [keyNum, setKeyNum] = useState(null);
    const [refCable, setRefCable] = useState(null);
    const ref = useRef(null);
    const submitRef = useRef(null);

    const LocationFinderDummy = () => {
        const map = useMapEvents({
            click(e) {
                form.setFieldsValue({
                    gpon_id: details?.detail?.id,
                    lat : e.latlng.lat,
                    long : e.latlng.lng,
                }); 
              setPosition([e.latlng.lat, e.latlng.lng])
            },
        });
        return null;
    };
    
    const renderContent = (column = 3) => (
        <Descriptions size="small" column={column}>
          <Descriptions.Item label="Id"><strong>{details?.detail?.gpon_id}</strong></Descriptions.Item>
          <Descriptions.Item label="Address"><strong>{details?.detail?.address}</strong></Descriptions.Item> 
          <Descriptions.Item label="Description"><strong>{details?.detail?.description}</strong></Descriptions.Item>
        </Descriptions>
      );
      
    //   const Content = ({ children, extra }) => (
    //     <div className="content">
    //       <div className="main">{children}</div>
    //       <div className="extra">{extra}</div>
    //     </div>
    //   );
      const handleEdit = (data) => {
        setEditDesData(data?.detail)
        setEditDesModalVisible(true)
      }
    //   const handleCableConnectionEdit = (data, p) => {
    //     setPoint(p)
    //     setEditConnectionData(data)
    //     setEditConModalVisible(true)
    //   }
    //   const handleEdit = (id) => {
    //     setId(id); 
    //   }
    //   const handleDelete = async (id, point) => {
    //     const res =  await removeCableConnection(id, point)
    //     if(res === 201){
    //         refresh(id)
    //     }
    //   }
    const handleRemove = async (id) => {
        const res = await deleteCable(details?.detail?.id, id)
        if(res === 201){
            refresh(details?.detail?.id)
        }
    }
    const handleCableRemove = async (type) => {
        const res = await cableHandle(details?.detail?.id, details?.detail?.in_cable?.id, 'remove')
        if(res === 201){
            refresh(details?.detail?.id,)
        }
    }
    
      const onFinish = async(values) => {
        const res = await updateGponLocation(values)
        if(res === 201){
            refresh(details?.detail?.id)
            refreshApi()
        }
      }

    const handleCount = (item) => {
    let c = 0;
        c = c + item?._id?.cable_length
        item?.connection?.map((con) => {
            c =  c + con?.cable_length
        })
    
        return c
    }

    const capitalizeFirst = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    
    const handleData = (data) => {
        mapData(data)
        closeFnc(false)
    }

    const deleteContent = (id) => 
        ( <DeleteConfirmation id={id} fnc={deleteFnc} closeFnc={() => setOpen(false)} />
        )

        const constrolAssign = (data, key, cable_id) => {
            setKeyNum(key)
            if(core1 === null && core2 === null){
                
                setCore1(data)
                setCableId1(cable_id)
                if(core2 !== null){
                    submitRef.current.focus();
                }
            }
            else if(core1 !== null && core2 === null){
                setCore2(data)
                setCableId2(cable_id)
                if(core1 !== null){
                    submitRef.current.focus();
                }
            }
            else if(core2 !== null && core1 === null){
                setCore1(data)
                setCableId1(cable_id)
                if(core2 !== null){
                    submitRef.current.focus();
                }
            }
            else return
    }
    
    const handleAssign = async (id, state) => {
        if(state === 'assign'){
            const res = await assignCore(details?.detail?.id, id, 'gpon')
            if(res === 201){
                refresh(details?.detail?.id)
            }
        }
    
        if(state === 'remove'){ 
            const res = await removeCore(details?.detail?.id, id, 'gpon')
            if(res === 201){
                refresh(details?.detail?.id)
            }
        }
    }
      
    const removeAssign = async (data) => {
        const res = await removeCore(data?.id, data?.r?.id)
        if(res === 201){
            setCore1(null)
            setCore2(null)
            setCableId1(null)
            setCableId2(null)
            setKeyNum(null)
            refresh(details?.detail?.id)
        }
        refreshApi()
    
    }
    const handleClick = () => {
        ref.current.focus();
        };
    return ( 
        <>
       
       {details?.detail?.in_cable ? 
                        <Row>
                            <Col xs={24} >
                                <Row style={{boxShadow: '1px 1px 4px 1px rgba(0,0,0,0.2)',padding: '5px', margin: '5px 0px'}}>
                                <Col xs={24}>
                                    <PointBox>
                                        <div style={{ width: '100%'}}>
                                            <h4>Input Cable 

                                          
                                            </h4>
                                        </div>
                                    </PointBox>
                                </Col>
                                <Col xs={8}>
                                    <PointBox>
                                        <div>
                                            <h4>Cable Details</h4>
                                            <h2>Cable Id: <strong>{details?.detail?.in_cable?.cable_id}</strong></h2>
                                            <h2>Core: <strong>{details?.detail?.in_cable?.core}</strong></h2>
                                            <Popconfirm title="Are you sure?" okText="Yes" cancelText='No' onConfirm={() => handleRemove(details?.detail?.in_cable?.id)}>
                                                <Button danger type='primary'>Delete Cable</Button>
                                            </Popconfirm>
                                            &nbsp;
                                            <Popconfirm title="Are you sure?" okText="Yes" cancelText='No' onConfirm={() => handleCableRemove()}>
                                                <Button danger type='primary'>Input Cable Remove</Button>
                                            </Popconfirm>
                                        </div>
                                    </PointBox>
                                </Col>
                                {details?.connection?.map(item => {
                                    if(item?.cable?.id === details?.detail?.in_cable?.id){
                                        return (
                                        <Col xs={16}>
                                            <PointBox>
                                                <div > 
                                                    <h4>Core Details &nbsp; </h4>
                                                    {item?.cores?.map(item => (
                                                        <p>Sub Cabel - {item?.sub_cable_no}  &nbsp;
                                                            {item?.connected ? 
                                                                <Tag color={item?.color === 'white' ? 'black' : item?.color} ><span> Used</span></Tag>
                                                            :
                                                            <Tag color={item?.color === 'white' ? 'black' : item?.color} className='glow-on-hover' style={{ color: item?.color === 'white' ? 'black' : item?.color }}><span> Unused</span></Tag>
                                                            }
                                                        {/* <Tag color={item?.color}><span>{item?.connected ? ' Used' : ' Unused'}</span></Tag> */}
                                                        {item?.connected ?
                                                        <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => {handleAssign(item?.id,'remove')}}>
                                                            <Button  size="small" icon={<DeleteOutlined />} type="primary"danger title='Remove'>Remove</Button>
                                                        </Popconfirm>
                                                        :
                                                        <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => {handleAssign(item?.id,'assign')}}>
                                                            <Button  size="small" icon={<CheckCircleOutlined />} type="primary" title='Assign'>Assign</Button>
                                                        </Popconfirm>
                                                        }
                                                        
                                                        <span>
                                                                    {item?.connection[0] ?
                                                                    <>
                                                                    Connected with - <strong>{ item?.connection[0]?.connection_model} ({ item?.connection[0]?.connection_name})</strong> 
                                                                    </>
                                                                    : 'Not Connected'}
                                                                </span>
                                                        <br /></p>
                                                    ))}
                                                </div>
                                            </PointBox>
                                        </Col>
                                        )
                                    }
                                })}
                            {/* <Col xs={16}>
                                <PointBox>
                                    <div>
                                        <h4>Core Details &nbsp; </h4>
                                        {con?.cores?.map((item, key) => (
                                            item?.r 
                                                ? 
                                                <Selected >
                                                    
                                                    <p style={ 
                                                        refCable === item?.id 
                                                        ? 
                                                        {backgroundColor: '#1abc9c', cursor: 'pointer'}
                                                         : 
                                                         refCable === item?.r?.id ?
                                                         {backgroundColor: '#1abc9c', cursor: 'pointer'}
                                                         :
                                                         {cursor: 'pointer'}
                                                        } onClick={() => {setRefCable(item?.r?.id);handleClick();}}>Tube -{item?.sub_cable_no}  &nbsp; 
                                                    <Tag 
                                                        color={item?.color}
                                                        
                                                        >
                                                            {refCable === item?.id ?
                                                                <InputCore value=" " ref={ref} id="message"  autocomplete="off" />
                                                            : null
                                                        }
                                                        <span>Connected</span></Tag>
                                                  
                                                    

                                                    {core1 === null || core2 === null ?
                                                        keyNum === id ?
                                                        <Button size="small"disabled>Remove</Button>
                                                        :
                                                    <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => {removeAssign(item)}}>
                                                        <Button size="small"  type="primary"danger >Remove</Button>
                                                    </Popconfirm>
                                                    :
                                                    null
                                                    }
                                                    
                                                    {core1 !== null && core2 !== null ?
                                                   <Button onClick={() => constrolAssign(item, id, con?.cable?.cable_id)}  size="small"  type="primary" danger disabled >Select</Button>
                                                    :
                                                    null
                                                    }
                                                    
                                                    
                                                    
                                                    <span style={{ float: 'right'}}>
                                                        {item?.connection[0] ?
                                                        <>
                                                        Connected with - <strong>{ item?.connection[0]?.connection_model} ({ item?.connection[0]?.connection_name})</strong> 
                                                        </>
                                                        : 'Not Connected'}
                                                    </span>
                                                    <br />
                                                    </p>
                                                   
                                                    
                                                </Selected>
                                                :

                                                <Selected status ={core1?.id === item?.id || core2?.id === item?.id}  >
                                                    <p>Tube -{item?.sub_cable_no}  &nbsp; 
                                                    {item?.r ? 
                                                        <Tag color={item?.color} ><span> Used</span></Tag>
                                                    :
                                                    <Tag color={item?.color} className='glow-on-hover' style={{ color: item?.color}}><span> Unused</span></Tag>
                                                    }
                                                    {core1 === null || core2 === null ?
                                                        keyNum === id ?
                                                        <Button onClick={() => constrolAssign(item, id, con?.cable?.cable_id)}  size="small"  type="primary" disabled>Select</Button>
                                                        :
                                                    <Button onClick={() => constrolAssign(item, id, con?.cable?.cable_id)}  size="small"  type="primary" >Select</Button>
                                                    :
                                                    null
                                                    }
                                                    
                                                    {core1 !== null && core2 !== null ?
                                                    <Button onClick={() => constrolAssign(item, id, con?.cable?.cable_id)}  size="small"  type="primary"  disabled >Select</Button>
                                                   
                                                    :
                                                    null
                                                    }
                                                    
                                                    <span style={{ float: 'right'}}>
                                                        {item?.connection[0] ?
                                                        <>
                                                        Connected with - <strong>{ item?.connection[0]?.connection_model} ({ item?.connection[0]?.connection_name}) </strong> 
                                                        </>
                                                        : 'Not Connected'}
                                                    </span>
                                                    <br />
                                                    </p>
                                                    
                                                </Selected>
                                            
                                        ))}
                                    </div>
                                    
                                </PointBox>
                            </Col> */}
                            
                                </Row>
                            </Col>
                            
                            
                        </Row>
                    : 
                    
                    <Row>
                    <Col xs={24} >
                        <Row style={{boxShadow: '1px 1px 4px 1px rgba(0,0,0,0.2)',padding: '5px', margin: '5px 0px'}}>
                        <Col xs={24}>
                            <PointBox>
                                <div>
                                    <h4>Input Cable 
                                   
                                    </h4>
                                </div>
                            </PointBox>
                        </Col>
                        <Col xs={24}>
                            <Empty />
                        </Col>
                    
                        </Row>
                    </Col>
                    
                    
                </Row>}
                
       
        </>
    );
    }
 
export default connect(null,{getGponDetails, updateGponLocation, assignCore, removeCore, deleteCable, deleteGpon, cableHandle})(InCable);