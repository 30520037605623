import React, { Component } from "react";
import styled from 'styled-components';
import { resetMessage } from "../action/reset";
import { Alert,message } from 'antd';
import { connect } from "react-redux";
const Msg = styled.div`
  position: fixed;
  right: 100px;
  top:0px;
  width: 400px;
  z-index: 10000000;
    animation: mymove 1s  ;

  @keyframes mymove {
   0%{top: -100px}
  
   
   100%{top: 0px}
}
`;

class MessageAlert extends Component {
  state = {
    show: false,
    message: null,
  };
  handleShow = () => {
    this.setState({ show: !this.state.show });
  };
  componentDidMount() {
    // this.setState({ message: this.props.value });
    setTimeout( () =>{ this.props.resetMessage()}, 3000);
  } 
  onClose = (e) => {
    console.log(e, 'I was closed.');
  };
 
  render() {
    const { type, value } = this.props;
    return (
      <Msg>
        {/* <Alert
      message="Error Text"
      description={this.state.message}
      type="error"
      closable
      onClose={this.onClose}
    /> */}
    {type === 'error' ? message.error(this.props.value) : message.success(this.props.value)}
          {/* <Alert style={{width: '80%', padding: '0px 10px', marginTop: '10px'}} variant="outlined" severity={type} variant="filled" onClose={() => {this.props.resetMessage()}}>
            {this.state.message}
          </Alert> */}
      </Msg>
    );
  }
}

export default connect(null, { resetMessage })(MessageAlert);
