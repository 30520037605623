import React from 'react'
import { Button, Descriptions,Input, Select, PageHeader, Form, Row, Col, Tabs, Modal, Tag } from 'antd';
import { useEffect } from 'react';
import {connect} from 'react-redux';
import {updateNttnDetails} from '../../action/nttn';


const { Option } = Select;
const NttnDetailsUpdate = ({ 
    visible, onCancel, data, 
    updateNttnDetails, refresh,
}) => {
    useEffect(() => {
        form.setFieldsValue({
            id : data?.nttn_id,
            nttn_id : data?.id,
            name : data?.name,
            address : data?.address,
            active : data?.active,
            description : data?.description,
          });
    }, [])
    
    const [form] = Form.useForm();

    const onFinish = async (values) => {
        const code = await updateNttnDetails(values, data?.nttn_id)
        if(code === 201){
            onCancel(false)
            refresh(data?.id)
        }
      }
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
    return ( 
        <Modal title="Edit Nttn" visible={visible} onCancel={() =>onCancel(false)}
            okButtonProps={{
                style: {
                display: "none",
                },
            }}
          >
            <Form
                  name="basic"
                  labelCol={{
                  span: 8,
                  }}
                  wrapperCol={{
                  span: 16,
                  }}
                  initialValues={{
                  remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  form={form}
              >
                      {/* <Form.Item
                          label="ID"
                          name="id"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Input />
                      </Form.Item> */}
                      <Form.Item
                          label="Client ID"
                          name="nttn_id"
                          rules={[{ required: true, message: 'Required' }]}
                          style={{display: 'none'}}
                      >
                          <Input />
                      </Form.Item>
                      <Form.Item
                          label="Name"
                          name="name"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Input />
                      </Form.Item>
                      <Form.Item
                          label="Address"
                          name="address"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Input.TextArea />
                      </Form.Item>

                      {/* <Form.Item
                          label="Status"
                          name="active"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Select  >
                            <Option value={true}>Active</Option>
                            <Option value={false}>Inactive</Option>
                          </Select>
                      </Form.Item> */}
                      
                      <Form.Item
                          label="Description"
                          name="description"
                      >
                          <Input.TextArea />
                      </Form.Item>

                      <Form.Item
                          wrapperCol={{
                          offset: 8,
                          span: 16,
                          }}
                      >
                          <Button type="primary" htmlType="submit">
                          Submit
                          </Button>
                      </Form.Item>
              </Form>
        </Modal>
     );
}
 
export default connect(null,{updateNttnDetails})(NttnDetailsUpdate);