import styled from "styled-components";

export const MContainer = styled.div`
    background-color: #FFF;
`;
export const SideContainer = styled.div`
    background-color: #FFF;
    height: 100vh ;
    padding: 10px ;
    
`;

export const Box1 = styled.div`
    width: 100px;
    height: 30px;
    background-color: Blue;
    align-self: center ;
    /* margin: 10px ; */
`;
export const Box2 = styled.div`
    width: 100px;
    height: 30px;
    background-color: Orange;
    align-self: center ;
    /* margin: 10px ; */
`;
export const Box3 = styled.div`
    width: 100px;
    height: 30px;
    background-color: Green;
    align-self: center ;
    /* margin: 10px ; */
`;
export const Box4 = styled.div`
    width: 100px;
    height: 30px;
    background-color: Brown;
    align-self: center ;
    /* margin: 10px ; */
`;
export const Box5 = styled.div`
    width: 100px;
    height: 30px;
    background-color: #8fb190;
    align-self: center ;
    /* margin: 10px ; */
`;
export const Box6 = styled.div`
    width: 100px;
    height: 30px;
    background-color: Black;
    align-self: center ;
    /* margin: 10px ; */
`;
export const Box7 = styled.div`
    width: 500px;
    height: 30px;
    background-color: Red;
    align-self: center ;
    /* margin: 10px ; */
`;

export const CoreBox = styled.div`
    position: absolute;
    top: 100;
    right: 300px;
    margin-left: 100px;
    background-color: #ffffff;
    z-index: 1000;
    border: 1px solid red;
    border-radius: 10px;
    width: 150px;
    padding: 10px;
    font-size: 10px;
`;

export const Black = styled.span`
    padding: 2px 10px;
    background-color: #000000;
`;

export const Yellow = styled.span`
padding: 2px 10px;
    background-color: yellow;
`;

export const Blue = styled.span`
padding: 2px 10px;
    background-color: blue;
`;

export const Red = styled.span`
    padding: 2px 10px;
    background-color: red;
`;

export const PopWrapper = styled.div`
    /* margin: 15px 0px; */
    cursor: pointer;
    padding: 5px  ;
    transition: .2s all ;
    box-shadow: ${(props) => props.active ? '0px 2px 2px -2px rgba(0,0,0,0.75)' : null};
    :hover{
        box-shadow: 0px 2px 2px -2px rgba(0,0,0,0.75);
    cursor: pointer;
    }
    h6{
    color: #000000;
    }
`

export const SideDataBar = styled.div`
    position: absolute;
    top: 75px;
    left: 0px;
    height: 100vh;
    width: 220px;
    background-color: #ffffff;
    z-index: 1000;
    padding: 10px;
`

export const BottomBtn = styled.button`
    position: absolute;
    bottom:20px ;
    width: 90% ;
    margin: auto;
    border: none;
    padding: 10px 12px ;
    background-color: #1565A6;
    color: #FFF;
    cursor: pointer;
`

