import styled, { createGlobalStyle } from "styled-components";

    const GlobalStyles = createGlobalStyle`
    *{
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
        font-family: 'Mulish',Arial, Helvetica, sans-serif;
    }
    button{
        }

`;
export default GlobalStyles;


export const FlexContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
`;

export const Container = styled.div`
    margin-top: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: ${props => props.name === 'detail' ? '10px 150px' : '10px 20px'};
    
    @media only screen and (max-width: 600px) {
        
    padding: ${props => props.name === 'detail' ? '10px 20px' : '10px 20px'};
    }
`;

export const HeaderText = styled.h1`
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    padding: 20px 0px;
`;

export const SubHeaderText = styled.p`
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    padding: 20px 0px;
`;

export const Card = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    padding: 15px 20px;
    border-radius: 10px;
    box-shadow: 1px 1px 4px 1px rgba(0,0,0,0.2);
    -webkit-box-shadow: 1px 1px 4px 1px rgba(0,0,0,0.2);
    -moz-box-shadow: 1px 1px 4px 1px rgba(0,0,0,0.2);
    margin: 15px 0px;
        h1{
            font-weight: 600 ;
            padding: 0px 10px ;
        }
        min-height: 350px;
        ul{
            padding: 10px ;
        }
        li{
            list-style-type: none;
            margin: 15px 0px ;
            border-left: 5px solid green ;
            padding: 0px 5px ;
        }
`;
export const PopBox = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    h4{
        align-self: center;
        text-align: right ;
    }
`;
export const DetailBox = styled.div`
    width: auto ;
`;


export const PointBox = styled.div`
display:  flex;
justify-content: center;
flex-direction: column;
/* align-items: center; */
    /* min-height: 300px; */
div{
    /* align-self: center ; */
    width: 90%;
    height: auto ;
}
    h4{
        font-weight: bold;
        text-align: center;
        align-self: center;
        font-size: 18px;
        background-color: #888b83;
        color: #FFF;
    }
    h2{
        font-size: 18px;
    }
    p{
        text-align: center;
        margin: 3px;
    }
`;
export const ConnectionBox = styled.div`
    display: flex;
    justify-content: center ;
    align-items: center;
        /* background-color: ${ props => props.clr ? 'red' : ''}; */
    p{
        padding: 5px 10px ;
        box-shadow: 0px 2px 18px -10px rgba(0,0,0,0.75);
        /* background-color: ${ props => props.clr ?`${props.color}` : 'red'}; */
    }
`;

export const Selected = styled.div`
    background-color: ${ props => props.status ? '#a4df68' : 'null'};
`;
export const InputCore = styled.input`
    border:  none;
    outline: none;
    background-color: inherit;
    width: 0px ;
    margin: 0px ;
    cursor: pointer;
`;

export const ScrollAble = styled.div`
    height: ${props => props.height ? props.height : '400px'};
    max-height: ${props => props.maxHeight ? props.maxHeight : '400px'};
     z-index: ${props => props.zIndex ? props.zIndex : '10010'};
     background-color: ${props => props.backgroundColor ? props.backgroundColor : null};
     padding: ${props => props.padding ? props.padding : null};
     width: ${props => props.width ? props.width : null};
     min-width: ${props => props.minWidth ? props.minWidth : null};
      overflow: auto;
        /* overflow-x: auto; */
        /* white-space: nowrap; */
      /* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1565A6;
  border-radius: 10px;
}
`;
export const PopWrapper = styled.div`
    /* margin: 15px 0px; */
    cursor: pointer;
    padding: 5px  ;
    transition: .2s all ;
    box-shadow: ${(props) => props.active ? '0px 2px 2px -2px rgba(0,0,0,0.75)' : null};
    :hover{
        box-shadow: 0px 2px 2px -2px rgba(0,0,0,0.75);
    cursor: pointer;
    }
    h6{
    color: #000000;
    }
`