import { Button, Col, Popconfirm, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import * as L from "leaflet";
import { Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import {
  DeleteOutlined,
  EditOutlined,
  EyeFilled,
  RightOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { PopBox } from "../../styles/globalStyle";
import ClientDetails from "./gponDetails";
import { getGponDetails, deleteGpon } from "../../action/gpon";
import { getJunctionDetails } from "../../action/junction";
import { getPopDetails } from "../../action/pop";
import JunctionDetails from "../junction/junctionDetails";
import PopDetails from "../pop/popDetails";

const { TabPane } = Tabs;

const GponMarker = ({
  data,
  mapData,
  mData,
  single,
  isFocus,
  isFocusFnc,
  getPopDetails,
  getJunctionDetails,
  getGponDetails,
  deleteGpon,
  refreshFnc,
  refreshApi,
  fnc1,
  fnc2,
  fnc3,
}) => {
  const [details, setDetails] = useState(null);
  const [popDetails, setpopDetails] = useState(null);
  const [junctiondetails, setJunctionDetails] = useState(null);
  const [visible, setVisible] = useState(false);

  const LeafIcon = L.Icon.extend({
    options: {
      iconSize: [40, 40],
    },
  });
  const jponicon = (data) =>
    new LeafIcon({
      iconUrl: `./gpon_icons/gpon${data}.png`,
      iconSize: [20, 20],
      iconAnchor: [12, 12],
      popupAnchor: [0, 0],
    });
  const jponiconlg = (data) =>
    new LeafIcon({
      iconUrl: `https://fancomni.ca/wp-content/uploads/2016/07/Gigabit-passive-optical-network-GPON-01.png`,
      iconSize: [30, 30],
      iconAnchor: [15, 21],
      popupAnchor: [0, 0],
    });
  const junctionicon = (data) =>
    new LeafIcon({
      iconUrl: `./Junction.png`,
      iconSize: [20, 20],
      iconAnchor: [12, 12],
      popupAnchor: [0, 0],
    });
  const junctioniconlg = (data) =>
    new LeafIcon({
      iconUrl: `https://cdn.iconscout.com/icon/free/png-256/location-marker-1719247-1460733.png`,
      iconSize: [40, 40],
      iconAnchor: [23, 35],
      popupAnchor: [0, 0],
    });
  const popicon = (data) =>
    new LeafIcon({
      iconUrl: `./POP.png`,
      iconSize: [20, 20],
      iconAnchor: [12, 12],
      popupAnchor: [0, 0],
    });

  const popiconlg = (data) =>
    new LeafIcon({
      iconUrl: `https://cdn.iconscout.com/icon/free/png-256/location-marker-1719247-1460733.png`,
      iconSize: [40, 40],
      iconAnchor: [23, 35],
      popupAnchor: [0, 0],
    });

  const handleDelete = async (id, pass) => {
    const res = await deleteGpon(id, pass);
    if (res === 201) {
      refreshFnc(id);
      setVisible(false);
      setDetails(null);
      setJunctionDetails(null);
      setpopDetails(null);
    }
  };

  const handleView = async (name, id) => {
    if (name === "pop") {
      const res = await getPopDetails(id);
      if (res) {
        setpopDetails(res);
        setVisible(true);
        setJunctionDetails(null);
        setDetails(null);
        isFocusFnc(false);
      }
    } else if (name === "junction") {
      const res = await getJunctionDetails(id);
      if (res) {
        setJunctionDetails(res);
        setVisible(true);
        setDetails(null);
        setpopDetails(null);
        isFocusFnc(false);
      }
    } else {
      const res = await getGponDetails(id);
      if (res) {
        setDetails(res);
        setVisible(true);
        isFocusFnc(false);
      }
    }
  };

  const refresh = async (id) => {
    const res = await getGponDetails(id);
    if (res) {
      setDetails(res);
    }
  };

  const RecenterAutomatically = ({ lat, lng }) => {
    const map = useMap();
    useEffect(() => {
      map.setView([lat, lng]);
    }, [lat, lng]);
    return null;
  };

  return (
    <>
      {/* ================ Focus Marker ============== */}
      {/* {mData?.connection ?
          mData?.connection?.map((item, i) => (
            <Marker key={i} position={item?.connection_point?.location} 
            icon={
              item?.connection_point?.entity === "junction" ? junctioniconlg() :
              item?.connection_point?.entity === "pop" ? popiconlg() :
               jponiconlg()
              }
               eventHandlers={{
                click: () => {
                  fnc1(item); fnc2(item?.position); fnc3("2");
                  handleView(item?.connection_point?.entity, item?.connection_point?.id)
                }
              }}
            >
                </Marker>
          ))
        :null} */}
      {data?.map((item, i) => (
        <>
          <Marker
            title={item?.gpon_id + "----" + item?.name}
            key={i}
            position={item?.position}
            icon={jponicon(item.splitter)}
            eventHandlers={{
              click: () => {
                fnc1(item);
                fnc2(item?.position);
                fnc3("5");
                handleView("gpon", item?.id);
              },
              mouseover: (e) => {
                e.target.openPopup();
              },
              mouseout: (e) => {
                e.target.closePopup();
              },
            }}
          >
            {/* {console.log("jponicon", item)} */}
            <Popup minWidth="300">
              <Row style={{ minWidth: "250px" }}>
                <Col xs={8}>
                  <h3>Gpon Id</h3>
                </Col>{" "}
                <Col xs={16}>
                  <h3>
                    : <strong>{item?.client_id}</strong>
                  </h3>
                </Col>
                <Col xs={8}>
                  <h3>Name</h3>
                </Col>{" "}
                <Col xs={16}>
                  <h3>
                    : <strong>{item?.name}</strong>
                  </h3>
                </Col>
                <Col xs={8}>
                  <h3>Address</h3>
                </Col>{" "}
                <Col xs={16}>
                  <h4>
                    : <strong>{item?.address}</strong>
                  </h4>
                </Col>
              </Row>
            </Popup>
          </Marker>
        </>
      ))}

      {visible ? (
        <ClientDetails
          details={details}
          visible={visible}
          refreshApi={refreshApi}
          closeFnc={() => setVisible(false)}
          refresh={refresh}
          deleteFnc={handleDelete}
          mapData={mapData}
        />
      ) : null}
      {single?.position && single?.gpon_id ? (
        isFocus ? (
          <>
            <Marker
              title={single?.name}
              position={single?.position}
              icon={jponicon()}
              eventHandlers={{
                click: () => {
                  fnc1(single);
                  fnc3("1");
                  fnc2(single?.position);
                  handleView("client", single?.id);
                },
              }}
            >
              {/* <RecenterAutomatically lat={single?.position[0]} lng={single?.position[1]} /> */}
            </Marker>
          </>
        ) : (
          <>
            <Marker
              title={single?.name}
              position={single?.position}
              icon={jponiconlg()}
              eventHandlers={{
                click: () => {
                  fnc1(single);
                  fnc3("1");
                  fnc2(single?.position);
                  handleView("client", single?.id);
                },
              }}
            >
              <RecenterAutomatically
                lat={single?.position[0]}
                lng={single?.position[1]}
              />
            </Marker>
          </>
        )
      ) : null}
    </>
  );
};

export default connect(null, {
  getGponDetails,
  deleteGpon,
  getPopDetails,
  getJunctionDetails,
})(GponMarker);
