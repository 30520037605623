import React, { useState, useRef } from 'react';
import { Button,Drawer, Descriptions,Empty , Select, PageHeader, Form, Row, Col, Tabs, Modal, Tag, Popconfirm, Input, Popover } from 'antd';
import { CheckCircleOutlined, DeleteOutlined, EditOutlined, RightOutlined } from '@ant-design/icons';
import ClientDetailsUpdate from './popDetailsUpdate';
// import CableConnectionUpdate from './cableConnectionUpdate';
import {getPopDetails,assignCore, removeCore,coreAssign, removeCoreAssign, deleteCable, updatePopLocation, deletePop} from '../../action/pop'
import {connect}  from 'react-redux'
import * as L from "leaflet";
import {
    Marker,
    MapContainer,
    Popup,
    TileLayer,
    useMapEvents,
  } from 'react-leaflet'
import { ConnectionBox, DetailBox, InputCore, PointBox, ScrollAble, Selected } from '../../styles/globalStyle';
import { Content } from 'antd/lib/layout/layout';
import AssignCore from './assignCore';
import { isClickableInput } from '@testing-library/user-event/dist/utils';
import DeleteConfirmation from '../../components/deleteConfirmation';
import { useEffect } from 'react';
const { TabPane } = Tabs;
const { Option } = Select;

const POPDetails = ({
    details, closeFnc, refresh, visible,
    assignCore, updatePopLocation, deletePop,
    removeCore,coreAssign, removeCoreAssign, deleteCable,
    deleteFnc, mapData, refreshApi, 
    }) => {
        

    const LeafIcon = L.Icon.extend({
        options: {
            iconSize:     [40, 40],
        }
        });
    const icon = (data) => new LeafIcon({
        iconUrl:  `https://cdn.iconscout.com/icon/free/png-256/location-marker-1719247-1460733.png`,
        iconSize: [20, 20],
        iconAnchor: [12, 12],
        popupAnchor: [0, 0],
        })
    // const [id, setId] = useState(null);
    const [editDesData, setEditDesData] = useState(null);
    const [editConnectionData, setEditConnectionData] = useState(null);
    const [editDesModalVisible, setEditDesModalVisible] = useState(false);
    const [assignModal, setAssignModal] = useState(false);
    const [point, setPoint] = useState(null);
    const [position, setPosition] = useState(null);
    const [core1, setCore1] = useState(null);
    const [core2, setCore2] = useState(null);
    const [cableId1, setCableId1] = useState(null);
    const [cableId2, setCableId2] = useState(null);
    const [keyNum, setKeyNum] = useState(null);
    const [refCable, setRefCable] = useState(null);
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const ref = useRef(null);
    const submitRef = useRef(null);

   
    const LocationFinderDummy = () => {
        const map = useMapEvents({
            click(e) {
                form.setFieldsValue({
                    pop_id: details?.detail?.id,
                    lat : e.latlng.lat,
                    long : e.latlng.lng,
                }); 
              setPosition([e.latlng.lat, e.latlng.lng])
            },
        });
        return null;
    };
    
    const renderContent = (column = 3) => (
        <Descriptions size="small" column={column}>
          <Descriptions.Item label="Id"><strong>{details?.detail?.pop_id}</strong></Descriptions.Item>
          <Descriptions.Item label="Address"><strong>{details?.detail?.address}</strong></Descriptions.Item> 
          {/* <Descriptions.Item label="Mobile number"><strong>{details?.detail?.mobile_number}</strong></Descriptions.Item>  */}
          <Descriptions.Item label="Description"><strong>{details?.detail?.description}</strong></Descriptions.Item>
        </Descriptions>
      );
      
      const handleEdit = (data) => {
        setEditDesData(data?.detail)
        setEditDesModalVisible(true)
      }

    const constrolAssign = (data, key, cable_id) => {
        setKeyNum(key)
        if(core1 === null && core2 === null){
            
            setCore1(data)
            setCableId1(cable_id)
            if(core2 !== null){
                submitRef.current.focus();
            }
        }
        else if(core1 !== null && core2 === null){
            
            setCore2(data)
            setCableId2(cable_id)
            
            if(core1 !== null){
                submitRef.current.focus();
            }
            
        }
        else if(core2 !== null && core1 === null){
            setCore1(data)
            setCableId1(cable_id)
            
            if(core2 !== null){
                submitRef.current.focus();
            }
        }
      
        else  return
    }

    const handleAssignCore = async (core_id) => {
        
        const res = await coreAssign(details?.detail?.id, core_id)
        if(res === 201){
            refresh(details?.detail?.id)
            refreshApi()
        }
    }

    const handleRemoveCable = async (id) => {
        const res = await deleteCable(id)
        if(res === 201){
            refresh(details?.detail?.id)
            refreshApi()
        }
    }

    const handleCoreRemove = async (id) => {
        const res = await removeCoreAssign(details?.detail?.id, id)
        
        if(res == 201){
            refresh(details?.detail?.id)
            
        }
        refreshApi()
    }

    const handleAssign = async () => {
    const res = await assignCore(core1?.id, core2?.id)
    if(res === 201){
        setCore1(null)
        setCore2(null)
        setCableId1(null)
        setCableId2(null)
        setKeyNum(null)
        refresh(details?.detail?.id)
    }
    refreshApi()
    }

    const handleReset = async () => {
        setCore1(null)
        setCore2(null)
        setCableId1(null)
        setCableId2(null)
        setKeyNum(null)
    }
      
    const removeAssign = async (data) => {
        const res = await removeCore(data?.id, data?.r?.id)
        if(res === 201){
            setCore1(null)
            setCore2(null)
            setCableId1(null)
            setCableId2(null)
            setKeyNum(null)
            refresh(details?.detail?.id)
        }
        refreshApi()
    }

    const onFinish = async(values) => {
        const res = await updatePopLocation(values)
        if(res === 201){
            refresh(details?.detail?.id)
            setPosition(null)
            refreshApi()
        }
    }
      
  const handleClick = () => {
    ref.current.focus();
  };
  const handleSubmitRef = () => {
    submitRef.current.focus();
  };
  
  const handleCount = (item) => {
    let c = 0;
        c = c + item?._id?.cable_length
        item?.connection?.map((con) => {
            c =  c + con?.cable_length
        })
    
    return c
  }
  const handleData = (data) => {
    mapData(data)
    closeFnc(false)
    }

    const capitalizeFirst = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
 
    const deleteContent = (id) => 
    {
        
        return ( <DeleteConfirmation id={id} fnc={deleteFnc} open={open} closeFnc={() => setOpen(false)} />
        )
    }
    return ( 
        <>
        <Modal
        title="Pop Details"
        centered
        visible={visible}
        onCancel={() => closeFnc(false)}
        cancelText="Close"
        width={1000}
        okButtonProps={{
            style: {
              display: "none",
            },
          }}
      >
        <DetailBox>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Details" key="1">
                <PageHeader
                        className="site-page-header-responsive"
                        title={details?.detail?.name ? capitalizeFirst(details?.detail?.name) : details?.detail?.name}
                        extra={[
                            <Button onClick={() =>{ handleEdit(details)}} size="small" icon={<EditOutlined />} type="primary" title='Edit'></Button>,
                            // <Popover open={open} content={deleteContent(details?.detail?.id)} title="Delete Confirmation"
                            //     onOpenChange={() => setOpen(false)}
                            //     trigger="click"
                            // >
                                <Button size="small" icon={<DeleteOutlined />} onClick={() => setOpen(true)} type="primary" title='Delete' danger>Delete Pop</Button>
                            // </Popover>,
                            
                        ]}
                    >
                        {open ? 
                            <DeleteConfirmation 
                                id={details?.detail?.id}
                                fnc={deleteFnc}
                                open={open} 
                                closeFnc={() => setOpen(false)}
                            /> 
                        : null}
                        <Content>{renderContent()}</Content>
                            {
                            details?.connectionPath?.length > 0
                            ?
                            <ScrollAble 
                            maxHeight="310px"
                            backgroundColor= '#eeeeee'
                            padding= '10px'
                            width= '100%'
                            >
                            
                            {details?.connectionPath?.map((item, key) => (
                                <>
                                    <div xs={24}>
                                        <h4 style={{ textAlign: 'center',}} >Connection {key+1} <Button size='small' type="primary" onClick={() => handleData(item)}>View On Map</Button></h4>
                                    </div> 
                                    <div key={key} style={{display: 'flex'}}>
                                        
                                        <div style={{ width: '200px'}}>
                                            <Row>
                                                <Col xs={24} style={{ textAlign: 'center'}}><h5>Model</h5></Col>
                                                <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid #FFF`,}}><h5>Cable ID</h5></Col>
                                                <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid #FFF`,}}><h5>Name</h5></Col>
                                                <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid #FFF`,}}><h5>Color</h5></Col>
                                                <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid #FFF`,}}><h5>Core</h5></Col>
                                                <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid #FFF`,}}><h5>Distance</h5></Col>
                                            </Row>
                                        </div>
                                        <div style={{ width: '150px'}}>
                                            <Row>
                                                <Col xs={24}><h5 style={{ textAlign: 'center'}}>{item?._id?.connection_model} <RightOutlined style={{ color: item?._id?.color}}/></h5></Col>
                                                <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid ${item?._id?.color}`}}><h5 style={{ color: item?._id?.color }}>{item?._id?.cable_u_id}</h5></Col>
                                                <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid ${item?._id?.color}`}}><h5 style={{ color: item?._id?.color }}>{item?._id?.connection_name}</h5></Col>
                                                <Col xs={24} style={{ textTransform: 'capitalize', textAlign: 'center', borderTop: `1px solid ${item?._id?.color}`}}><h5 style={{ color: item?._id?.color }}>{item?._id?.color}</h5></Col>
                                                <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid ${item?._id?.color}`}}><h5 style={{ color: item?._id?.color }}>{item?._id?.cable_core}</h5></Col>
                                                <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid ${item?._id?.color}`}}><h5 style={{ color: item?._id?.color }}>{item?._id?.cable_length}</h5></Col>
                                                
                                                {/* {item?.connection?.map((con) => (
                                                    <>
                                                        <Col style={{ width: '150px'}} style={{ textAlign: 'center', borderTop: '1px solid orange'}}><h5 style={{ color: 'orange' , }}>Orange</h5></Col>
                                                    </>
                                                ))} */}
                                            </Row>
                                        </div>
                                        
                                        {item?.connection?.map((con) => (
                                            <>   
                                                <div style={{ width: '150px'}}>
                                                    <Row>
                                                        <Col xs={24}><h5 style={{ textAlign: 'center'}}>{con?.connection_model} <RightOutlined style={{ color: con?.color}}/></h5></Col>
                                                        <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid ${con?.color}`}}><h5 style={{ color: con?.color }}>{con?.cable_u_id}</h5></Col>
                                                        <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid ${con?.color}`}}><h5 style={{ color: con?.color }}>{con?.connection_name}</h5></Col>
                                                        <Col xs={24} style={{ textTransform: 'capitalize', textAlign: 'center', borderTop: `1px solid ${con?.color}`}}><h5 style={{ color: con?.color }}>{con?.color}</h5></Col>
                                                        <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid ${con?.color}`}}><h5 style={{ color: con?.color }}>{con?.cable_core}</h5></Col>
                                                        <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid ${con?.color}`}}><h5 style={{ color: con?.color }}>{con?.cable_length}</h5></Col>
                                                        
                                                        {/* {item?.connection?.map((con) => (
                                                            <>
                                                                <Col style={{ width: '150px'}} style={{ textAlign: 'center', borderTop: '1px solid orange'}}><h5 style={{ color: 'orange' , }}>Orange</h5></Col>
                                                            </>
                                                        ))} */}
                                                    </Row>
                                                </div>
                                            </>
                                        ))}
                                        
                                        <div style={{ width: '200px'}}>
                                            <Row>
                                                <Col xs={24} style={{ textAlign: 'center'}}><h5>&nbsp;</h5></Col>
                                                <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid #FFF`,}}><h5>Total Length</h5></Col>
                                                <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid #FFF`,}}><h5>{handleCount(item)}</h5></Col>
                                                <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid #FFF`,}}><h5>&nbsp;</h5></Col>
                                            </Row>
                                        </div>
                                    </div>
                                </>
                                ))}
                            </ScrollAble>
                            : null}
                            <hr />
                            <br />
                            <PointBox style={{ display: 'flex', justifyContent: 'center', width: '100%'}}>
                                <h3><strong>Update Location</strong></h3>
                                <Row>
                                    <Col xs={14}>
                                        <MapContainer 
                                            attributionControl={false}
                                            center={details?.detail?.location ? details?.detail?.location : [23.785638540195837, 90.42570563577543]} 
                                            zoom={15} 
                                            zoomControl={false}
                                            style={{ height: '250px', width: '100%', alignSelf:'center' }}
                                        >
                                            
                                            <LocationFinderDummy />

                                            <TileLayer
                                                url="https://fm.hidayahsmart.solutions/tile/{z}/{x}/{y}.png"
                                            />
                                            
                                            {position ? 
                                                <Marker position={position} icon={icon()} >
                                                    
                                                </Marker>
                                            : null}
                                            {details?.detail?.location ?
                                                <Marker position={details?.detail?.location} icon={icon()} >
                                                <Popup>
                                                    {details?.detail?.name}
                                                </Popup>
                                                </Marker>
                                            :null}
                                            
                                        </MapContainer>
                                    </Col>
                                    
                                    <Col xs={2}></Col>
                                    <Col xs={8}>
                                        
                                        <Form
                                        name="update location"
                                        labelCol={{
                                        span: 24,
                                        }}
                                        wrapperCol={{
                                        span: 24,
                                        }}
                                        initialValues={{
                                        remember: true,
                                        }}
                                        onFinish={onFinish}
                                        autoComplete="off"
                                        form={form}
                                    >
                                            <Form.Item
                                                label="Pop ID"
                                                name="pop_id"
                                                rules={[{ required: true, message: 'Required' }]}
                                                style={{display: 'none'}}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                label="Latitude"
                                                name="lat"
                                                rules={[{ required: true, message: 'Required' }]}
                                            >
                                                <Input disabled/>
                                            </Form.Item>
                                            <Form.Item
                                                label="Longitude"
                                                name="long"
                                                rules={[{ required: true, message: 'Required' }]}
                                            >
                                                <Input disabled/>
                                            </Form.Item>
                                            <Form.Item
                                                wrapperCol={{
                                                offset: 8,
                                                span: 16,
                                                }}
                                            >
                                                <Button type="primary" htmlType="submit">
                                                Submit
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </Row>
                            </PointBox>
                        
                </PageHeader>
                </TabPane>
                <TabPane tab="Connection" key="2">
                    <ConnectionBox>
                        {cableId1 ?
                        <>
                        <InputCore ref={submitRef} value=" "/>
                            <p style={{backgroundColor: core1?.color, color: '#000'}}>Cable {cableId1} Tube {core1?.sub_cable_no} {core1?.color}  </p>
                            <p>-----Connected with-----</p>
                            </>:null}
                        
                            {cableId2 ?
                            <p style={{backgroundColor: core2?.color}}>Cable {cableId2} Tube {core2?.sub_cable_no} {core2?.color}</p>
                            :null}
                            {cableId1 && cableId2 ?
                            <>
                            <p onClick={handleAssign} style={{padding: '5px 20px', backgroundColor: 'green', color: '#FFF', cursor: 'pointer'}}>
                                
                                Submit</p>
                            </>: null}
                            {cableId1 || cableId2 ?
                            <>
                            <p onClick={handleReset} style={{padding: '5px 20px', backgroundColor: 'red', color: '#FFF', cursor: 'pointer'}}>Reset</p>
                            </>: null}
                    
                    </ConnectionBox>
                    
                    {details?.connection?.length > 0 ? 
                        <Row>
                            {details?.connection?.map((con,id) => (
                            <Col xs={24}>
                            <Row style={{boxShadow: '1px 1px 4px 1px rgba(0,0,0,0.2)',padding: '5px', margin: '5px 0px'}}>
                            <Col xs={12}>
                                <PointBox>
                                    <div>
                                        <h4>Cable Details</h4>
                                        <h2>Cable Id: <strong>{con?.cable?.cable_id}</strong></h2>
                                        <h2>Core: <strong>{con?.cable?.core}</strong></h2>
                                        <Popconfirm title="Are you sure?" okText="Yes" cancelText='No' onConfirm={() => handleRemoveCable(con?.cable?.id)}>
                                            <Button danger type='primary'>Remove</Button>
                                        </Popconfirm>
                                    </div>
                                </PointBox>
                            </Col>
                            <Col xs={12}>
                                <PointBox>
                                    <div>
                                        <h4>Core Details &nbsp; </h4>
                                        {con?.cores?.map((item, key) => (
                                            item?.r 
                                                ? 
                                                <Selected >
                                                    <p 
                                                        
                                                        style={ 
                                                        refCable === item?.id 
                                                        ? 
                                                        {backgroundColor: '#1abc9c', cursor: 'pointer'}
                                                         : 
                                                         refCable === item?.r?.id ?
                                                         {backgroundColor: '#1abc9c', cursor: 'pointer'}
                                                         :
                                                         {cursor: 'pointer'}
                                                        } onClick={() => {setRefCable(item?.r?.id);handleClick();}}
                                                    >
                                                            
                                                            Tube -{item?.sub_cable_no}  &nbsp; 
                                                            <Tag
                                                                color={item?.color}
                                                                style={ item?.color === 'white' || item?.color === 'slate' ? {color: '#000'} : null}>
                                                                {refCable === item?.id ?
                                                                <InputCore value=" " ref={ref} id="message"  autocomplete="off" />
                                                            : null
                                                            }<span>Connected</span></Tag>
                                                            {core1 === null || core2 === null ?
                                                                keyNum === id ?
                                                                <Button size="small"disabled>Core Disconnect</Button>
                                                                :
                                                            <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => {removeAssign(item)}}>
                                                                <Button size="small"  type="primary"danger >Disconnect</Button>
                                                            </Popconfirm>
                                                            :
                                                            null
                                                            }
                                                            
                                                            {core1 !== null && core2 !== null ?
                                                                <Button onClick={() => constrolAssign(item, id, con?.cable?.cable_id)}  size="small"  type="primary" danger disabled >Select</Button>
                                                            :
                                                            null
                                                            }
                                                            <br />
                                                    </p>
                                                   
                                                    
                                                </Selected>
                                                :

                                                <Selected status ={core1?.id === item?.id || core2?.id === item?.id}  >
                                                    <p>Tube -{item?.sub_cable_no}  &nbsp; 
                                                    &nbsp;
                                                    {item?.connected ? 
                                                        <Tag color={item?.color} ><span> Used</span></Tag>
                                                    :
                                                    <Tag color={item?.color} className='glow-on-hover' style={{ color: item?.color}}><span> Unused</span></Tag>
                                                    }
                                                    {/* <Tag color={item?.color}><span style={ item?.color === 'white' || item?.color === 'slate' ? {color: '#000'} : null}>{item?.connected ? ' Used' : ' Unused'}</span></Tag> */}
                                                    {core1 === null || core2 === null ?
                                                        keyNum === id ?
                                                        <Button onClick={() => constrolAssign(item, id, con?.cable?.cable_id)}  size="small"  type="primary" disabled>Select</Button>
                                                        :
                                                        item?.connected ?
                                                            <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => {handleCoreRemove(item?.id)}}>
                                                                <Button size="small"  type="primary" danger >Withdraw</Button>
                                                            </Popconfirm>
                                                        :
                                                        <>
                                                            <Button onClick={() => constrolAssign(item, id, con?.cable?.cable_id)}  size="small"  type="primary" >Select</Button>
                                                            <Button onClick={() => handleAssignCore(item?.id)}  size="small"  type="primary" >Assign</Button>
                                                        </>
                                                    :
                                                    null
                                                    }
                                                    
                                                    {core1 !== null && core2 !== null ?
                                                    // <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => {handleAssign(item,'remove')}}>
                                                        <>
                                                        <Button onClick={() => constrolAssign(item, id, con?.cable?.cable_id)}  size="small"  type="primary"  disabled >Select</Button>
                                                        </>
                                                    // </Popconfirm>
                                                    :
                                                    null
                                                    }
                                                    <br /></p>
                                                </Selected>
                                            
                                        ))}
                                    </div>
                                </PointBox>
                            </Col>
                            </Row>
                            </Col>
                            ))}
                            
                        </Row>
                    : <Empty />}
                </TabPane>
            </Tabs>
        </DetailBox>
        {/* <AssignCore /> */}
        </Modal>
        {editDesData ?<ClientDetailsUpdate refresh={refresh} data={editDesData} visible={editDesModalVisible} onCancel={ () => setEditDesModalVisible(false)} />:null}
        
        {/* <Drawer
        title="Assign Core"
        centered
        visible={assignModal}
        onCancel={() => setAssignModal(false)}
        cancelText="Close"
        width={1000}
        zIndex={100000000}
        okButtonProps={{
            style: {
              display: "none",
            },
          }}
      >
        <AssignCore />
        </Drawer> */}
        
       {assignModal ?
        <AssignCore /> :null}
       
        </>
     );
}
 
export default connect(null,{getPopDetails,assignCore, removeCore,coreAssign, removeCoreAssign, deleteCable, updatePopLocation, deletePop})(POPDetails);