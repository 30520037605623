import {
  BranchesOutlined,
    DesktopOutlined,
    FileOutlined,
    PieChartOutlined,
    TeamOutlined,
    UserOutlined,
  } from '@ant-design/icons';
  import { Breadcrumb, Layout, Menu } from 'antd';
  import React, { useState } from 'react';
  import { Link } from 'react-router-dom';
  import { useLocation } from 'react-router-dom';
  const { SubMenu } = Menu;
  const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }
const items = [
    getItem('Option 1', '1', <PieChartOutlined />),
    getItem('Option 2', '2', <DesktopOutlined />),
    getItem('User', 'sub1', <UserOutlined />, [
      getItem('Tom', '3'),
      getItem('Bill', '4'),
      getItem('Alex', '5'),
    ]),
    getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
    getItem('Files', '9', <FileOutlined />),
  ];
const Sidebar = () => {
    const location = useLocation();
    const [collapsed, setCollapsed] = useState(false);
    return ( 
        <Sider width={200} className="site-layout-background" collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
            {/* <div className="logo" /> */}
            {/* <img className='logo' src="./logo.png" width="150px" style={{padding: '15px'}} /> */}
            {/* <div className='sider-logo'> */}
            <img className='sider-logo' src="./logo.png" />
            {/* </div> */}
            <Menu theme="light" mode="inline" defaultSelectedKeys={['dashboard']}>
              <Menu.Item  key="dashboard" icon={<PieChartOutlined />}>
                <Link to="/dashboard">Dashboard</Link>
              </Menu.Item>
              <Menu.Item key="networkPoint" icon={<BranchesOutlined />}>
                <Link to="/network-point">Network Point</Link>
              </Menu.Item>

              {/* <SubMenu key="point" icon={<UserOutlined />} title="Network Point">
                <Menu.Item key="role"><Link to="/pops">POP</Link></Menu.Item>
                <Menu.Item key="3"><Link to="/users">TJ Box</Link></Menu.Item>
                <Menu.Item key="clients"><Link to="/clients">Clients</Link></Menu.Item>
                <Menu.Item key="junctions"><Link to="/junctions">Junction Point</Link></Menu.Item>
              </SubMenu> */}

              {/* <SubMenu key="cable" icon={<UserOutlined />} title="Fiber Map">
                <Menu.Item key="role"><Link to="/roles">Areal</Link></Menu.Item>
                <Menu.Item key="3"><Link to="/users">Underground</Link></Menu.Item>
                <Menu.Item key="3"><Link to="/users">Circle</Link></Menu.Item>
              </SubMenu> */}

            </Menu>
        </Sider>
     );
}
 
export default Sidebar;