import { Button, Form, Menu, Modal, PageHeader } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import {logOut} from '../../action/loginAction'
import { useLocation } from 'react-router-dom';
import {
  BranchesOutlined,
    ColumnWidthOutlined,
    PieChartOutlined,
    UserOutlined,
  } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import history from '../../history';
import { renderInput } from '../form/form';
import { updateOwnPassword } from '../../action/user';

const Topbar = ({logOut, updateOwnPassword}) => {
  const [isPassModalOpen, setIsPassModalOpen] = useState(false);
  const location = useLocation();
  
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };
  
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };
  
  const [pform] = Form.useForm();
  const handlePass = async (val) => {
    const res = await updateOwnPassword( localStorage.getItem('user_id'), val?.old_password, val?.password, val?.confirm_password )
    if(res === 201){
      pform.resetFields();
      setIsPassModalOpen(false)
    };
  }
  return(
  <div className="site-page-header-ghost-wrapper">
    <PageHeader
      style={{height: '60px', padding: '0px 24px'}}
      ghost={false}
      title={
        <div style={{ backgroundColor: 'red'}}>
          <Menu mode="horizontal" 
          style={{width: '1000px',margin: '0px', border: 'none', height: 'auto'}}
          defaultSelectedKeys={[location?.pathname ? location?.pathname : null]}
            >
            <Menu.Item >
            <img  src="./logo.png" style={{ width: '100px'}}/>
            </Menu.Item>
            <Menu.Item key="/dashboard" icon={<PieChartOutlined />}>
              <Link to="/dashboard">Dashboard</Link>
            </Menu.Item>
            {localStorage.getItem('root') === 'true' ? 
            <Menu.Item key="/user" icon={<UserOutlined />}>
              <Link to="/user">User</Link>
            </Menu.Item>
            :null}
            <Menu.Item key="/network-point" icon={<BranchesOutlined />}>
              <Link to="/network-point">Network Point</Link>
            </Menu.Item>
            <Menu.Item key="/planing" icon={<ColumnWidthOutlined />}>
              <Link to="/planing">Planing</Link>
            </Menu.Item>
          </Menu>
        </div>
      }
      extra={[
        // <Menu mode="horizontal" style={{ border: 'none'}} defaultSelectedKeys={['mail']}>
        //   <Menu.SubMenu key="SubMenu" title="Setting" icon={<ToolOutlined />}>
        //       <Menu.Item key="four" icon={<UserOutlined />}>
        //         User
        //       </Menu.Item>
        //       <Menu.Item key="five" icon={<LoginOutlined />}>
        //         Logout
        //       </Menu.Item>
        //   </Menu.SubMenu>
        // </Menu>
        <Button style={{ marginTop: '20%', cursor: 'default'}} size='small' key="1" type="text" icon={<UserOutlined />}>
          {localStorage.getItem('name')}
        </Button>,
        <Button style={{ marginTop: '10%'}} size='small' key="1" type="primary" onClick = { () => setIsPassModalOpen(true)}>
          Change Password
        </Button>,
        <Button style={{ marginTop: '20%'}} size='small' key="1" type="primary" onClick = { () => logOut()}>
          Logout
        </Button>,
      ]}
    >
    </PageHeader>
    
    <Modal
        title="Change Password" 
        visible={isPassModalOpen} 
        onOk={() => setIsPassModalOpen(false)} 
        onCancel={() => setIsPassModalOpen(false)} 
        width={800}
        footer={null}
      >
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={handlePass}
          form={pform}
        >
         
          {renderInput('old_password', 'Old Password', [
              { required: true, message: 'This field is required!' },
            ])
          }
          {renderInput('password', 'New Password', [
              { required: true, message: 'This field is required!' },
            ])
          }
          {renderInput('confirm_password', 'Confirm Password', [
              { required: true, message: 'This field is required!' },
            ])
          }
          <Form.Item {...tailLayout}> 
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
                
        </Form>
      </Modal>
  </div>)
};

export default connect(null,{logOut, updateOwnPassword}) (Topbar);