import L from 'leaflet';
import {
  GeoSearchControl,
  GoogleProvider,
} from 'leaflet-geosearch';
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

const provider = new GoogleProvider({ params: {
  key: 'AIzaSyDslUl2FlUug8PaPRs2woRYjCi5EwqshVo',
} });

const searchControl = new GeoSearchControl({
  provider: provider,
});

// const map = new L.Map('map');
// const provider = new GoogleProvider({ apiKey:'AIzaSyDslUl2FlUug8PaPRs2woRYjCi5EwqshVo' });
const SearchField = ({ apiKey }) => {
  // @ts-ignore

  const map = useMap();
  useEffect(() => {
   
   
const res =  map.addControl(searchControl);
  }, []);

//   return null;
};

export default SearchField;

