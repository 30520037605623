import styled from "styled-components";

export const MContainer = styled.div`
  background-color: #fff;
  height: 100vh;
`;
export const SideContainer = styled.div`
  background-color: #fff;
  height: 100vh;
  padding: 10px;
`;

export const Box1 = styled.div`
  width: 100px;
  height: 30px;
  background-color: Blue;
  align-self: center;
  /* margin: 10px ; */
`;
export const Box2 = styled.div`
  width: 100px;
  height: 30px;
  background-color: Orange;
  align-self: center;
  /* margin: 10px ; */
`;
export const Box3 = styled.div`
  width: 100px;
  height: 30px;
  background-color: Green;
  align-self: center;
  /* margin: 10px ; */
`;
export const Box4 = styled.div`
  width: 100px;
  height: 30px;
  background-color: Brown;
  align-self: center;
  /* margin: 10px ; */
`;
export const Box5 = styled.div`
  width: 100px;
  height: 30px;
  background-color: #8fb190;
  align-self: center;
  /* margin: 10px ; */
`;
export const Box6 = styled.div`
  width: 100px;
  height: 30px;
  background-color: Black;
  align-self: center;
  /* margin: 10px ; */
`;
export const Box7 = styled.div`
  width: 500px;
  height: 30px;
  background-color: Red;
  align-self: center;
  /* margin: 10px ; */
`;

export const CoreBox = styled.div`
  position: absolute;
  top: 100;
  right: 100px;
  margin-left: 100px;
  background-color: rgba(255, 255, 255, 0);
  z-index: 1000;
  border: 1px solid red;
  border-radius: 10px;
  width: 250px;
  padding: 10px;
  font-size: 10px;
`;

export const Grey = styled.span`
  padding: 2px 10px;
  background-color: grey;
`;
export const Purple = styled.span`
  padding: 2px 10px;
  background-color: purple;
`;
export const Black = styled.span`
  padding: 2px 10px;
  background-color: #000000;
`;
export const Green = styled.span`
  padding: 2px 10px;
  background-color: green;
`;

export const Yellow = styled.span`
  padding: 2px 10px;
  background-color: yellow;
`;

export const Blue = styled.span`
  padding: 2px 10px;
  background-color: blue;
`;

export const Red = styled.span`
  padding: 2px 10px;
  background-color: red;
`;
export const Aqua = styled.span`
  padding: 2px 10px;
  background-color: Aqua;
`;
export const Orange = styled.span`
  padding: 2px 10px;
  background-color: orange;
`;

export const PopWrapper = styled.div`
  /* margin: 15px 0px; */
  cursor: pointer;
  padding: 5px;
  transition: 0.2s all;
  box-shadow: ${(props) =>
    props.active ? "0px 2px 2px -2px rgba(0,0,0,0.75)" : null};
  :hover {
    box-shadow: 0px 2px 2px -2px rgba(0, 0, 0, 0.75);
    cursor: pointer;
  }
`;

export const SideDataBar = styled.div`
  position: absolute;
  top: 60px;
  left: 0px;
  height: 100%;
  width: 200px;
  background-color: #ffffff;
  z-index: 1000;
  padding: 10px;
  color: #000000;
  /* background-color: #000000; */
  height: 90vh;
`;

export const BottomBtn = styled.button`
  position: absolute;
  bottom: 100px;
  width: 90%;
  margin: auto;
  border: none;
  padding: 10px 12px;
  background-color: #1565a6;
  color: #fff;
  cursor: pointer;
`;
