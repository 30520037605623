import { Col, Form, Row, PageHeader, Tabs } from 'antd';
import React, {useRef, useEffect, useState } from 'react'
import {Black, Blue,  CoreBox, MContainer, Red, SideDataBar, Yellow} from './style'
import {
  Marker,
  MapContainer,
  Popup,
  TileLayer,
  FeatureGroup,
  Polyline,
  useMapEvents,
} from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { ArrowLeftOutlined } from '@ant-design/icons'
import {allPoint} from '../../action/map'
import { connect } from 'react-redux';
import ClientList from '../clients'
import ClientMarker from '../clients/marker'
import Pop from '../pop';
import PopMarker from '../pop/marker';
import JunctionMarker from '../junction/marker';
import JunctionList from '../junction';
import Cable from '../cable';
const { TabPane } = Tabs;

const FocusedMap = ({ data }) => {

    return ( 
      <FeatureGroup>
        
        <Polyline
            dashArray= {
              data?._id?.cable_type === 'underground' ? '2, 5' :
              data?._id?.cable_type === 'wireless' ? '5, 10' 
               : null}
            pathOptions={{ 
              color: '#DE51B4',
                weight: '8',
            }}
            lineCap="Ok"
            positions={data?._id?.location}
            
          >
        </Polyline>
      {data?.connection ?
          data?.connection?.map(item => (
        <Polyline
            dashArray= {
              item.cable_type === 'underground' ? '2, 5' :
              item.cable_type === 'wireless' ? '5, 10' 
               : null}
            pathOptions={{ 
              color: '#DE51B4',
                weight: '10',
            }}
            lineCap="Ok"
            positions={item.location}
          >
          
        </Polyline>
          ))
      :null}
      

   
    
    </FeatureGroup>
     );
}
 
export default FocusedMap;