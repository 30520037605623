import React from 'react'
import { Button, Descriptions,Input, Select, PageHeader, Form, Row, Col, Tabs, Modal, Tag } from 'antd';
import { useEffect } from 'react';
import {connect} from 'react-redux';
import {updatePopDetails} from '../../action/pop';


const { Option } = Select;
const PopDetailsUpdate = ({ 
    visible, onCancel, data, 
    updatePopDetails, refresh,
}) => {
    useEffect(() => {
        form.setFieldsValue({
            id : data?.pop_id,
            pop_id : data?.id,
            name : data?.name,
            address : data?.address,
            note : data?.note,
            description : data?.description,
          });
    }, [])
    
    const [form] = Form.useForm();

    const onFinish = async (values) => {
        const code = await updatePopDetails(values, data?.id)
        if(code === 201){
            onCancel(false)
            refresh(data?.id)
        }
      }
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
    return ( 
        <Modal title="Edit Pop" visible={visible} onCancel={() =>onCancel(false)}
            okButtonProps={{
                style: {
                display: "none",
                },
            }}
          >
            <Form
                  name="basic"
                  labelCol={{
                  span: 8,
                  }}
                  wrapperCol={{
                  span: 16,
                  }}
                  initialValues={{
                  remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  form={form}
              >
                      <Form.Item
                          label="ID"
                          name="id"
                          rules={[{ required: true, message: 'Required' }]}
                        //   style={{display: 'none'}}
                      >
                          <Input />
                      </Form.Item>
                      <Form.Item
                          label="Pop ID"
                          name="pop_id"
                          rules={[{ required: true, message: 'Required' }]}
                          style={{display: 'none'}}
                      >
                          <Input />
                      </Form.Item>
                      <Form.Item
                          label="Name"
                          name="name"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Input />
                      </Form.Item>
                      <Form.Item
                          label="Address"
                          name="address"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Input.TextArea />
                      </Form.Item>
                      
                      <Form.Item
                          label="Note"
                          name="note"
                      >
                          <Input.TextArea />
                      </Form.Item>
                      
                      <Form.Item
                          label="Description"
                          name="description"
                      >
                          <Input.TextArea />
                      </Form.Item>

                      <Form.Item
                          wrapperCol={{
                          offset: 8,
                          span: 16,
                          }}
                      >
                          <Button type="primary" htmlType="submit">
                          Submit
                          </Button>
                      </Form.Item>
              </Form>
        </Modal>
     );
}
 
export default connect(null,{updatePopDetails})(PopDetailsUpdate);