import React from 'react'
import { Button, Descriptions,Input, Select, PageHeader, Form, Row, Col, Tabs, Modal, Tag } from 'antd';
import { useEffect } from 'react';
import {connect} from 'react-redux';
import {updateCabelDescription} from '../../action/cable';


const { Option } = Select;
const CableDetailsUpdate = ({ 
    visible, onCancel, data, 
    updateCabelDescription, refresh,
    allRefresh,
}) => {
    useEffect(() => {
        form.setFieldsValue({
            cable_id : data?.cable_id,
            id : data?.id,
            type : data?.type,
            length : data?.length,
            note : data?.note,
            description : data?.description,
          });
    }, [])
    
    const [form] = Form.useForm();

    const onFinish = async (values) => {
        const code = await updateCabelDescription(values, data?.id)
        if(code === 201){
            onCancel(false)
            form.resetFields()
            allRefresh()
            refresh(data?.id)
        }
      }
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
    return ( 
        <Modal title="Edit Form" visible={visible} onCancel={() =>onCancel(false)}
            okButtonProps={{
                style: {
                display: "none",
                },
            }}
          >
            <Form
                  name="basic"
                  labelCol={{
                  span: 8,
                  }}
                  wrapperCol={{
                  span: 16,
                  }}
                  initialValues={{
                  remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  form={form}
              >
                      <Form.Item
                          label="Cable ID"
                          name="cable_id"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Input />
                      </Form.Item>

                      <Form.Item
                          label="Type"
                          name="type"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Select  >
                            <Option value="line">Line</Option>
                            <Option value="underground">Underground</Option>
                            <Option value="wireless">Wireless</Option>
                          </Select>
                      </Form.Item>
                      
                      <Form.Item
                          label="Length"
                          name="length"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Input />
                      </Form.Item>

                      <Form.Item
                          label="Note"
                          name="note"
                      >
                          <Input.TextArea />
                      </Form.Item>

                      <Form.Item
                          label="Description"
                          name="description"
                      >
                          <Input.TextArea />
                      </Form.Item>

                      <Form.Item
                          wrapperCol={{
                          offset: 8,
                          span: 16,
                          }}
                      >
                          <Button type="primary" htmlType="submit">
                          Submit
                          </Button>
                      </Form.Item>
              </Form>
        </Modal>
     );
}
 
export default connect(null,{updateCabelDescription})(CableDetailsUpdate);