import React from 'react'
import LineTo, { SteppedLineTo } from 'react-lineto';
import "./styles.css";
import { Col, Row } from 'antd';
import { useState } from 'react';
import "./styles.css";
const faststyle = {
    width: "90px",
    height: "40px",
    border: "1px solid green",
    margin: "0 0 20px 0",
    background: "lightgreen",
    zIndex: "2"
  };
  
  const container = {
    display: "flex",
    flexDirection: "column",
    margin: "0 50px 0 0"
  };
  
  const root = {
    display: "flex",
    flexDirection: "row"
  };
  
const AssignCore = () => {
    const [isActive , setIsActive] = useState(false)
    return ( 
        <div className="container">
           
{/* <LineTo fromAnchor="right" toAnchor='left' delay={100} from="element1" to="element20" orientation="v"/> 
<LineTo fromAnchor="right" toAnchor='left' delay={200} from="element2" to="element16" orientation="v" /> 
<LineTo fromAnchor="right" toAnchor='left' delay={300} from="element3" to="element17" orientation="v" /> 
<LineTo fromAnchor="right" toAnchor='left' delay={400} from="element4" to="element24"  orientation="v"/> 
<LineTo fromAnchor="right" toAnchor='left' delay={500} from="element12" to="element13"  orientation="v"/>  */}
{/* <SteppedLineTo zIndex={0} fromAnchor="right" toAnchor='left' borderStyle='dotted' delay={500} from="element1" color="green" to="element20" orientation="h" /> */}
<SteppedLineTo borderStyle="dotted" zIndex="100000000000000" fromAnchor="right" toAnchor='left' delay={100} from="element1" to="element20" orientation="h" />
<SteppedLineTo zIndex="100000000000000" fromAnchor="right" toAnchor='left' delay={300} from="element2" to="element16" orientation="h" />
<SteppedLineTo zIndex="100000000000000" fromAnchor="right" toAnchor='left' delay={500} from="element3" to="element17" orientation="h" />
<SteppedLineTo zIndex="100000000000000" fromAnchor="right" toAnchor='left' delay={700} from="element4" to="element13" orientation="h" />
<SteppedLineTo zIndex="100000000000000" fromAnchor="right" toAnchor='left' delay={900} from="element5" to="element22" orientation="h" />
            <Row className="elements">
            <Col xs={1} className="elements-row">
                <div className="element element1" />
                <div className="element element2" />
                <div className="element element3" />
                <div className="element element4" />
                <div className="element element5" />
                <div className="element element6" />
                <div className="element element7" />
                <div className="element element8" />
                <div className="element element9" />
                <div className="element element10" />
                <div className="element element11" />
                <div className="element element12" />
                
            </Col>
            <Col xs={2}>
            </Col>
            <Col xs={1} className="elements-row">
                <div className="element element13" />
                <div className="element element14" />
                <div className="element element15" />
                <div className="element element16" />
                <div className="element element17" />
                <div className="element element18" />
                <div className="element element19" />
                <div className="element element20" />
                <div className="element element21" />
                <div className="element element22" />
                <div className="element element23" />
                <div className="element element24" />
            </Col>
            <br />
            <br />
            <br />
            <br />
            <Col xs={23} className="elements-row">
                <div className="element element43" />
                </Col>
            </Row>

            {/* <ConnectElements
            overlay="0"
            strokeWidth="2"
            selector=".elements"
            color="red"
            onClick={(e)=> console.log(e)}
            elements={[
                { from: ".element1", to: ".element24", color: "blue" },
                { from: ".element2", to: ".element17", color: "Orange" },
                { from: ".element3", to: ".element20", color: "green" },
                { from: ".element4", to: ".element21", color: "Brown" },
                { from: ".element5", to: ".element19", color: "teal" },
                { from: ".element6", to: ".element15", color: "rgb(64, 62, 62)" },
                { from: ".element7", to: ".element22", color: "Red" },
                { from: ".element8", to: ".element16", color: "Black" },
                { from: ".element9", to: ".element14", color: "Yellow" },
                { from: ".element10", to: ".element13", color: "Violet" },
                { from: ".element11", to: ".element18", color: "Pink" },
                { from: ".element12", to: ".element23", color: "rgb(20, 70, 96)" },
                ]}
            /> */}
        </div>
        

     );
}
 
export default AssignCore;