import React from 'react';
import './App.css';
import history from './history';
import MaintenancePage from './pages/maintenance';
import NotFound from './pages/notFound';
import GlobalStyles from './styles/globalStyle';
import MainLayout from './pages/layout';
import { Router, Route,Switch, Redirect } from 'react-router-dom';
import LoginPage from './pages/login';
import { connect } from 'react-redux';
import MessageAlert from './components/messageAlert';

const App = (props) => {
  return ( 
    <>
    {props.authSuccess ? <MessageAlert type="success" value={props.authSuccess} /> : null}
     {props.authError ? <MessageAlert type="error" value={props.authError} /> : null}
     {props.authValidationError ? 
       props.authValidationError.map((item,id )=> (
         <MessageAlert type="error" value={item.message} />
       ))
       :
       null
     }
    <Router history={history} >
      <GlobalStyles />
      <Switch>
          <Route path="/maintenance" component={MaintenancePage}/>
          <Route path="/login" component={LoginPage}/>
          <Route path="/404" component={NotFound}/>
          <Route path="/" component={MainLayout}/>
      </Switch>
    </Router>
    </>
   );
}
const mapStateToProps = (state) => {
  return {
    authSuccess: state.helpers.authSuccess,
    authError: state.helpers.authError,
    authValidationError: state.helpers.authValidationError,
  };
};
export default connect(mapStateToProps)(App);