import api from "./apis";
import { AUTH_ERROR, ERROR_MESSAGE, LOADED, LOADING, RESET_MESSAGES, SUCCESS_MESSAGE, VALIDATION_AUTH_ERROR, VALIDATION_ERROR } from "./type";
import history from '../history';

// ==========Login============
export const login = ({email, password} ) => async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const response = await api().post(`/signin`, {
        email: email,
        password: password, 
      });
      if (response.status === 201) {
        localStorage.setItem("token", response.data.data.token);
        localStorage.setItem("name", response.data.data.name);
        localStorage.setItem("role", response.data.data.email);
        localStorage.setItem("root", response.data.data.root);
        localStorage.setItem("user_id", response.data.data.user);
        dispatch({type: LOADED})
          dispatch({ type: RESET_MESSAGES });
          history.replace('/dashboard');
      }
    }catch(err){
      
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: AUTH_ERROR, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: AUTH_ERROR, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    }
  };

  // =================Logout=============

  // =====================Logout

export const logOut = () => async (dispatch) => {
  try {
    
    const response = await api(localStorage.getItem("token")).patch("/signout");
    if (response.status === 201) {
      localStorage.clear();
      history.push("/login");
    }
      dispatch({
        type: SUCCESS_MESSAGE ,
        payload: "Successfully Loged out..! ",
      });
      
    
   
  } catch (err) {
    if(err.response)
        {
            dispatch( {type: LOADED });
            if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
        }
    dispatch({ type: ERROR_MESSAGE , payload: err.response ? err.response.data.message : history.push('/opps')  });
    
  }
};

// ==============Get OTP for pass===
export const getOTP = (mobile_number) => async (dispatch) => {
  // dispatch({ type: LOADING });
  try {
    
    const data = {
      mobile_number: mobile_number
    };
    const res = await api().post("/forgot-password",{...data}, {
      header: {
        "content-type": "application/json",
      }
    });
    // dispatch({ type: LOADED });
    dispatch({ type: SUCCESS_MESSAGE, payload: 'OTP is send to your number' });
    return res.status
  
  } catch (err) {
     if(err.response)
        {
            // dispatch( {type: LOADED });
            if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
        }
   
    dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps')  });
    
    // dispatch({ type: LOADED });
  }
};

// ============Change pass for forget====
export const forgetPass = (mobile_number, otp, password, confirm_password) => async (dispatch) => {
  
  // dispatch({ type: LOADING });
  try {
    
    const datas = {
      mobile_number : mobile_number,
      OTP : otp,
      password : password,
      confirm_password : confirm_password,
    };
    const response = await api().patch("/reset-password",{...datas}, {
      header: {
        "content-type": "application/json",
      }
    });
  
    
    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Password Changed successfully ",
      });
      
    // dispatch({ type: LOADED });
      history.push("/login");
    }else{
      
    // dispatch({ type: LOADED });
    }
  } catch (err) {
    if(err?.response?.status === 422){
      err.response.data.errors ?
      err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
        :
        dispatch({
          type: ERROR_MESSAGE,
          payload: err.response ? err.response.data.message : null ,
        });
      }
    if(err.response)
        {
            // dispatch( {type: LOADED });
            if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
        }
    dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/error')  });
    // dispatch({ type: LOADED });
    
  }
};


  // ========== Resend otp=========
  export const resendForgotOtp = (mobile_number) => async (dispatch) => {
   
    // dispatch({ type: LOADING });
    const obj = {
      'type': 'resetPass',
      'mobile_number': mobile_number,
    }
    try {
      const response = await api(localStorage.getItem("token")).patch("/resend-otp", {...obj});
  
      if (response.status === 201) {
        
        // dispatch({ type: LOADED });
        dispatch({ type: SUCCESS_MESSAGE, payload: 'OTP is send to your number' });
        return response.status
        
      } else {
        // dispatch({ type: LOADED });
      }
    } catch (err) {
      
        if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }

       if(err.response)
          {
            dispatch( {type: LOADED });
              if(err.response.status === 401){ localStorage.clear(); history.push('/login') };
          }
      // dispatch({ type: LOADED });
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response ? err.response.data.message : history.push('/opps'),
      });
    }
  };