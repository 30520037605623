import React, { useEffect, useState } from 'react';
import { PointBox } from './style';
import {addDevice} from '../../action/cable'
import {connect}  from 'react-redux'
import * as L from "leaflet";
import {
    Marker,
    MapContainer,
    TileLayer,
    Polyline,
    FeatureGroup,
    useMap,
    useMapEvents,
  } from 'react-leaflet'
import { Button, Drawer, Form, Input } from 'antd';
import history from '../../history';


const CableCut = ({ addDevice }) => {
    const [details, setDetails] = useState(JSON.parse(localStorage.getItem('cordinates')))
    const [position, setPosition] = useState([]);
    const [createDiv, setCreateDiv] = useState(false);
    const [cableNo, setCableNo] = useState(null);
    const [type, setType] = useState(null);
    const [form] = Form.useForm();
    const LeafIcon = L.Icon.extend({
        options: {
            iconSize:     [40, 40],
        }
    });
    const icon = (data) => new LeafIcon({
        iconUrl:  `https://cdn.iconscout.com/icon/free/png-256/location-marker-1719247-1460733.png`,
        iconSize: [20, 20],
        iconAnchor: [12, 12],
        popupAnchor: [0, 0],
      })
    const popicon = (data) => new LeafIcon({
        iconUrl:  `https://cdn.iconscout.com/icon/free/png-256/location-marker-1719247-1460733.png`,
        iconSize: [20, 20],
        iconAnchor: [12, 12],
        popupAnchor: [0, 0],
        })
    const RecenterAutomatically = ({lat,lng}) => {
        const map = useMap();
        useEffect(() => {
        map.setView([lat, lng]);
        }, [lat, lng]);
        return null;
    }
    const onFinish = async (values) => {
            let res = null;
            if(type === 'pop'){
                res = await addDevice(values, type)
            }
            else{
                res = await addDevice(values, type)
            }
            if(res === 201){
                form.resetFields()
                setCreateDiv(false)
                history.push('/network-point');
            }
        };
        
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const makeLine = (lat1, lon1, lat2, lon2, i) => (
        // console.log([[lat1, lon1],[lat2, lon2]])
        <Polyline
        // dashArray= { '2, 5'}
        pathOptions={{ 
            color: 'black',
            weight: '5',
        }}
        lineCap="Ok"
        positions={[[lat1, lon1],[lat2, lon2]]}
        
        eventHandlers={{
            click: () => {
                
                form.setFieldsValue({
                    break_point : i,
                });
                setCableNo(i);
                setCreateDiv(true);
            }}}
        
    ></Polyline>
    )
    const lineHelper = (e) => {
        let a = [];
        for(let i=1; i < e.length; i++ ){
        a.push(makeLine(e[i - 1][0], e[i - 1][1], e[i][0], e[i][1], i))
        
        } 
    return a;
    }

    const LocationFinderDummy = () => {
        const map = useMapEvents({
            click(e) {
              setPosition([e.latlng.lat, e.latlng.lng])
              form.setFieldsValue({
                lat : e.latlng.lat,
                long : e.latlng.lng,
                cable: details?.id,
              })
            },
        });
        return null;
    };
    return ( 
        <>
        
        <Drawer title="Create" placement="right" onClose={() => setCreateDiv(false)} visible={createDiv}>
              {/* <h4 style={{textAlign: 'center', color: 'red'}}>*** Please click on the map to get coordinate**</h4> */}
              <br />
              <Form
                  name="basic"
                  labelCol={{
                  span: 8,
                  }}
                  wrapperCol={{
                  span: 16,
                  }}
                  initialValues={{
                  remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  form={form}
              >
                      <Form.Item
                          label="Id"
                          name="cable"
                          rules={[{ required: true, message: 'Required' }]}
                          
                      >
                          <Input disabled />
                      </Form.Item>
                      <Form.Item
                          label="Cable No"
                          name="break_point"
                          rules={[{ required: true, message: 'Required' }]}
                          
                      >
                          <Input disabled />
                      </Form.Item>
                      <Form.Item
                          label="Device ID"
                          name="id"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Input />
                      </Form.Item>
                      <Form.Item
                          label="Name"
                          name="name"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Input />
                      </Form.Item>
                      <Form.Item
                          label="Lat"
                          name="lat"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Input disabled/>
                      </Form.Item>

                      <Form.Item
                          label="Long"
                          name="long"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Input disabled/>
                      </Form.Item>

                      <Form.Item
                          label="Address"
                          name="address"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Input.TextArea />
                      </Form.Item>

                      <Form.Item
                          label="Note"
                          name="note"
                      >
                          <Input.TextArea />
                      </Form.Item>

                      <Form.Item
                          label="Description"
                          name="description"
                      >
                          <Input.TextArea />
                      </Form.Item>

                      <Form.Item
                          wrapperCol={{
                          offset: 8,
                          span: 16,
                          }}
                      >
                          {/* <Button type="primary" htmlType="submit" onClick={() => setType('pop')} >
                            Add POP
                          </Button> */}
                          <Button type="primary" htmlType="submit" onClick={() => setType('junction')} >
                            Add Junction
                          </Button>
                      </Form.Item>
              </Form>
          </Drawer>
            <PointBox style={{ display: 'flex', justifyContent: 'center', width: '100%'}}>
               
                <MapContainer 
                    attributionControl={false}
                    center={details?.connection_start_point?.location ? details?.connection_start_point?.location : [23.785638540195837, 90.42570563577543]} 
                    zoom={15} 
                    style={{ height: '88vh', width: '100%', padding: '0px' }}
                >
                    
             
                    <TileLayer
                        url="https://fm.hidayahsmart.solutions/tile/{z}/{x}/{y}.png"
                    />
                    
                    <LocationFinderDummy />
                      {position.length > 0 ? 
                        <Marker position={position} icon={icon()} >
                                                    
                        </Marker>
                      : null}
                    {details ?                       
                        <FeatureGroup>
                        
                            
                        
                            {position.length > 0 ? 
                                <RecenterAutomatically lat={position[0]} lng={position[1]} />
                            : 
                                details?.location_front?.length > 0 ? 
                                <RecenterAutomatically lat={details?.location_front[0][0]} lng={details?.location_front[1][1]} />
                            : null
                            }

                            {    lineHelper(details?.location_front)}

                            {/* {details?.location_front?.length > 0 ?
                                <Polyline
                                    dashArray= {
                                        details?.type === 'underground' ? '2, 5' :
                                        details?.type === 'wireless' ? '5, 10' 
                                        : null}
                                    pathOptions={{ 
                                        color: details?.core === 2 ? 'black' :
                                                details?.core === 4 ? 'grey' :
                                                details?.core === 8 ? 'green' :
                                                details?.core === 12 ? 'blue' :
                                                details?.core === 24 ? 'orange' :
                                                details?.core === 36 ? 'yellow' :
                                                details?.core === 48 ? 'red' :
                                            'black',
                                        weight: '5',
                                    }}
                                    lineCap="Ok"
                                    positions={details?.location_front}
                                    
                                ></Polyline>
                            : null}              */}
                        </FeatureGroup>
                    :null}  

                </MapContainer>
            </PointBox>
                        
        </>
     );
}
 
export default connect(null,{addDevice})(CableCut);