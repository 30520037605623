import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh ;
    padding: 0px 20px ;
`;

export const Wrapper = styled.div`
    align-self: center;
    width: 100%;
    height: 60%;
    padding: 10px;
    box-shadow: 0px 2px 18px -10px rgba(0,0,0,0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #7b2323; */
    form{
    align-self: center;
    width: 100%;

    }
`;