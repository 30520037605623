import React, {  useState, useRef } from 'react';
import { Button, Descriptions,Empty , Select, PageHeader, Form, Row, Col, Tabs, Modal, Tag, Popconfirm, Input, Popover } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
// import CableConnectionUpdate from './cableConnectionUpdate';
import {getJunctionDetails, updateJunctionLocation, deleteCable, deleteJunction} from '../../action/junction'
import {connect}  from 'react-redux'
import * as L from "leaflet";
import {
    Marker,
    MapContainer,
    Popup,
    TileLayer,
    useMapEvents,
  } from 'react-leaflet'
import { ConnectionBox, DetailBox, InputCore, PointBox, Selected } from '../../styles/globalStyle';
import { Content } from 'antd/lib/layout/layout';
import { assignCore, removeCore } from '../../action/pop';
import JunctionDetailsUpdate from './junctionDetailsUpdate';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import DeleteConfirmation from '../../components/deleteConfirmation';
import './style.css'

const { TabPane } = Tabs;
const { Option } = Select;

const JunctionDetails = ({
    details, closeFnc, refresh, visible,
    assignCore, removeCore, deleteJunction, updateJunctionLocation, deleteCable,
    deleteFnc, refreshApi, 
    }) => {
        const LeafIcon = L.Icon.extend({
            options: {
              iconSize:     [40, 40],
           }
          });
        const icon = (data) => new LeafIcon({
            iconUrl:  `https://cdn.iconscout.com/icon/free/png-256/location-marker-1719247-1460733.png`,
            iconSize: [20, 20],
            iconAnchor: [12, 12],
            popupAnchor: [0, 0],
          })
    // const [id, setId] = useState(null);
    const [editDesData, setEditDesData] = useState(null);
    const [editConnectionData, setEditConnectionData] = useState(null);
    const [editDesModalVisible, setEditDesModalVisible] = useState(false);
    const [core1, setCore1] = useState(null);
    const [core2, setCore2] = useState(null);
    const [cableId1, setCableId1] = useState(null);
    const [cableId2, setCableId2] = useState(null);
    const [keyNum, setKeyNum] = useState(null);
    const [refCable, setRefCable] = useState(null);
    const [position, setPosition] = useState(null);
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const ref = useRef(null);
    const submitRef = useRef(null);
  
    const LocationFinderDummy = () => {
        const map = useMapEvents({
            click(e) {
                form.setFieldsValue({
                    junction_id: details?.detail?.id,
                    lat : e.latlng.lat,
                    long : e.latlng.lng,
                }); 
              setPosition([e.latlng.lat, e.latlng.lng])
            },
        });
        return null;
    };
    
    const renderContent = (column = 3) => (
        <Descriptions size="small" column={column}>
          <Descriptions.Item label="ID"><strong>{details?.detail?.junction_id}</strong></Descriptions.Item>
          <Descriptions.Item label="Address"><strong>{details?.detail?.address}</strong></Descriptions.Item> 
          {/* <Descriptions.Item label="Mobile number"><strong>{details?.detail?.mobile_number}</strong></Descriptions.Item>  */}
          <Descriptions.Item label="Description"><strong>{details?.detail?.description}</strong></Descriptions.Item>
        </Descriptions>
      );
      
  
    const handleEdit = (data) => {
        setEditDesData(data?.detail)
        setEditDesModalVisible(true)
    }

    
    const constrolAssign = (data, key, cable_id) => {
        setKeyNum(key)
        if(core1 === null && core2 === null){
            
            setCore1(data)
            setCableId1(cable_id)
            if(core2 !== null){
                submitRef.current.focus();
            }
        }
        else if(core1 !== null && core2 === null){
            setCore2(data)
            setCableId2(cable_id)
            if(core1 !== null){
                submitRef.current.focus();
            }
        }
        else if(core2 !== null && core1 === null){
            setCore1(data)
            setCableId1(cable_id)
            if(core2 !== null){
                submitRef.current.focus();
            }
        }
        else return
    }
    
    const handleRemove = async (id) => {
        const res = await deleteCable(details?.detail?.id, id)
        if(res === 201){
            refresh(details?.detail?.id)
        }
        refreshApi()
    }
    
    const handleAssign = async () => {
        const res = await assignCore(core1?.id, core2?.id)
        if(res === 201){
            setCore1(null)
            setCore2(null)
            setCableId1(null)
            setCableId2(null)
            setKeyNum(null)
            refresh(details?.detail?.id)
        }
        refreshApi()
        
    }
  
    const handleClick = () => {
        ref.current.focus();
      };

      
    const removeAssign = async (data) => {
        const res = await removeCore(data?.id, data?.r?.id)
        if(res === 201){
            setCore1(null)
            setCore2(null)
            setCableId1(null)
            setCableId2(null)
            setKeyNum(null)
            refresh(details?.detail?.id)
        }
        refreshApi()
    
    }

    const onFinish = async(values) => {
        const res = await updateJunctionLocation(values)
        if(res === 201){
            refresh(details?.detail?.id)
            setPosition(null)
        }
    }

    const handleReset = async () => {
        setCore1(null)
        setCore2(null)
        setCableId1(null)
        setCableId2(null)
        setKeyNum(null)
    }
    const excelDownload = (data) => {
        const test = new Map();
        const cables = [];
        const createData = (fromId, fromColor, toId, toColor) => {
            return {fromId, fromColor, toId, toColor};
    };


    /*
        // for (let index = 0; index < data?.connection.length; index++) {
            data?.connection?.map(index => {

            
            test.set(index?.cable?.id, index?.cable)
            // cables.push(index?.cable)
            // console.log(data?.connection[index]?.cable?.id === '946fc288-dc09-4160-a147-26c8596c87f4')
            index?.cores?.map(item => {
                
                if(item?.r){
                    console.log(test)
                    // const res = getId(item?.r?.cable_id)
                    cables.push(createData(test.get(item?.cable_id)?.cable_id, item?.color,
                    test.get(item?.r?.cable_id)?.cable_id, item?.r?.color  ))
                    // console.log(test.get(item?.cable_id)?.cable_id)
                    // cables.push(item)
                }
            })
            })
        // }
        //
        console.log(cables)
    */
        // =====================

        const ulala = new Map()

        for(let i = 0 ; i< data?.connection?.length; i++){
            
            const cable = data?.connection[i]?.cable
            const core = data?.connection[i]?.cores
            for(let j = 0 ; j< core?.length; j++){
                ulala.set(core[j].id, { ...core[j], cable: cable });
            }

        }
        // console.log(ulala.keys());
        // console.log(ulala.get('887f32d1-0ab1-4c3d-9fee-fcfb8b4b40d3'))
        // console.log(ulala.get('2f67d832-aa47-445e-b9dc-303ce874f246'))

        for(let i = 0 ; i< data?.connection?.length; i++){
            const core = data?.connection[i]?.cores;
            const cable = data?.connection[i]?.cable;

            cables.push({
                'F Entity': "", 
                'F Cable Id': "", 
                'F Core Color': "",
                'cable' : cable.cable_id,
                'T Core Color': "",
                'T Cable Id': '',
                'T Entity': "", 
            })
            
            for(let j = 0 ; j < core?.length  ; j++){
                if(core[j]?.r){
                    cables.push({
                        'F Entity': core[j]?.connection[0]?.connection_model + `( ${core[j]?.connection[0]?.connection_name})`, 
                        'F Cable Id': cable?.cable_id + '(Tube-' + core[j]?.sub_cable_no +1 + ')',
                        'F Core Color': core[j]?.color,
                        'cable' : "",
                        'T Core Color': core[j]?.r?.color,
                        'T Cable Id': ulala.get(core[j]?.r?.id)?.cable?.cable_id + `(Tube- ${core[j]?.r?.sub_cable_no +1})`,
                        'T Entity':ulala.get(core[j]?.r?.id)?.connection[0]?.connection_model + `(${ulala.get(core[j]?.r?.id)?.connection[0]?.connection_name})`, 
                    })
                }
                else{
                    cables.push({
                        'F Entity': core[j]?.connection[0]?.connection_model + `( ${core[j]?.connection[0]?.connection_name})`, 
                        'F Cable Id': cable?.cable_id + '(Tube-' + core[j]?.sub_cable_no +1 + ')',
                        'F Core Color': core[j]?.color,
                        'cable' : "",
                        'T Core Color': "",
                        'T Cable Id': "",
                        'T Entity': "", 
                    })
                } 
            }
        }

        // console.log(cables);
           
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const ws = XLSX.utils.json_to_sheet(cables);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const exceldata = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(exceldata, 'connections' + '.xlsx');

    }

    const deleteContent = (id) => 
        ( <DeleteConfirmation id={id} fnc={deleteFnc} closeFnc={() => setOpen(false)}/>
        )

    const handleDelete = async (id, pass) => {
        closeFnc();
        const res = await deleteJunction(id, pass)
        if(res === 201){
            refreshApi()
        }
    }
    return ( 
        <>
        <Modal
            title="Junction Details"
            centered
            visible={visible}
            onCancel={() => closeFnc(false)}
            cancelText="Close"
            width={1000}
            okButtonProps={{
                style: {
                display: "none",
                },
            }}
      >
        <DetailBox>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Details" key="1">
                <PageHeader
                        className="site-page-header-responsive"
                        title={details?.detail?.name}
                        extra={[
                            <Button onClick={() =>{ handleEdit(details)}} size="small" icon={<EditOutlined />} type="primary" title='Edit'></Button>,
                            <Button size="small" icon={<DeleteOutlined />} onClick={() => setOpen(true)} type="primary" title='Edit' danger>Delete Junction</Button>
                            
                        ]}
                    >
                        {open ? 
                            <DeleteConfirmation 
                                id={details?.detail?.id}
                                fnc={handleDelete}
                                open={open} 
                                closeFnc={() => setOpen(false)}
                            /> 
                        : null}
                        <Content>{renderContent()}</Content>
                        <hr />
                        <br />
                            <PointBox style={{ display: 'flex', justifyContent: 'center', width: '100%'}}>
                                <h3><strong>Update Location</strong></h3>
                                <Row>
                                    <Col xs={14}>
                                        <MapContainer 
                                            attributionControl={false}
                                            center={details?.detail?.location ? details?.detail?.location : [23.785638540195837, 90.42570563577543]} 
                                            zoom={13} 
                                            zoomControl={false}
                                            style={{ height: '250px', width: '100%', alignSelf:'center' }}
                                        >
                                            
                                            <LocationFinderDummy />

                                            <TileLayer
                                                url="https://fm.hidayahsmart.solutions/tile/{z}/{x}/{y}.png"
                                            />
                                            
                                            {position ? 
                                                <Marker position={position} icon={icon()} >
                                                    
                                                </Marker>
                                            : null}
                                            {details?.detail?.location ?
                                                <Marker position={details?.detail?.location} icon={icon()} >
                                                <Popup>
                                                    {details?.detail?.name}
                                                </Popup>
                                                </Marker>
                                            :null}
                                            
                                        </MapContainer>
                                    </Col>
                                    
                                    <Col xs={2}></Col>
                                    <Col xs={8}>
                                        <Form
                                        name="update location"
                                        labelCol={{
                                        span: 24,
                                        }}
                                        wrapperCol={{
                                        span: 24,
                                        }}
                                        initialValues={{
                                        remember: true,
                                        }}
                                        onFinish={onFinish}
                                        autoComplete="off"
                                        form={form}
                                    >
                                            <Form.Item
                                                label="Junction ID"
                                                name="junction_id"
                                                rules={[{ required: true, message: 'Required' }]}
                                                style={{display: 'none'}}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                label="Latitude"
                                                name="lat"
                                                rules={[{ required: true, message: 'Required' }]}
                                            >
                                                <Input disabled/>
                                            </Form.Item>
                                            <Form.Item
                                                label="Longitude"
                                                name="long"
                                                rules={[{ required: true, message: 'Required' }]}
                                            >
                                                <Input disabled/>
                                            </Form.Item>
                                            <Form.Item
                                                wrapperCol={{
                                                offset: 8,
                                                span: 16,
                                                }}
                                            >
                                                <Button type="primary" htmlType="submit">
                                                Submit
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </Row>
                            </PointBox>
                        
                </PageHeader>
                </TabPane>
                <TabPane tab="Connection" key="2">
                    
                    <ConnectionBox>
                        {cableId1 ?
                        <>
                            <InputCore ref={submitRef} value=" "/>
                            <p style={{backgroundColor: core1?.color, color: '#000'}}>Cable {cableId1} Tube {core1?.sub_cable_no + 1 } {core1?.color}  </p>
                            <p>-----Connected with-----</p>
                            </>:null}
                        
                            {cableId2 ?
                            <p style={{backgroundColor: core2?.color}}>Cable {cableId2} Tube {core2?.sub_cable_no + 1 } {core2?.color}</p>
                            :null}
                            {cableId1 && cableId2 ?
                            <>
                            <p onClick={handleAssign} style={{padding: '5px 20px', backgroundColor: 'green', color: '#FFF', cursor: 'pointer'}}>Submit</p>
                            <p onClick={handleReset} style={{padding: '5px 20px', backgroundColor: 'red', color: '#FFF', cursor: 'pointer'}}>Reset</p>
                            </>: 
                        null}
                    
                    </ConnectionBox>

                    {details?.connection?.length > 0 ? 
                        <Row>
                            <Col xs={24}>
                                <Button style={{ float: 'right'}} type='primary' onClick={() => excelDownload(details)}>Download</Button>
                            </Col>
                            {details?.connection?.map((con, id) => (
                            <Col xs={24}>
                            <Row style={{boxShadow: '1px 1px 4px 1px rgba(0,0,0,0.2)',padding: '5px', margin: '5px 0px'}}>
                            <Col xs={8}>
                                <PointBox>
                                    <div>
                                        <h4>Cable Details</h4>
                                        <h2>Cable Id: <strong>{con?.cable?.cable_id}</strong></h2>
                                        <h2>Core: <strong>{con?.cable?.core}</strong></h2>
                                        <Popconfirm title="Are you sure?" okText="Yes" cancelText='No' onConfirm={() => handleRemove(con?.cable?.id)}>
                                            <Button danger type='primary'>Remove</Button>
                                        </Popconfirm>
                                    </div>
                                </PointBox>
                            </Col>
                            <Col xs={16}>
                                <PointBox>
                                    <div>
                                        <h4>Core Details &nbsp; </h4>
                                        {con?.cores?.map((item, key) => (
                                            item?.r 
                                                ? 
                                                // <Selected status ={item?.r}  >
                                                <Selected >
                                                    
                                                    {/* {console.log(item?.connection[0] ? item?.connection[0] : 'none')} */}
                                                    <p style={ 
                                                        refCable === item?.id 
                                                        ? 
                                                        {backgroundColor: '#1abc9c', cursor: 'pointer'}
                                                         : 
                                                         refCable === item?.r?.id ?
                                                         {backgroundColor: '#1abc9c', cursor: 'pointer'}
                                                         :
                                                         {cursor: 'pointer'}
                                                        } onClick={() => {setRefCable(item?.r?.id);handleClick();}}>Tube -{item?.sub_cable_no}  &nbsp; 
                                                    <Tag 
                                                        color={item?.color}
                                                        
                                                        >
                                                            {refCable === item?.id ?
                                                                <InputCore value=" " ref={ref} id="message"  autocomplete="off" />
                                                            : null
                                                        }
                                                        <span>Connected</span></Tag>
                                                  
                                                    {/* <span>Tube -{item?.r?.sub_cable_no}  &nbsp; 
                                                        <Tag color={item?.r?.color}>Connected</Tag>
                                                    </span> */}

                                                    {core1 === null || core2 === null ?
                                                        keyNum === id ?
                                                        <Button size="small"disabled>Remove</Button>
                                                        :
                                                    <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => {removeAssign(item)}}>
                                                        <Button size="small"  type="primary"danger >Remove</Button>
                                                    </Popconfirm>
                                                    :
                                                    null
                                                    }
                                                    
                                                    {core1 !== null && core2 !== null ?
                                                    // <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => {handleAssign(item,'remove')}}>
                                                        <Button onClick={() => constrolAssign(item, id, con?.cable?.cable_id)}  size="small"  type="primary" danger disabled >Select</Button>
                                                    // </Popconfirm>
                                                    :
                                                    null
                                                    }
                                                    
                                                    
                                                    
                                                    <span style={{ float: 'right'}}>
                                                        {item?.connection[0] ?
                                                        <>
                                                        Connected with - <strong>{ item?.connection[0]?.connection_model} ({ item?.connection[0]?.connection_name})</strong> 
                                                        </>
                                                        : 'Not Connected'}
                                                    </span>
                                                    <br />
                                                    </p>
                                                   
                                                    
                                                </Selected>
                                                :

                                                <Selected status ={core1?.id === item?.id || core2?.id === item?.id}  >
                                                    <p>Tube -{item?.sub_cable_no}  &nbsp; 
                                                    {item?.r ? 
                                                        <Tag color={item?.color} ><span> Used</span></Tag>
                                                    :
                                                    <Tag color={item?.color} className='glow-on-hover' style={{ color: item?.color}}><span> Unused</span></Tag>
                                                    }
                                                    {core1 === null || core2 === null ?
                                                        keyNum === id ?
                                                        <Button onClick={() => constrolAssign(item, id, con?.cable?.cable_id)}  size="small"  type="primary" disabled>Select</Button>
                                                        :
                                                    // <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => {handleAssign(item,'remove')}}>
                                                        <Button onClick={() => constrolAssign(item, id, con?.cable?.cable_id)}  size="small"  type="primary" >Select</Button>
                                                    // </Popconfirm>
                                                    :
                                                    null
                                                    }
                                                    
                                                    {core1 !== null && core2 !== null ?
                                                    // <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => {handleAssign(item,'remove')}}>
                                                        <Button onClick={() => constrolAssign(item, id, con?.cable?.cable_id)}  size="small"  type="primary"  disabled >Select</Button>
                                                    // </Popconfirm>
                                                    :
                                                    null
                                                    }
                                                    
                                                    <span style={{ float: 'right'}}>
                                                        {item?.connection[0] ?
                                                        <>
                                                        Connected with - <strong>{ item?.connection[0]?.connection_model} ({ item?.connection[0]?.connection_name}) </strong> 
                                                        </>
                                                        : 'Not Connected'}
                                                    </span>
                                                    <br />
                                                    </p>
                                                    
                                                </Selected>
                                            
                                        ))}
                                    </div>
                                    
                                </PointBox>
                            </Col>
                            </Row>
                            </Col>
                            ))}
                            
                        </Row>
                    : <Empty />}
                </TabPane>
            </Tabs>
        </DetailBox>
        </Modal>
      
        {editDesData ?<JunctionDetailsUpdate refresh={refresh} data={editDesData} visible={editDesModalVisible} onCancel={ () => {setEditDesModalVisible(false); setEditDesData(null)}} />:null}
   
        </>
     );
}
 
export default connect(null,{getJunctionDetails,assignCore, removeCore, deleteJunction, updateJunctionLocation, deleteCable})(JunctionDetails);