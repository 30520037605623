import api from "./apis";
import history from "../history";
import {
  ERROR_MESSAGE,
  LOADED,
  LOADING,
  SUCCESS_MESSAGE,
  VALIDATION_ERROR,
} from "./type";
import axios from "axios";

// ============Fetch Orders=====
export const refresh = (shop, s, p, rmn, ds, de, mo, o) => async (dispatch) => {
  // dispatch({type: LOADING});
  //   try {
  //   const response =  await api(localStorage.getItem("token")).get(`/refresh`);
  //     if (response.status === 200) {
  //       localStorage.setItem('token', response?.data?.token)
  //       localStorage.setItem('lan', response?.data?.language)
  //       localStorage.setItem('name', response?.data?.name)
  //       localStorage.setItem('mobile_number', response?.data?.mobile_number)
  //       localStorage.setItem('email', response?.data?.email)
  //     } else {
  //       dispatch({ type: LOADED });
  //     }
  //   } catch (err) {
  //      if(err.response)
  //         {
  //           dispatch( {type: LOADED });
  //             if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
  //             if(err.response.status === 422){
  //               // dispatch({
  //               //   type: ERROR_MESSAGE,
  //               //   payload: err.response ? err.response.data.message : history.push('/404'),
  //               // })}
  //         }
  //       }
  //     dispatch({ type: LOADED });
  //     dispatch({
  //       type: ERROR_MESSAGE,
  //       payload: err.response ? err.response.data.message : history.push('/404'),
  //     });
  //   }
};

export const getArea = (data) => (dispatch) => {
  // console.log(data)
  //   try {

  const response = axios
    .get(`https://fm.hidayahsmart.solutions/search.php?q=${data}`)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => console.log(error));

  return response;
};
//     if (response.status === 200) {

//       localStorage.setItem('token', response?.data?.token)
//       localStorage.setItem('lan', response?.data?.language)
//       localStorage.setItem('name', response?.data?.name)
//       localStorage.setItem('mobile_number', response?.data?.mobile_number)
//       localStorage.setItem('email', response?.data?.email)
//     } else {
//       dispatch({ type: LOADED });
//     }
//   } catch (err) {
//      if(err.response)
//         {
//           dispatch( {type: LOADED });
//             if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
//             if(err.response.status === 422){
//               // dispatch({
//               //   type: ERROR_MESSAGE,
//               //   payload: err.response ? err.response.data.message : history.push('/404'),
//               // })}
//         }
//       }
//     dispatch({ type: LOADED });
//     dispatch({
//       type: ERROR_MESSAGE,
//       payload: err.response ? err.response.data.message : history.push('/404'),
//     });
//   }
// }
