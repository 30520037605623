import { Button, Col, Drawer, Form, Input,  Row, Select } from 'antd';
import React, { useEffect, useState } from 'react'

import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { CaretRightOutlined } from '@ant-design/icons'
import { createJunction, getJunction, getJunctionDetails} from '../../action/junction';
import { connect } from 'react-redux';
import { ScrollAble, PopWrapper } from '../../styles/globalStyle';
const { Option } = Select;
const JunctionList = ({ getJunction, position = [], createJunction,  getJunctionDetails, fnc1, fnc2, data1, refreshApi }) => {

    const [details, setDetails] = useState(null);
    const [visible, setVisible] = useState(false);
    const [createDiv, setCreateDiv] = useState(false)
    const [allJunction, setAllJunction] = useState(false)
    const [form] = Form.useForm();

    useEffect( () => {
      window.scrollTo(0, 0)
      getData('')
    }, [])

    const getData = async (search = '') => {
      const res = await getJunction(search);
      if(res){
        setAllJunction(res)
      }
    }

  const handleSearch = (e) => {
    getData(e)
  }
    return ( 
        <>
       
          {allJunction ?
            <>
            <Form.Item
              label=""
            >    
              <Input
                placeholder="Search Junction"
                allowClear 
                onChange={e => handleSearch(e.target.value)}
              />
            </Form.Item>
            
          <ScrollAble>
              {allJunction?.map((item,id) => (
                  <PopWrapper active={item} onClick={ () => {fnc1(item); fnc2(item?.position);}}>
                    <Row>
                      <Col xs={3}>
                        <img src='./Junction.png'style={{width: '15px', marginBottom: '8px'}}/>
                      </Col>
                      <Col xs={18} style={{}}>
                        <h6 style={{padding: '0px', fontWeight: 'bold',color: data1?.junction_id === item?.junction_id ? 'red' : null}}>
                          {item.name}
                        </h6>
                        <h6 style={{}}>{item.text}</h6>
                      </Col>
                      <Col xs={3}><CaretRightOutlined /></Col>
                    </Row>
                    
                    
                  </PopWrapper>
              ))}
          </ScrollAble>
            </>
          :null}
          
        </>
     );
}
 
export default connect(null,{ createJunction, getJunction, getJunctionDetails })(JunctionList);