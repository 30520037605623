import { Col, Form, Input,  Row } from 'antd';
import React, { useEffect, useState } from 'react'

import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { CaretRightOutlined } from '@ant-design/icons'
import {getPops, createPop, getPopDetails} from '../../action/pop';
import { connect } from 'react-redux';
import { PopWrapper, ScrollAble } from '../../styles/globalStyle';

const Pop = ({ getPops, fnc1, fnc2, data1, }) => {
    const [allPops, setAllPops] = useState(null);

    useEffect( () => {
      window.scrollTo(0, 0)
      getData('')
    }, [])

  const getData = async (search = '') => {
    const res = await getPops(search);
    if(res){
      setAllPops(res)
    }
  }

  const handleSearch = (e) => {
    getData(e)
  }
    return ( 
        <>
          {allPops ?
            <>
              <Form.Item
                label=""
              >    
                <Input
                  placeholder="Search POP"
                  allowClear 
                  onChange={e => handleSearch(e.target.value)}
                />
              </Form.Item>
              
              <ScrollAble>
                {allPops?.map((item,id) => (
                    <PopWrapper active={item} onClick={ () => {fnc1(item); fnc2(item?.position)}}>
                      <Row>
                        <Col xs={3}>
                          <img src='./POP.png'style={{width: '15px', marginBottom: '8px'}}/>
                        </Col>
                        <Col xs={18} style={{}}>
                          <h6 style={{padding: '0px', fontWeight: 'bold',color: data1.pop_id === item?.pop_id ? 'red' : null}}>
                            {item.name}
                          </h6>
                          <h6 style={{}}>{item.text}</h6>
                        </Col>
                        <Col xs={3}><CaretRightOutlined style={{color: '#000000'}} /></Col>
                      </Row>
                      
                    </PopWrapper>
                ))}
              </ScrollAble>
            </>
          :null
          }
         
        </>
     );
}
 
export default connect(null,{ createPop, getPops, getPopDetails })(Pop);