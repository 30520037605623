import { useEffect } from "react";
import { useMap } from "react-leaflet";
import { GeoSearchControl } from "leaflet-geosearch";
import "./react-leaflet-geosearch.css";
import L from "leaflet";
import Pin from './pin.png';
const SearchControl = (props) => {
  const map = useMap();

  useEffect(() => {
    
    const LeafIcon = L.Icon.extend({
      options: {
        iconSize:     [40, 40],
     }
    });

    const pointericon = (data) => new LeafIcon({
      iconUrl:  Pin,
      // iconUrl:  `./Junction.png`,
      iconSize: [40, 40],
      iconAnchor: [28 , 40],
      popupAnchor: [10, 10],
    })

    const searchControl = new GeoSearchControl({
      provider: props.provider,
      marker: {
        // optional: L.Marker    - default L.Icon.Default
        icon: pointericon(),
        draggable: true
      },
      keepResult: false,

      resultFormat: ({ result }) => result.label,
      ...props
    });
    map.on("geosearch/showlocation", function (e) {
      props?.fnc([e.location?.y, e.location?.x])
    });
    map.on("geosearch/marker/dragend", function (e) {
      props?.fnc([e.location?.lat, e.location?.lng])
      // console.log(e.location);
    });
    map.addControl(searchControl);
    return () => map.removeControl(searchControl);
  }, [map, props]);

  return null;
};
export default SearchControl;
