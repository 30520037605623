import axios from 'axios';
import history from '../history';
import api from './apis';

import {
    AUTH_ERROR,
    AUTH_SUCCESS,
    ERROR_MESSAGE,
    LOADED,
    LOADING,
    SUCCESS_MESSAGE,
    VALIDATION_AUTH_ERROR,
    VALIDATION_ERROR,
} from './type';



  export const getClients = (search) => async (dispatch)=> {
      
      dispatch({type: LOADING});
      try{
          const response = await api(localStorage.getItem("token")).get(`/client/list?search=${search}`);
          dispatch({type: LOADED});
          return response?.data?.list;
      }catch(err){
          /* =============Start========== */
            if(err?.response?.status === 422){
              err.response.data.errors.map(item => {
                  dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
              })
            }
            if(err?.response?.status === 401 || err?.response?.status === 403 ){
                dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
                localStorage.clear();
                history.push('/login')
            }
            dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
            dispatch({type: LOADED});
            /* ===========End============*/
      
  }
  }

  export const createClient = (data) => async (dispatch) => {
    
      dispatch({type: LOADING});
     
      const object = {
        name: data?.name,
        description: data?.description,
      };
    
      try {
        const response = await api(localStorage.getItem("token")).post(
          "/client/create",
          { ...data }
        );
    
        if (response.status === 201) {
          
        dispatch({type: LOADED});
        dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
        return response.status;
        }
      } catch (err) {
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
          dispatch({type: LOADED});
        /* ===========End============*/
      }
    };

  export const getClientDetails = (client_id) => async (dispatch)=> {
      
      dispatch({type: LOADING});
      try{
          const response = await api(localStorage.getItem("token")).get(`/client/detail?client_id=${client_id}`);
          dispatch({type: LOADED});
          return response?.data;
          
      }catch(err){
          /* =============Start========== */
            if(err?.response?.status === 422){
              err.response.data.errors.map(item => {
                  dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
              })
            }
            if(err?.response?.status === 401 || err?.response?.status === 403 ){
                dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
                localStorage.clear();
                history.push('/login')
            }
            dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
            dispatch({type: LOADED});
            /* ===========End============*/
      
  }
  }
  export const assignCore = (client_id,core_id) => async (dispatch) => {
    
      dispatch({type: LOADING});
     
      const object = {
        client_id: client_id,
        core_id: core_id,
      };
    
      try {
        const response = await api(localStorage.getItem("token")).patch(
          "/client/assign/core",
          { ...object }
        );
    
        if (response.status === 201) {
          
        dispatch({type: LOADED});
        dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
        return response.status;
        }
      } catch (err) {
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
          dispatch({type: LOADED});
        /* ===========End============*/
      }
    };
  export const removeCore = (client_id,core_id) => async (dispatch) => {
    
      dispatch({type: LOADING});
     
      const object = {
        client_id: client_id,
        core_id: core_id,
      };
    
      try {
        const response = await api(localStorage.getItem("token")).patch(
          "/client/remove/core",
          { ...object }
        );
    
        if (response.status === 201) {
          
        dispatch({type: LOADED});
        dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
        return response.status;
        }
      } catch (err) {
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
          dispatch({type: LOADED});
        /* ===========End============*/
      }
    };
  export const updateClientDetails = (data, client_id) => async (dispatch) => {
    
      dispatch({type: LOADING});
      
    
      try {
        const response = await api(localStorage.getItem("token")).patch(
          "/client/update/detail",
          { ...data }
        );
    
        if (response.status === 201) {
          
        dispatch({type: LOADED});
        dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
        return response.status;
        }
      } catch (err) {
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
          dispatch({type: LOADED});
        /* ===========End============*/
      }
    };
  export const deleteClient = (client_id, password) => async (dispatch) => {
      dispatch({type: LOADING});
      try {
        const response = await api(localStorage.getItem("token")).patch(
          "/client/delete",
          { 
            client_id: client_id,
            password: password 
          }
        );
    
        if (response.status === 201) {
          
        dispatch({type: LOADED});
        dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
        return response.status;
        }
      } catch (err) {
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
          dispatch({type: LOADED});
        /* ===========End============*/
      }
    };
  export const updateClientLocation = (data) => async (dispatch) => {
    
      dispatch({type: LOADING});
      
    
      try {
        const response = await api(localStorage.getItem("token")).patch(
          "/client/update/location",
          { ...data }
        );
    
        if (response.status === 201) {
          dispatch({type: LOADED});
          dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
          return response.status;
        }
      } catch (err) {
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
          dispatch({type: LOADED});
        /* ===========End============*/
      }
    };
  export const deleteCable = (client_id, cable_id) => async (dispatch) => {
      dispatch({type: LOADING});
      try {
        const response = await api(localStorage.getItem("token")).patch(
          "/client/remove/cable",
          { 
            client_id: client_id,
            cable_id: cable_id,
           }
        );
    
        if (response.status === 201) {
          
        dispatch({type: LOADED});
        dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
        return response.status;
        }
      } catch (err) {
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
          dispatch({type: LOADED});
        /* ===========End============*/
      }
    };


