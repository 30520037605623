// ==============================================Helper================
export const TOPBAR_STATUS = 'TOPBAR_STATUS';
export const CREATE_SHOP_MODAL = 'CREATE_SHOP_MODAL';
export const PAYMENT_MODAL = 'PAYMENT_MODAL';
export const RESET_MESSAGES = 'RESET_MESSAGES';

export const COUNTER = 'COUNTER';


// ======================================Login===================
export const LOGIN = "LOGIN";


// ======================================Loading===================
export const LOADING = "LOADING";
export const LOADED = "LOADED";


// ======================================Messages===================
export const ERROR_MESSAGE = "ERROR_MESSAGE";
export const VALIDATION_ERROR = "VALIDATION_ERROR";
export const SUCCESS_MESSAGE = "SUCCESS_MESSAGE";
export const AUTH_ERROR = "AUTH_ERROR";
export const VALIDATION_AUTH_ERROR = "VALIDATION_AUTH_ERROR";
export const AUTH_SUCCESS = "AUTH_SUCCESS";

//========================Coverage Area================
export const COVERAGE_AREA = "COVERAGE_AREA";