import axios from 'axios';
import history from '../history';
import api from './apis';

import {
    AUTH_ERROR,
    AUTH_SUCCESS,
    ERROR_MESSAGE,
    LOADED,
    LOADING,
    SUCCESS_MESSAGE,
    VALIDATION_AUTH_ERROR,
    VALIDATION_ERROR,
} from './type';


export const allPoint = () => async (dispatch)=> {
    
  dispatch({type: LOADING});
  try{
      const response = await api(localStorage.getItem("token")).get('/point/all');
      dispatch({type: LOADED});
      return response?.data?.data
      
  }catch(err){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
        /* ===========End============*/
  
}
}
export const getPops = () => async (dispatch)=> {
    
  dispatch({type: LOADING});
  try{
      const response = await api(localStorage.getItem("token")).get('/map-management/map/pin');
      dispatch({type: LOADED});
      return response?.data?.list
      
  }catch(err){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
        /* ===========End============*/
  
}
}

export const getLayers = () => async (dispatch)=> {
    
    dispatch({type: LOADING});
    try{
        const response = await api(localStorage.getItem("token")).get('/map-management/map/layer');
        
        dispatch({type: LOADED});
        return response?.data?.list;
        // return response.data.roles
        // dispatch({type: GET_ROLES, payload: response.data.roles})
        
    }catch(err){
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
          dispatch({type: LOADED});
          /* ===========End============*/
    
}
}

export const createLayer = (data) => async (dispatch) => {
  
    dispatch({type: LOADING});
   
    const object = {
      name: data?.name,
      lat: data?.lat,
      long: data?.long,
      description: data?.description,
      layer: data?.layer,
      new_layer: data?.new_layer,
    };
  
    try {
      const response = await api(localStorage.getItem("token")).post(
        "/map-management/map/pin/create",
        { ...object }
      );
  
      if (response.status === 201) {
        
      dispatch({type: LOADED});
      dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
      return response.status;
      }
    } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
  };

  export const getLocationData = (id) => async (dispatch)=> {
    
    dispatch({type: LOADING});
    try{
        const response = await api(localStorage.getItem("token")).get(`/map-management/map/pin/detail?location=${id}`);
        
        dispatch({type: LOADED});
        return response.data.location
    }catch(err){
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
          dispatch({type: LOADED});
          /* ===========End============*/
    
  }
  }
  export const updateLayer = (data,location) => async (dispatch) => {
    
      dispatch({type: LOADING});
     
      const object = {
        location: location,
        name: data?.name,
        lat: data?.lat,
        long: data?.long,
        description: data?.description,
        layer: data?.layer,
        new_layer: data?.new_layer,
      };
    
      try {
        const response = await api(localStorage.getItem("token")).patch(
          "/map-management/map/pin/update",
          { ...object }
        );
    
        if (response.status === 201) {
          
        dispatch({type: LOADED});
        dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
        return response.status;
        }
      } catch (err) {
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
          dispatch({type: LOADED});
        /* ===========End============*/
      }
    };
  export const deleteLayer = (location) => async (dispatch) => {
    
      dispatch({type: LOADING});
     
      const object = {
        location: location,
      };
    
      try {
        const response = await api(localStorage.getItem("token")).patch(
          "/map-management/map/pin/delete",
          { ...object }
        );
    
        if (response.status === 201) {
          
        dispatch({type: LOADED});
        dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
        return response.status;
        }
      } catch (err) {
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
          dispatch({type: LOADED});
        /* ===========End============*/
      }
    };