import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from "styled-components";
import Sidebar from '../../components/sidebar';
import Topbar from '../../components/topbar';
import history from '../../history';
import Switcher from './switcher';
import '../../styles/default.css';
import MessageAlert from '../../components/messageAlert';
const { Header, Content, Sider,Footer } = Layout;
const MainLayout = ({validationError, success, error, loading, refresh}) => {


  useEffect( () => {
    if(localStorage.getItem('token') === null){
      localStorage.clear();
      history.push('/login')
    }
  }, [])
    return ( 
        <>
        {/* {loading ?
        <BarLoader color="#b91e1e" loading={loading}  size={150} css={override} />
      :
          <p style={{margin:'0px',padding: '2px',height: '4px', backgroundColor: '#fff', position: 'fixed', width: '100%', zIndex:'100'}}> </p>
      } */}
        
        {success ? <MessageAlert type="success" value={success} /> : null}
        {error ? <MessageAlert type="error" value={error} /> : null}
        {validationError ? 
          validationError.map((item,id )=> (
            <MessageAlert type="error" value={item.message} />
            ))
            :
            null
        }

        {/* === Main Layout with bgc === */}
        <Layout className='site-layout'>
          {/* <Sidebar /> */}
          <Layout>
          <Topbar />
            {/* === Main content with fix height === */}
            <Content>
                <Switcher />
            </Content>

            {/* === Footer === */}
            <Footer  > <p style={{textAlign: 'center', margin: '0px'}}>Copyright © 2023 Hidayah Smart Solutions. All Rights Reserved.</p></Footer>
            </Layout>
        </Layout>
        </>
     );
}
const mapStateToProps = (state) => { 
    return {
      loading: state.helpers.loading,
      success: state.helpers.success,
      error: state.helpers.error,
      // validationError: state.helpers.validationError,
    }
  }
export default connect(mapStateToProps)(MainLayout);