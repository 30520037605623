import React, { useEffect, useState } from 'react';
import { Button, Descriptions,Empty , Select, PageHeader, Form, Row, Col, Tabs, Modal, Tag, Popconfirm } from 'antd';
import { PointBox } from './style';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import CableDetailsUpdate from './cableDetailsUpdate';
import CableConnectionUpdate from './cableConnectionUpdate';
import {removeCableConnection} from '../../action/cable'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import {connect}  from 'react-redux'
import * as L from "leaflet";
import {
    Marker,
    MapContainer,
    Popup,
    TileLayer,
    Polyline,
    Circle,
    Pane,
    FeatureGroup,
    useMap,
  } from 'react-leaflet'
import { DetailBox } from '../../styles/globalStyle';
import { EditControl } from "react-leaflet-draw";
import history from '../../history';
import DeleteConfirmation from '../../components/deleteConfirmation';
const { TabPane } = Tabs;
const { Option } = Select;

const CableDetails = ({
    core, details,setVisible, 
    visible, refresh,
    allPops, allClient, allJunction,
    removeCableConnection,deleteFnc,
    bPoint, bPointFnc, refreshFnc,
    }) => {
        const LeafIcon = L.Icon.extend({
            options: {
              iconSize:     [40, 40],
           }
          });
       
    const starticon = (data) => new LeafIcon({
        iconUrl:  `./start.png`,
        iconSize: [20, 20],
        iconAnchor: [12, 12],
        popupAnchor: [0, 0],
      })
    const endicon = (data) => new LeafIcon({
        iconUrl:  `./end.png`,
        iconSize: [22, 22],
        iconAnchor: [12, 12],
        popupAnchor: [0, 0],
      })
    const [editDesData, setEditDesData] = useState(null);
    const [editConnectionData, setEditConnectionData] = useState(null);
    const [editDesModalVisible, setEditDesModalVisible] = useState(false);
    const [editConModalVisible, setEditConModalVisible] = useState(false);
    const [point, setPoint] = useState(null);
    const [estBpoint, setEstBpoint] = useState(null);
    const [LeafletId, setLeafletId] = useState(null);
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();

    const renderContent = (column = 3) => (
        <Descriptions size="small" column={column}>
          <Descriptions.Item label="Number of core">{details?.core}</Descriptions.Item>
          <Descriptions.Item label="Length of Connection">{details?.length} miter 
          </Descriptions.Item>
          <Descriptions.Item label="Description">{details?.description}</Descriptions.Item>
        </Descriptions>
      );
      
      const Content = ({ children, extra }) => (
        <div className="content">
          <div className="main">{children}</div>
          <div className="extra">{extra}</div>
        </div>
      );
      const handleCableEdit = (data) => {
        setEditDesData(data)
        setEditDesModalVisible(true)
      }
      const handleCableConnectionEdit = (data, p) => {
        setPoint(p)
        setEditConnectionData(data)
        setEditConModalVisible(true)
      }
  
      const handleDelete = async (id, point) => {
        const res =  await removeCableConnection(id, point)
        if(res === 201){
            refresh(id)
        }
      }
      
      const coreCount = (data) => {
        // console.log(data)
        let t = 0;
        let f = 0;
        for (let index = 0; index < data?.length; index++) {
            if(data[index]?.used === true)
            {
                t=t+1
            }
            if(data[index]?.used === false)
            {
                f=f+1
            }
            
        }
        return f
      }
      const getbPoint = (e) => {
    //   console.log(bPoint)

        let tot = 0;
        let estCor = [];
      for(let i=1; i < details?.location_front.length; i++ ){
        // console.log(details?.location_front[i - 1][0],details?.location_front[i - 1][1], details?.location_front[i][0],details?.location_front[i][1])
        let a = calDistance(details?.location_front[i - 1][0],details?.location_front[i - 1][1], details?.location_front[i][0],details?.location_front[i][1]);
        // console.log(details?.location_front[i - 1][0],details?.location_front[i - 1][1], details?.location_front[i][0],details?.location_front[i][1])
        tot += a; 
        console.log(tot * 1000)
        if(bPoint > tot*1000){
            // console.log(bPoint)
            console.log(i)
            estCor.push([details?.location_front[i][0], details?.location_front[i][1]])
            // console.log(details?.location_front[i - 1][0], details?.location_front[i - 1][1])
            // break;
        }
        // if(bPoint ){
        //     estCor.push([details?.location_front[0][0], details?.location_front[0][1]])
        // }

        
    //   console.log(tot*1000 > bPoint)
    //   console.log(tot*1000 < bPoint)
      }
      setEstBpoint(estCor[estCor?.length - 1])
      }

      function toRad(Value) {
        return Value * Math.PI / 180;
    }

      function calDistance(lat1, lon1, lat2, lon2) {
      
        try{
    
            // console.log(lat1, lon1, lat2, lon2);
    
            var R = 6371; // km
            var dLat = toRad(lat2-lat1);
            var dLon = toRad(lon2-lon1);
            var lat1 = toRad(lat1);
            var lat2 = toRad(lat2);
    
            var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
            var d = R * c;
  
            // console.log(d)
            return d
            
        }catch(error){
            console.log(error)
            return false
        }
        
    }
    const _onEditVertex = (e) => {
        console.log(e)
    }
    const _onEdited = (e) => {
        console.log(e);
        try {
          const {
            layers: { _layers },
          } = e;
    
        //   const hello = [];
        //   console.log(e.layers._layers[LeafletId]._latlngs);
    
        //   for (let i = 0; i < e.layers._layers[LeafletId]._latlngs.length; i++) {
        //     hello.push([
        //       e.layers._layers[LeafletId]._latlngs[i].lat,
        //       e.layers._layers[LeafletId]._latlngs[i].lng,
        //     ]);
        //   }
    
        //   setData2(hello);
        } catch (error) {
          console.log(error);
        }
        // console.log(e);
      };
    
  const RecenterAutomatically = ({lat,lng}) => {
    const map = useMap();
     useEffect(() => {
       map.setView([lat, lng]);
     }, [lat, lng]);
     return null;
   }
const makeLine = (lat1, lon1, lat2, lon2) => (
    // console.log([[lat1, lon1],[lat2, lon2]])
    <Polyline
    // dashArray= { '2, 5'}
    pathOptions={{ 
        color: 'black',
        weight: '5',
    }}
    lineCap="Ok"
    positions={[[lat1, lon1],[lat2, lon2]]}
    
></Polyline>
)

const lineHelper = (e) => {
    let a = [];
    for(let i=1; i < e.length; i++ ){
       a.push(makeLine(e[i - 1][0],e[i - 1][1], e[i][0],e[i][1]))
       
    } 
  return a;
  }
    return ( 
        <>
        <Modal
        title="Cable Details"
        centered
        visible={visible}
        onCancel={() => {setVisible(false)}}
        cancelText="Close"
        width={1000}
        okButtonProps={{
            style: {
              display: "none",
            },
          }}
      >
            <DetailBox>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Details" key="1">
                        <PageHeader
                            className="site-page-header-responsive"
                            title={details?.cable_id}
                            subTitle={details?.type}
                            extra={[
                                <Button onClick={() =>{ handleCableEdit(details)}} size="small" icon={<EditOutlined />} type="primary" title='Edit'></Button>,
                                
                                <Button onClick={() => {
                                    localStorage.setItem('cordinates', JSON.stringify(details));
                                    history.push('/cable-cut', {allRefresh: 'refreshFnc'});
                                    setVisible(false)
                                }} size="small" icon={<EditOutlined />} type="primary" title='Edit' danger>Cable Cut</Button>,
                                
                                <Button onClick={() => setOpen(true)} size="small" icon={<DeleteOutlined />} type="primary" title='Edit' danger>Delete Cable</Button>
                                ,
                            ]}
                        >
                            {open ? 
                                <DeleteConfirmation
                                    id={details?.id}
                                    fnc={deleteFnc}
                                    open={open} 
                                    closeFnc={() => setOpen(false)}
                                /> 
                            : null}
                            <Content>{renderContent()}</Content>
                            <hr />
                            <br />
                                <Row>
                                    <Col xs={12}>
                                        <PointBox>
                                            <h4>Start Point 
                                                &nbsp;
                                                <Button onClick={() => handleCableConnectionEdit(details, 'start')} size="small" icon={<EditOutlined />} type="primary" title='Edit'></Button>
                                                <Button onClick={() => handleDelete(details?.id, 'start')} size="small" icon={<DeleteOutlined />} type="primary" danger title='Edit'></Button>
                                            </h4>
                                            {details?.connection_start_model ? 
                                            <>
                                            <p>Model: <strong>{details?.connection_start_model}</strong></p>
                                            <p>Name: <strong>{details?.connection_start_point?.name}</strong></p>
                                            <p>Latitude: <strong>{details?.connection_start_point?.location[0]}</strong></p>
                                            <p>Longitude: <strong>{details?.connection_start_point?.location[1]}</strong></p>
                                            {/* <p>{coreCount(core[0]?.cores)}</p> */}
                                            <p>Unused: {coreCount(core[0]?.cores)}</p>
                                            </>
                                            :
                                            <Empty />}                               
                                        </PointBox>
                                    </Col>
                                    <Col xs={12}>
                                        <PointBox>
                                            <h4>End Point&nbsp;
                                                <Button onClick={() => handleCableConnectionEdit(details, 'end')} size="small" icon={<EditOutlined />} type="primary" title='Edit'></Button>
                                                <Button onClick={() => handleDelete(details?.id, 'end')} size="small" icon={<DeleteOutlined />} type="primary" danger title='Edit'></Button>
                                            </h4>
                                            {details?.connection_end_model ? 
                                            <>
                                            <p>Model: <strong>{details?.connection_end_model}</strong></p>
                                            <p>Name: <strong>{details?.connection_end_point?.name}</strong></p>
                                            <p>Latitude: <strong>{details?.connection_end_point?.location[0]}</strong></p>
                                            <p>Longitude: <strong>{details?.connection_end_point?.location[1]}</strong></p>
                                            
                                            <p>Unused: {coreCount(core[1]?.cores)}</p>
                                            </>
                                            :
                                            <Empty />
                                            }
                                        </PointBox>
                                        
                                        {/*                                 
                                        <Descriptions size="small" column="2">
                                            <Descriptions.Item label="Model">{details?.connection_end_model}</Descriptions.Item>
                                            <Descriptions.Item label="Latitude">{details?.connection_end_point?.location[0]}</Descriptions.Item>
                                            <Descriptions.Item label="Longitude">{details?.connection_end_point?.location[1]}</Descriptions.Item>
                                        </Descriptions> */}
                                    </Col>
                                    <br />
                                    {/* <br />
                                    <Col xs={12}>
                                        Get estimated breaking point
                                        <input type="text" onChange={(e) => bPointFnc(e.target.value)}/>
                                        <button onClick={getbPoint}>Get</button>
                                    </Col> */}
                                    <br />
                                    <br />
                                
                            </Row>
                        </PageHeader>
                        <PointBox style={{ display: 'flex', justifyContent: 'center', width: '100%'}}>
                            <MapContainer 
                                attributionControl={false}
                                center={details?.connection_start_point?.location ? details?.connection_start_point?.location : [23.785638540195837, 90.42570563577543]} 
                                zoom={14} 
                                // ref={mapRef}
                                style={{ height: '250px', width: '600px', alignSelf:'center' }}
                                // maxZoom={12}
                            >
                                {/* <ReactLeafletGoogleLayer 
                      apiKey='AIzaSyDslUl2FlUug8PaPRs2woRYjCi5EwqshVo' type={'satellite'} /> */}
                                <TileLayer
                                    url="https://fm.hidayahsmart.solutions/tile/{z}/{x}/{y}.png"
                                />
                                {details ?                       
                                    <FeatureGroup>
                                        {/* <EditControl
                                            position="topright"
                                            onEdited={_onEdited}
                                            // onEditVertex={_onEditVertex}
                                            onDrawVertex={_onEditVertex}
                                            edit={{
                                                remove: false,
                                                edit: true,
                                                create: false,
                                                draw: false,
                                                }}
                                            draw={{
                                            rectangle: false,
                                            circle: false,
                                            circlemarker: false,
                                            polygon: false,
                                            draw: false,
                                                marker: false,
                                                polyline: false,
                                            }}
                                        /> */}
                                        {details?.location_front?.length > 0 ? 
                                        <RecenterAutomatically lat={details?.location_front[0][0]} lng={details?.location_front[1][1]} />
                                        : null}
                                        
                                        {/* {    lineHelper(details?.location_front)} */}
                                
                                        {details?.location_front?.length > 0 ?
                                            <Polyline
                                                dashArray= {
                                                    details?.type === 'underground' ? '2, 5' :
                                                    details?.type === 'wireless' ? '5, 10' 
                                                    : null}
                                                pathOptions={{ 
                                                    color: details?.core === 2 ? '#ADD8E6' :
                                                            details?.core === 4 ? '#e87472' :
                                                            details?.core === 8 ? 'grey' :
                                                            details?.core === 12 ? '#CBC3E3' :
                                                            details?.core === 24 ? '#FED8B1' :
                                                            details?.core === 36 ? '#FFFFE0' :
                                                            details?.core === 48 ? '#90EE90' :
                                                        'black',
                                                    weight: '5',
                                                }}
                                                lineCap="Ok"
                                                positions={details?.location_front}
                                                
                                            ></Polyline>
                                        : null}
                                        {details?.connection_start_point?.location ?
                                                <Marker position={details?.connection_start_point?.location} icon={starticon()} >
                                                    <Popup>
                                                        <h4>Start point</h4>
                                                        <h5>Device: {details?.connection_start_point?.entity}</h5>
                                                        <h5>Name: {details?.connection_start_point?.name}</h5>
                                                    </Popup>
                                                </Marker>
                                                :null}
                                            
                                        {details?.connection_end_point?.location ?
                                                <Marker position={details?.connection_end_point?.location} icon={endicon()} >
                                                    <Popup>
                                                        <h4>End point</h4>
                                                        <h5>Device: {details?.connection_end_point?.entity}</h5>
                                                        <h5>Name:{details?.connection_end_point?.name}</h5>
                                                    </Popup>
                                                </Marker>
                                        :null}          
                                    </FeatureGroup>
                                :null}                    
                            </MapContainer>
                        </PointBox>
                    </TabPane>
                    <TabPane tab="Cores" key="2">
                        <Row>
                            <Col xs={12}>
                                <PointBox>
                                    <h4>Start</h4>
                                    {core[0]?.cores?.map((item, id) => (
                                        <p key={id}>Tube - {item?.sub_cable_no} <Tag color={item?.color}><span>{item?.used ? 'Used' : 'Unused'}</span></Tag><br /></p>
                                    ))}
                                </PointBox>
                            </Col>
                            <Col xs={12}>
                                <PointBox>
                                    <h4>End</h4>
                                    {core[1]?.cores?.map((item, id) => (
                                        <p key={id}>Sub Cabel - {item?.sub_cable_no} <Tag color={item?.color}><span>{item?.used ? 'Used' : 'Unused'}</span></Tag><br /></p>
                                    ))}
                                </PointBox>
                            </Col>
                        </Row>
                    </TabPane>
                </Tabs>
            </DetailBox>
        </Modal>
        {editDesData ?<CableDetailsUpdate allRefresh={refreshFnc} refresh={refresh} data={editDesData} visible={editDesModalVisible} onCancel={ () =>{ setEditDesModalVisible(false); setEditDesData(null)}} />:null}
        {editConnectionData ?<CableConnectionUpdate 
         allRefresh={refreshFnc}
        point={point}
        refresh={refresh} data={editConnectionData} 
        visible={editConModalVisible} onCancel={ () => setEditConModalVisible(false)}
        allPops={allPops} allClient={allClient} allJunction={allJunction}
        />:null}
        </>
     );
}
 
export default connect(null,{removeCableConnection})(CableDetails);