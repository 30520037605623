import { Button, Col, Drawer, Form, Input,  Row, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import { BottomBtn, PopWrapper } from './style'

import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { CaretRightOutlined, VerticalAlignMiddleOutlined } from '@ant-design/icons'
import { createNttn, getNttns, getNttnDetails} from '../../action/nttn';
import { connect } from 'react-redux';
import ClientDetails from './nttnDetails';
import { ScrollAble } from '../../styles/globalStyle';
const { Option } = Select;
const NttnList = ({getNttns, position = [], 
  setPopConnection, mapData, createNttn, getNttnDetails, fnc1, fnc2, data1, refreshApi }) => {
    const [createDiv, setCreateDiv] = useState(false)
    const [details, setDetails] = useState(null);
    const [visible, setVisible] = useState(false);
    const [allNttn, setAllNttn] = useState(false);
    const [form] = Form.useForm();
    useEffect( () => {
      window.scrollTo(0, 0)
      getData('')
    }, [])
    
  const getData = async (search = '') => {
    const res = await getNttns(search);
    if(res){
      setAllNttn(res)
    }
  }
  const onFinish = async (values) => {

    const res = await createNttn(values)
      if(res === 201){
        refreshApi()
        getData('')
        form.resetFields()
        setCreateDiv(false)
      }
    };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  // const options = [...
  //   data
  //     ? data?.map((item) =>
  //         createOption(
  //           item
  //         )
  //       )
  //     : null,
  // ];
  const handleFocus = async (item) => {
    fnc1(item); fnc2(item?.position)
  }
  const getDetails = async (id) => {
     const res = await getNttnDetails(id)
        if(res){
          setDetails(res)
          setVisible(true)
        }
  }
  const refresh = async (id) => {
     const res = await getNttnDetails(id)
        if(res){
          setDetails(res)
        }
  }
  const handleSearch = (e) => {
    getData(e)
  }
  
    return ( 
        <>
          <Drawer title="Create NTTN" placement="right" onClose={() => setCreateDiv(false)} visible={createDiv}>
              <h4 style={{textAlign: 'center', color: 'red'}}>*** Please click on the map to get coordinate**</h4>
              <br />
              <Form
                  name="basic"
                  labelCol={{
                  span: 8,
                  }}
                  wrapperCol={{
                  span: 16,
                  }}
                  initialValues={{
                  remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  form={form}
              >
                      <Form.Item
                          label="ID"
                          name="nttn_id"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Input />
                      </Form.Item>
                      <Form.Item
                          label="Name"
                          name="name"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Input />
                      </Form.Item>
                      <Form.Item
                          label="Lat"
                          name="lat"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Input disabled/>
                      </Form.Item>

                      <Form.Item
                          label="Long"
                          name="long"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Input disabled/>
                      </Form.Item>

                      <Form.Item
                          label="Address"
                          name="address"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Input.TextArea />
                      </Form.Item>

                      <Form.Item
                          label="Description"
                          name="description"
                      >
                          <Input.TextArea />
                      </Form.Item>

                      <Form.Item
                          wrapperCol={{
                          offset: 8,
                          span: 16,
                          }}
                      >
                          <Button type="primary" htmlType="submit">
                          Submit
                          </Button>
                      </Form.Item>
              </Form>
          </Drawer>  
          
          {details ?
                      <ClientDetails
                        details={details}
                        visible={visible}
                        refreshApi={refreshApi}
                        refresh={refresh}
                        closeFnc={() => {setDetails(null); setVisible(false)}}
                        // closeFnc={() => {setDetails(null); setVisible(false); fnc1(false)}}
                        mapData={mapData}
                      />
                    :null}
          {allNttn ?
          <>
          <Form.Item
            label=""
          >    
            <Input
              placeholder="Search NTTN"
              allowClear 
              onChange={e => handleSearch(e.target.value)}
            />
          </Form.Item>

          <ScrollAble>
            <PopWrapper>
              <Row onClick={() => {refreshApi(); setPopConnection(null)}}>
                <Col xs={3}>
                  <img src='./nttn.png'style={{width: '15px', marginBottom: '8px'}}/>
                </Col>
                <Col xs={18} onClick={() => getData()}>
                  <h6 style={{padding: '0px', fontWeight: 'bold'}}>
                    All / Refresh
                  </h6>
                </Col>
                <Col xs={3}><CaretRightOutlined style={{color: '#000000'}} /></Col>
              </Row>
            </PopWrapper>
            {allNttn?.map((item,id) => (
                <PopWrapper active={item} onClick={ () => {fnc1(item); fnc2(item?.position); getDetails(item?.id)}}>
                  <Row>

                    <Col xs={3}>
                      <img src='./nttn.png'style={{width: '15px', marginBottom: '8px'}}/>
                    </Col>
                    <Col xs={18} style={{}}>
                      <h6 style={{padding: '0px', fontWeight: 'bold',color: data1?.nttn_id === item?.nttn_id ? 'red' : null}}>
                        {item.name}
                      </h6>
                      <h6 style={{}}>{item.text}</h6>
                    </Col>
                    <Col xs={3}><CaretRightOutlined /></Col>
                  </Row>
                
                
              </PopWrapper>
            ))}
          </ScrollAble>
          </>
          :null}
          <BottomBtn onClick={() => {
            form.setFieldsValue({
              lat : position[0],
              long : position[1],
            });
            setCreateDiv(true)
            }}>Create New</BottomBtn>
        </>
     );
}
 
export default connect(null,{ createNttn, getNttns, getNttnDetails })(NttnList);