import history from '../history';
import api from './apis';

import {
    ERROR_MESSAGE,
    LOADED,
    LOADING,
    SUCCESS_MESSAGE,
    VALIDATION_ERROR,
} from './type';



  export const dashboardData= () => async (dispatch) => {
    
    dispatch({type: LOADING});
    
      try {
        const response = await api(localStorage.getItem("token")).get(
          "/dashboard"
        );
    
        if (response.status === 200) {
          
        dispatch({type: LOADED});
        return response.data;
        }
      } catch (err) {
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
          dispatch({type: LOADED});
        /* ===========End============*/
      }
  };

  