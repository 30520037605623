import axios from 'axios';
import history from '../history';
import api from './apis';

import {
    ERROR_MESSAGE,
    LOADED,
    LOADING,
    SUCCESS_MESSAGE,
    VALIDATION_ERROR,
} from './type';



  export const getPops = (search) => async (dispatch)=> {
      
      dispatch({type: LOADING});
      try{
          const response = await api(localStorage.getItem("token")).get(`/pop/list?search=${search}`);
          dispatch({type: LOADED});
          return response?.data?.list;
          
      }catch(err){
          /* =============Start========== */
            if(err?.response?.status === 422){
              err.response.data.errors.map(item => {
                  dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
              })
            }
            if(err?.response?.status === 401 || err?.response?.status === 403 ){
                dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
                localStorage.clear();
                history.push('/login')
            }
            dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
            dispatch({type: LOADED});
            /* ===========End============*/
      
  }
  }

  export const createPop = (data) => async (dispatch) => {
    
      dispatch({type: LOADING});
     
      const object = {
        name: data?.name,
        description: data?.description,
      };
    
      try {
        const response = await api(localStorage.getItem("token")).post(
          "/pop/create",
          { ...data }
        );
    
        if (response.status === 201) {
          
        dispatch({type: LOADED});
        dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
        return response.status;
        }
      } catch (err) {
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
          dispatch({type: LOADED});
        /* ===========End============*/
      }
    };

  export const getPopDetails = (pop_id) => async (dispatch)=> {
      
    dispatch({type: LOADING});
    try{
        const response = await api(localStorage.getItem("token")).get(`/pop/detail?pop_id=${pop_id}`);
        dispatch({type: LOADED});
        return response?.data;
        
    }catch(err){
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
          dispatch({type: LOADED});
          /* ===========End============*/
    
}
  }

  export const getPopConnections = (pop_id) => async (dispatch)=> {
      
    dispatch({type: LOADING});
    try{
        const response = await api(localStorage.getItem("token")).get(`/pop/connection?pop_id=${pop_id}`);
        dispatch({type: LOADED});
        return response?.data;
        
    }catch(err){
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
          dispatch({type: LOADED});
          /* ===========End============*/
    
}
  }

  export const updatePopDetails = (data) => async (dispatch) => {
    
    dispatch({type: LOADING});
    
  
    try {
      const response = await api(localStorage.getItem("token")).patch(
        "/pop/update/detail",
        { ...data }
      );
  
      if (response.status === 201) {
        
      dispatch({type: LOADED});
      dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
      return response.status;
      }
    } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
  };

  export const updatePopLocation = (data) => async (dispatch) => {
  
    dispatch({type: LOADING});
    
  
    try {
      const response = await api(localStorage.getItem("token")).patch(
        "/pop/update/location",
        { ...data }
      );
  
      if (response.status === 201) {
        dispatch({type: LOADED});
        dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
        return response.status;
      }
    } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
  };

  export const assignCore = (id,link_id) => async (dispatch) => {
    
    dispatch({type: LOADING});
   
    const object = {
      id: id,
      link_id: link_id,
    };
  
    try {
      const response = await api(localStorage.getItem("token")).post(
        "/core/assign",
        { ...object }
      );
  
      if (response.status === 201) {
        
      dispatch({type: LOADED});
      dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
      return response.status;
      }
    } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
  };

  export const coreAssign = (pop_id,core_id) => async (dispatch) => {
    
    dispatch({type: LOADING});
   
    const object = {
      pop_id: pop_id,
      core_id: core_id,
    };
  
    try {
      const response = await api(localStorage.getItem("token")).patch(
        "/pop/assign/core",
        { ...object }
      );
  
      if (response.status === 201) {
        
      dispatch({type: LOADED});
      dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
      return response.status;
      }
    } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
  };

  export const removeCoreAssign = (pop_id,core_id) => async (dispatch) => {
    
    dispatch({type: LOADING});
   
    const object = {
      pop_id: pop_id,
      core_id: core_id,
    };
  
    try {
      const response = await api(localStorage.getItem("token")).patch(
        "/pop/remove/core",
        { ...object }
      );
  
      if (response.status === 201) {
        
      dispatch({type: LOADED});
      dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
      return response.status;
      }
    } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
  };
  export const removeCore = (id,link_id) => async (dispatch) => {
    
    dispatch({type: LOADING});
   
    const object = {
      id: id,
      link_id: link_id,
    };
  
    try {
      const response = await api(localStorage.getItem("token")).post(
        "/core/remove",
        { ...object }
      );
  
      if (response.status === 201) {
        
      dispatch({type: LOADED});
      dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
      return response.status;
      }
    } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
  };

  export const deletePop = (pop_id, password) => async (dispatch) => {
    dispatch({type: LOADING});
    try {
      const response = await api(localStorage.getItem("token")).patch(
        "/pop/delete",
        { pop_id: pop_id,
          password: password  }
      );
  
      if (response.status === 201) {
        
      dispatch({type: LOADED});
      dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
      return response.status;
      }
    } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
  };

  export const deleteCable = (pop_id, cable_id) => async (dispatch) => {
    dispatch({type: LOADING});
    try {
      const response = await api(localStorage.getItem("token")).patch(
        "/pop/remove/cable",
        { 
          pop_id: pop_id,
          cable_id: cable_id,
         }
      );
  
      if (response.status === 201) {
        
      dispatch({type: LOADED});
      dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
      return response.status;
      }
    } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
  };



