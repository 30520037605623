import React, { useState, useRef } from 'react';
import { Button, Descriptions,Empty , Select, PageHeader, Form, Row, Col, Tabs, Modal, Tag, Popconfirm, Input, Popover } from 'antd';
import { CheckCircleOutlined, DeleteOutlined, EditOutlined, RightOutlined } from '@ant-design/icons';
import GponDetailsUpdate from './gponDetailsUpdate';
// import CableConnectionUpdate from './cableConnectionUpdate';
import {getGponDetails, updateGponLocation, deleteCable, deleteGpon} from '../../action/gpon'
import {connect}  from 'react-redux'
import * as L from "leaflet";
import {
    Marker,
    MapContainer,
    Popup,
    TileLayer,
    useMapEvents,
  } from 'react-leaflet'
// import { DetailBox, PointBox, ScrollAble } from '../../styles/globalStyle';
import { Content } from 'antd/lib/layout/layout';
import { assignCore, removeCore } from '../../action/pop';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ConnectionBox, DetailBox, InputCore, PointBox, ScrollAble, Selected } from '../../styles/globalStyle';

import DeleteConfirmation from '../../components/deleteConfirmation';
import './style.css'
import InCable from './inCable';
import GponOut from './gponOut';

const { TabPane } = Tabs;
const { Option } = Select;

const GponDetails = ({
    mapData,
    details, closeFnc, 
    visible, refresh, updateGponLocation, deleteCable,
    deleteFnc, refreshApi, deleteGpon, assignCore, removeCore,
    }) => {
        const LeafIcon = L.Icon.extend({
            options: {
              iconSize:     [40, 40],
           }
          });
        const icon = (data) => new LeafIcon({
            iconUrl:  `https://cdn.iconscout.com/icon/free/png-256/location-marker-1719247-1460733.png`,
            iconSize: [20, 20],
            iconAnchor: [12, 12],
            popupAnchor: [0, 0],
          })
    // const [id, setId] = useState(null);
    const [editDesData, setEditDesData] = useState(null);
    const [editDesModalVisible, setEditDesModalVisible] = useState(false);
    const [position, setPosition] = useState(null);
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const [core1, setCore1] = useState(null);
    const [core2, setCore2] = useState(null);
    const [cableId1, setCableId1] = useState(null);
    const [cableId2, setCableId2] = useState(null);
    const [keyNum, setKeyNum] = useState(null);
    const [refCable, setRefCable] = useState(null);
    const ref = useRef(null);
    const submitRef = useRef(null);

    const LocationFinderDummy = () => {
        const map = useMapEvents({
            click(e) {
                form.setFieldsValue({
                    gpon_id: details?.detail?.id,
                    lat : e.latlng.lat,
                    long : e.latlng.lng,
                }); 
              setPosition([e.latlng.lat, e.latlng.lng])
            },
        });
        return null;
    };
    
    const renderContent = (column = 3) => (
        <Descriptions size="small" column={column}>
          <Descriptions.Item label="Id"><strong>{details?.detail?.gpon_id}</strong></Descriptions.Item>
          <Descriptions.Item label="Address"><strong>{details?.detail?.address}</strong></Descriptions.Item> 
          <Descriptions.Item label="Description"><strong>{details?.detail?.description}</strong></Descriptions.Item>
        </Descriptions>
      );
      
    //   const Content = ({ children, extra }) => (
    //     <div className="content">
    //       <div className="main">{children}</div>
    //       <div className="extra">{extra}</div>
    //     </div>
    //   );
      const handleEdit = (data) => {
        setEditDesData(data?.detail)
        setEditDesModalVisible(true)
      }
    //   const handleCableConnectionEdit = (data, p) => {
    //     setPoint(p)
    //     setEditConnectionData(data)
    //     setEditConModalVisible(true)
    //   }
    //   const handleEdit = (id) => {
    //     setId(id); 
    //   }
    //   const handleDelete = async (id, point) => {
    //     const res =  await removeCableConnection(id, point)
    //     if(res === 201){
    //         refresh(id)
    //     }
    //   }
    const handleRemove = async (id) => {
        const res = await deleteCable(details?.detail?.id, id)
        if(res === 201){
            refresh(details?.detail?.id)
        }
    }
    const handleDelete = async (id, pass) => {
        closeFnc();
        const res = await deleteGpon(id, pass)
        if(res === 201){
            refreshApi()
        }
    }
    
      const onFinish = async(values) => {
        const res = await updateGponLocation(values)
        if(res === 201){
            refresh(details?.detail?.id)
            refreshApi()
        }
      }

    const handleCount = (item) => {
    let c = 0;
        c = c + item?._id?.cable_length
        item?.connection?.map((con) => {
            c =  c + con?.cable_length
        })
    
        return c
    }

    const capitalizeFirst = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    
    const handleData = (data) => {
        mapData(data)
        closeFnc(false)
    }

    const deleteContent = (id) => 
        ( <DeleteConfirmation id={id} fnc={deleteFnc} closeFnc={() => setOpen(false)} />
        )

        const constrolAssign = (data, key, cable_id) => {
            setKeyNum(key)
            if(core1 === null && core2 === null){
                
                setCore1(data)
                setCableId1(cable_id)
                if(core2 !== null){
                    submitRef.current.focus();
                }
            }
            else if(core1 !== null && core2 === null){
                setCore2(data)
                setCableId2(cable_id)
                if(core1 !== null){
                    submitRef.current.focus();
                }
            }
            else if(core2 !== null && core1 === null){
                setCore1(data)
                setCableId1(cable_id)
                if(core2 !== null){
                    submitRef.current.focus();
                }
            }
            else return
    }
    
    const handleAssign = async () => {
        const res = await assignCore(core1?.id, core2?.id)
        if(res === 201){
            setCore1(null)
            setCore2(null)
            setCableId1(null)
            setCableId2(null)
            setKeyNum(null)
            refresh(details?.detail?.id)
        }
        refreshApi()
    }
      
    const removeAssign = async (data) => {
        const res = await removeCore(data?.id, data?.r?.id)
        if(res === 201){
            setCore1(null)
            setCore2(null)
            setCableId1(null)
            setCableId2(null)
            setKeyNum(null)
            refresh(details?.detail?.id)
        }
        refreshApi()
    
    }
    const handleClick = () => {
        ref.current.focus();
        };
    return ( 
        <>
        <Modal
        title="Gpon Details"
        centered
        visible={visible}
        onCancel={() => closeFnc(false)}
        cancelText="Close"
        width={1000}
        okButtonProps={{
            style: {
            display: "none",
            },
        }}
    >
        <DetailBox>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Details" key="1">
                <PageHeader
                        className="site-page-header-responsive"
                        title={details?.detail?.name ? capitalizeFirst(details?.detail?.name) : details?.detail?.name}
                        // subTitle={details?.detail?.active ? 'Active' : 'Inactive'}
                        extra={[
                            <Button onClick={() =>{ handleEdit(details)}} size="small" icon={<EditOutlined />} type="primary" title='Edit'></Button>,
                            <Button size="small" icon={<DeleteOutlined />} onClick={() => setOpen(true)} type="primary" title='Edit' danger>Delete Gpon</Button>
                        ]}
                    >
                        {open ? 
                            <DeleteConfirmation 
                                id={details?.detail?.id}
                                fnc={handleDelete}
                                open={open} 
                                closeFnc={() => setOpen(false)}
                            /> 
                        : null}
                        <Content>{renderContent()}</Content>
                        {
                        details?.connectionPath?.length > 0
                        ?
                        <ScrollAble 
                            maxHeight="310px"
                            backgroundColor= '#eeeeee'
                            padding= '10px'
                            width= '100%'
                            >
                            
                            {details?.connectionPath?.map((item, key) => (
                                <>
                                    <div xs={24}>
                                        <h4 style={{ textAlign: 'center',}} >Connection {key+1} <Button size='small' type="primary" onClick={() => handleData(item)}>View On Map</Button></h4>
                                    </div> 
                                    <div key={key} style={{display: 'flex'}}>
                                        
                                        <div style={{ width: '100px'}}>
                                            <Row>
                                                <Col xs={24} style={{ textAlign: 'center'}}><h5>Model</h5></Col>
                                                <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid #FFF`,}}><h5>Cable ID</h5></Col>
                                                <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid #FFF`,}}><h5>Name</h5></Col>
                                                <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid #FFF`,}}><h5>Color</h5></Col>
                                                <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid #FFF`,}}><h5>Core</h5></Col>
                                                <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid #FFF`,}}><h5>Distance</h5></Col>
                                            </Row>
                                        </div>
                                        <div style={{ width: '190px'}}>
                                            <Row>
                                                <Col xs={24}><h5 style={{ textAlign: 'center'}}>{item?._id?.connection_model} <RightOutlined style={{ color: item?._id?.color}}/></h5></Col>
                                                <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid ${item?._id?.color}`}}><h5 style={{ color: item?._id?.color }}>{item?._id?.cable_u_id}</h5></Col>
                                                <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid ${item?._id?.color}`}}><h5 style={{ color: item?._id?.color }}>{item?._id?.connection_name}</h5></Col>
                                                <Col xs={24} style={{ textTransform: 'capitalize', textAlign: 'center', borderTop: `1px solid ${item?._id?.color}`}}><h5 style={{ color: item?._id?.color }}>{item?._id?.color}</h5></Col>
                                                <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid ${item?._id?.color}`}}><h5 style={{ color: item?._id?.color }}>{item?._id?.cable_core}</h5></Col>
                                                <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid ${item?._id?.color}`}}><h5 style={{ color: item?._id?.color }}>{item?._id?.cable_length}</h5></Col>
                                                
                                                {/* {item?.connection?.map((con) => (
                                                    <>
                                                        <Col style={{ width: '150px'}} style={{ textAlign: 'center', borderTop: '1px solid orange'}}><h5 style={{ color: 'orange' , }}>Orange</h5></Col>
                                                    </>
                                                ))} */}
                                            </Row>
                                        </div>
                                        
                                        {item?.connection?.map((con) => (
                                            <>   
                                                <div style={{ width: '190px'}}>
                                                    <Row>
                                                        <Col xs={24}><h5 style={{ textAlign: 'center'}}>{con?.connection_model} <RightOutlined style={{ color: con?.color}}/></h5></Col>
                                                        <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid ${con?.color}`}}><h5 style={{ color: con?.color }}>{con?.cable_u_id}</h5></Col>
                                                        <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid ${con?.color}`}}><h5 style={{ color: con?.color }}>{con?.connection_name}</h5></Col>
                                                        <Col xs={24} style={{ textTransform: 'capitalize', textAlign: 'center', borderTop: `1px solid ${con?.color}`}}><h5 style={{ color: con?.color }}>{con?.color}</h5></Col>
                                                        <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid ${con?.color}`}}><h5 style={{ color: con?.color }}>{con?.cable_core}</h5></Col>
                                                        <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid ${con?.color}`}}><h5 style={{ color: con?.color }}>{con?.cable_length}</h5></Col>
                                                        
                                                        {/* {item?.connection?.map((con) => (
                                                            <>
                                                                <Col style={{ width: '150px'}} style={{ textAlign: 'center', borderTop: '1px solid orange'}}><h5 style={{ color: 'orange' , }}>Orange</h5></Col>
                                                            </>
                                                        ))} */}
                                                    </Row>
                                                </div>
                                            </>
                                        ))}
                                        
                                        <div style={{ width: '200px'}}>
                                            <Row>
                                                <Col xs={24} style={{ textAlign: 'center'}}><h5>&nbsp;</h5></Col>
                                                <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid #FFF`,}}><h5>Total Length</h5></Col>
                                                <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid #FFF`,}}><h5>{handleCount(item)}</h5></Col>
                                                <Col xs={24} style={{ textAlign: 'center', borderTop: `1px solid #FFF`,}}><h5>&nbsp;</h5></Col>
                                            </Row>
                                        </div>
                                    </div>
                                </>
                                ))}
                        </ScrollAble>
                    : null}
                        <hr />
                        <br />
                    <PointBox style={{ display: 'flex', justifyContent: 'center', width: '100%'}}>
                        <h3><strong>Update Location</strong></h3>
                        <Row>
                            <Col xs={14}>
                                <MapContainer 
                                    attributionControl={false}
                                    center={details?.detail?.location ? details?.detail?.location : [23.785638540195837, 90.42570563577543]} 
                                    zoom={15} 
                                    zoomControl={false}
                                    style={{ height: '250px', width: '100%', alignSelf:'center' }}
                                >
                                    
                                    <LocationFinderDummy />

                                    <TileLayer
                                        url="https://fm.hidayahsmart.solutions/tile/{z}/{x}/{y}.png"
                                    />
                                    {position ? 
                                        <Marker position={position} icon={icon()} >
                                            
                                        </Marker>
                                    : null}
                                    {details?.detail?.location ?
                                        <Marker position={details?.detail?.location} icon={icon()} >
                                        <Popup>
                                            {details?.detail?.name}
                                        </Popup>
                                        </Marker>
                                    :null}
                                    
                                </MapContainer>
                            </Col>
                            
                            <Col xs={2}></Col>
                            <Col xs={8}>
                                
                                <Form
                                name="update location"
                                labelCol={{
                                span: 24,
                                }}
                                wrapperCol={{
                                span: 24,
                                }}
                                initialValues={{
                                remember: true,
                                }}
                                onFinish={onFinish}
                                autoComplete="off"
                                form={form}
                            >
                                    <Form.Item
                                        label="Gpon ID"
                                        name="gpon_id"
                                        rules={[{ required: true, message: 'Required' }]}
                                        style={{display: 'none'}}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="Latitude"
                                        name="lat"
                                        rules={[{ required: true, message: 'Required' }]}
                                    >
                                        <Input disabled/>
                                    </Form.Item>
                                    <Form.Item
                                        label="Longitude"
                                        name="long"
                                        rules={[{ required: true, message: 'Required' }]}
                                    >
                                        <Input disabled/>
                                    </Form.Item>
                                    <Form.Item
                                        wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                        }}
                                    >
                                        <Button type="primary" htmlType="submit">
                                        Submit
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </PointBox>
                        
                </PageHeader>
                </TabPane>
                <TabPane tab="Connection" key="2">
                    {/* {details?.connection?.length > 0 ? 
                        <Row>
                            {details?.connection?.map((con, id) => (
                            <Col xs={24} key={id}>
                                <Row style={{boxShadow: '1px 1px 4px 1px rgba(0,0,0,0.2)',padding: '5px', margin: '5px 0px'}}>
                                <Col xs={8}>
                                    <PointBox>
                                        <div>
                                            <h4>Cable Details</h4>
                                            <h2>Cable Id: <strong>{con?.cable?.cable_id}</strong></h2>
                                            <h2>Core: <strong>{con?.cable?.core}</strong></h2>
                                            <Popconfirm title="Are you sure?" okText="Yes" cancelText='No' onConfirm={() => handleRemove(con?.cable?.id)}>
                                                <Button danger type='primary'>Remove</Button>
                                            </Popconfirm>
                                        </div>
                                    </PointBox>
                                </Col>
                                
                            <Col xs={16}>
                                <PointBox>
                                    <div>
                                        <h4>Core Details &nbsp; </h4>
                                        {con?.cores?.map((item, key) => (
                                            item?.r 
                                                ? 
                                                <Selected >
                                                    
                                                   <p style={ 
                                                        refCable === item?.id 
                                                        ? 
                                                        {backgroundColor: '#1abc9c', cursor: 'pointer'}
                                                         : 
                                                         refCable === item?.r?.id ?
                                                         {backgroundColor: '#1abc9c', cursor: 'pointer'}
                                                         :
                                                         {cursor: 'pointer'}
                                                        } onClick={() => {setRefCable(item?.r?.id);handleClick();}}>Tube -{item?.sub_cable_no}  &nbsp; 
                                                    <Tag 
                                                        color={item?.color}
                                                        
                                                        >
                                                            {refCable === item?.id ?
                                                                <InputCore value=" " ref={ref} id="message"  autocomplete="off" />
                                                            : null
                                                        }
                                                        <span>Connected</span></Tag>
                                                  

                                                    {core1 === null || core2 === null ?
                                                        keyNum === id ?
                                                        <Button size="small"disabled>Remove</Button>
                                                        :
                                                    <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => {removeAssign(item)}}>
                                                        <Button size="small"  type="primary"danger >Remove</Button>
                                                    </Popconfirm>
                                                    :
                                                    null
                                                    }
                                                    
                                                    {core1 !== null && core2 !== null ?
                                                    <Button onClick={() => constrolAssign(item, id, con?.cable?.cable_id)}  size="small"  type="primary" danger disabled >Select</Button>
                                                    :
                                                    null
                                                    }
                                                    
                                                    
                                                    
                                                    <span style={{ float: 'right'}}>
                                                        {item?.connection[0] ?
                                                        <>
                                                        Connected with - <strong>{ item?.connection[0]?.connection_model} ({ item?.connection[0]?.connection_name})</strong> 
                                                        </>
                                                        : 'Not Connected'}
                                                    </span>
                                                    <br />
                                                    </p>
                                                   
                                                    
                                                </Selected>
                                                :

                                                <Selected status ={core1?.id === item?.id || core2?.id === item?.id}  >
                                                    <p>Tube -{item?.sub_cable_no}  &nbsp; 
                                                    {item?.r ? 
                                                        <Tag color={item?.color} ><span> Used</span></Tag>
                                                    :
                                                    <Tag color={item?.color} className='glow-on-hover' style={{ color: item?.color}}><span> Unused</span></Tag>
                                                    }
                                                    {core1 === null || core2 === null ?
                                                        keyNum === id ?
                                                        <Button onClick={() => constrolAssign(item, id, con?.cable?.cable_id)}  size="small"  type="primary" disabled>Select</Button>
                                                        :
                                                        <Button onClick={() => constrolAssign(item, id, con?.cable?.cable_id)}  size="small"  type="primary" >Select</Button>
                                                    :
                                                    null
                                                    }
                                                    
                                                    {core1 !== null && core2 !== null ?
                                                    <Button onClick={() => constrolAssign(item, id, con?.cable?.cable_id)}  size="small"  type="primary"  disabled >Select</Button>
                                                    :
                                                    null
                                                    }
                                                    
                                                    <span style={{ float: 'right'}}>
                                                        {item?.connection[0] ?
                                                        <>
                                                        Connected with - <strong>{ item?.connection[0]?.connection_model} ({ item?.connection[0]?.connection_name}) </strong> 
                                                        </>
                                                        : 'Not Connected'}
                                                    </span>
                                                    <br />
                                                    </p>
                                                    
                                                </Selected>
                                            
                                        ))}
                                    </div>
                                    
                                </PointBox>
                            </Col>
                            
                                </Row>
                            </Col>
                            ))}
                            
                        </Row>
                    : <Empty />} */}
                    <InCable details={details} refresh={refresh} />
                    <GponOut details={details} refresh={refresh} />
                </TabPane>
            </Tabs>
        </DetailBox>
        
        </Modal>
        {editDesData ?<GponDetailsUpdate refresh={refresh} data={editDesData} visible={editDesModalVisible} onCancel={ () => {setEditDesModalVisible(false); setEditDesData(null)}} />:null}
        {/* {editConnectionData ?<CableConnectionUpdate 
        point={point}
        refresh={refresh} data={editConnectionData} 
        visible={editConModalVisible} onCancel={ () => setEditConModalVisible(false)}
        allPops={allPops} allClient={allClient} allJunction={allJunction}
        />:null} */}
        </>
    );
    }
 
export default connect(null,{getGponDetails, updateGponLocation, assignCore, removeCore, deleteCable, deleteGpon})(GponDetails);