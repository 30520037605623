import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Statistic } from "antd";
import {
  LikeOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { dashboardData } from "../../action/dashboard";
const Dashboard = ({ dashboardData }) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    const res = await dashboardData();
    setData(res);
  };
  return data ? (
    <div style={{ height: "81vh", padding: "10px", margin: "5px 50px" }}>
      <Row gutter={16}>
        <Col span={11} style={{ backgroundColor: "#FFF", padding: "10px" }}>
          <Statistic title="Total Client" value={data?.total_client} />
        </Col>
        <Col span={1}>&nbsp;</Col>
        <Col
          span={12}
          style={{
            backgroundColor: "#FFF",
            padding: "10px",
            display: "flex",
            // justifyContent: "space-between",
          }}
        >
          <Statistic title="Active Client" value={data?.active_client?.count} />
          <Statistic
            style={{ marginLeft: "10em" }}
            title="Inactive Client"
            value={data?.inactive_client.count ?? null}
          />
        </Col>
      </Row>
      <br />
      <br />
      <Row gutter={16}>
        <Col span={11} style={{ backgroundColor: "#FFF", padding: "10px" }}>
          <Statistic title="Total Cable" value={data?.total_cable} />
        </Col>
        <Col span={1}>&nbsp;</Col>
        <Col span={12} style={{ backgroundColor: "#FFF", padding: "10px" }}>
          <Statistic title="Total Junction" value={data?.total_junction} />
        </Col>
      </Row>
      <br />
      <br />
      <Row gutter={16}>
        <Col span={11} style={{ backgroundColor: "#FFF", padding: "10px" }}>
          <Statistic title="Total Pop" value={data?.total_pop} />
        </Col>
        <Col span={1}>&nbsp;</Col>
        <Col span={12} style={{ backgroundColor: "#FFF", padding: "10px" }}>
          <Statistic
            title="Total Length (km)"
            value={
              data?.total_length > 0
                ? (data?.total_length / 1000).toFixed(2)
                : "0"
            }
          />
        </Col>
      </Row>
      {/* <br /> */}
      <br />
      {data.cable_per_length.map((item) => (
        <Row
          gutter={16}
          style={{
            display: "flex",
            width: "100vw",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Col
            span={12}
            style={{
              display: "flex",
            }}
          >
            <Statistic
              title={
                <>
                  <span style={{ fontSize: "1.2rem" }}>Core - </span>
                  <span
                    style={{
                      fontWeight: "400",
                      color: "black",
                      fontSize: "1.25rem",
                      // display: 'inline',
                    }}
                  >
                    {item?.length_per_cable?.key}
                  </span>{" "}
                  <span style={{ fontSize: "1.25rem" }}>length (km) ---</span>
                </>
              }
              value={(item?.length_per_cable?.value / 1000).toFixed(2)}
              valueStyle={{ fontWeight: "400" }}
              style={{
                width: "80vw",
                display: "flex",
                gap: "0.75em",
                justifyContent: "center",
                placeContent: "center",
                alignItems: "center",
                backgroundColor: "white",
              }}
            />
          </Col>
        </Row>
      ))}
    </div>
  ) : null;
};

export default connect(null, { dashboardData })(Dashboard);
