import axios from 'axios';
import history from '../history';
import api from './apis';

import {
    ERROR_MESSAGE,
    LOADED,
    LOADING,
    SUCCESS_MESSAGE,
    VALIDATION_ERROR,
} from './type';



  export const getJunction = (search) => async (dispatch)=> {
      
      dispatch({type: LOADING});
      try{
          const response = await api(localStorage.getItem("token")).get(`/junction/list?search=${search}`);
          dispatch({type: LOADED});
          return response?.data?.list;
          
      }catch(err){
          /* =============Start========== */
            if(err?.response?.status === 422){
              err.response.data.errors.map(item => {
                  dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
              })
            }
            if(err?.response?.status === 401 || err?.response?.status === 403 ){
                dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
                localStorage.clear();
                history.push('/login')
            }
            dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
            dispatch({type: LOADED});
            /* ===========End============*/
      
  }
  }

  export const createJunction = (data) => async (dispatch) => {
    
      dispatch({type: LOADING});
     
      try {
        const response = await api(localStorage.getItem("token")).post(
          "/junction/create",
          { ...data }
        );
    
        if (response.status === 201) {
          
        dispatch({type: LOADED});
        dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
        return response.status;
        }
      } catch (err) {
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
          dispatch({type: LOADED});
        /* ===========End============*/
      }
    };
  
  export const getJunctionDetails = (junction_id) => async (dispatch)=> {
    
    dispatch({type: LOADING});
    try{
        const response = await api(localStorage.getItem("token")).get(`/junction/detail?junction_id=${junction_id}`);
        dispatch({type: LOADED});
        return response?.data;
        
    }catch(err){
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
          dispatch({type: LOADED});
          /* ===========End============*/
    
}
  }
  
  export const updateJunctionDetails = (data) => async (dispatch) => {
    
    dispatch({type: LOADING});
    
  
    try {
      const response = await api(localStorage.getItem("token")).patch(
        "/junction/update/detail",
        { ...data }
      );
  
      if (response.status === 201) {
        
      dispatch({type: LOADED});
      dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
      return response.status;
      }
    } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
  };
  
  export const updateJunctionLocation = (data) => async (dispatch) => {
  
    dispatch({type: LOADING});
    
  
    try {
      const response = await api(localStorage.getItem("token")).patch(
        "/junction/update/location",
        { ...data }
      );
  
      if (response.status === 201) {
        dispatch({type: LOADED});
        dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
        return response.status;
      }
    } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
  };
  
  export const deleteJunction = (junction_id, password) => async (dispatch) => {
    dispatch({type: LOADING});
    try {
      const response = await api(localStorage.getItem("token")).patch(
        "/junction/delete",
        {
          
            junction_id: junction_id,
            password: password 
        }
      );
  
      if (response.status === 201) {
        
      dispatch({type: LOADED});
      dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
      return response.status;
      }
    } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
  };
  
  export const deleteCable = (junction_id, cable_id) => async (dispatch) => {
    dispatch({type: LOADING});
    try {
      const response = await api(localStorage.getItem("token")).patch(
        "/junction/remove/cable",
        { 
          junction_id: junction_id,
          cable_id: cable_id,
          }
      );
  
      if (response.status === 201) {
        
      dispatch({type: LOADED});
      dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
      return response.status;
      }
    } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
  };
  
