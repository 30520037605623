import axios from 'axios';
import history from '../history';
import api from './apis';

import {
    ERROR_MESSAGE,
    LOADED,
    LOADING,
    SUCCESS_MESSAGE,
    VALIDATION_ERROR,
} from './type';



  export const getGpons = (search) => async (dispatch)=> {
      
      dispatch({type: LOADING});
      try{
          const response = await api(localStorage.getItem("token")).get(`/gpon/list?search=${search}`);
          dispatch({type: LOADED});
          return response?.data?.list;
          
      }catch(err){
          /* =============Start========== */
            if(err?.response?.status === 422){
              err.response.data.errors.map(item => {
                  dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
              })
            }
            if(err?.response?.status === 401 || err?.response?.status === 403 ){
                dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
                localStorage.clear();
                history.push('/login')
            }
            dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
            dispatch({type: LOADED});
            /* ===========End============*/
      
  }
  }

  export const createGpon = (data) => async (dispatch) => {
    
      dispatch({type: LOADING});
    
      try {
        const response = await api(localStorage.getItem("token")).post(
          "/gpon/create",
          { ...data }
        );
    
        if (response.status === 201) {
          
        dispatch({type: LOADED});
        dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
        return response.status;
        }
      } catch (err) {
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
          dispatch({type: LOADED});
        /* ===========End============*/
      }
  };

  export const getGponDetails = (id) => async (dispatch)=> {
      
    dispatch({type: LOADING});
    try{
        const response = await api(localStorage.getItem("token")).get(`/gpon/detail?gpon_id=${id}`);
        dispatch({type: LOADED});
        return response?.data;
        
    }catch(err){
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
          dispatch({type: LOADED});
          /* ===========End============*/
    
}
  }

  export const updateGponDetails = (data, id) => async (dispatch) => {
    
    dispatch({type: LOADING});
    
    data.id = id;
    try {
      const response = await api(localStorage.getItem("token")).patch(
        "/gpon/update/detail",
        { ...data }
      );
  
      if (response.status === 201) {
        
      dispatch({type: LOADED});
      dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
      return response.status;
      }
    } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
  };

  export const updateGponLocation = (data) => async (dispatch) => {
  
    dispatch({type: LOADING});
    
  
    try {
      const response = await api(localStorage.getItem("token")).patch(
        "/gpon/update/location",
        { ...data }
      );
  
      if (response.status === 201) {
        dispatch({type: LOADED});
        dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
        return response.status;
      }
    } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
  };

  export const deleteGpon = (gpon_id, password) => async (dispatch) => {
    dispatch({type: LOADING});
    try {
      const response = await api(localStorage.getItem("token")).patch(
        "/gpon/delete",
        { 
          gpon_id: gpon_id,
          password: password,
        }
      );
  
      if (response.status === 201) {
        
      dispatch({type: LOADED});
      dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
      return response.status;
      }
    } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
  };

  export const deleteCable = (gpon_id, cable_id) => async (dispatch) => {
    dispatch({type: LOADING});
    try {
      const response = await api(localStorage.getItem("token")).patch(
        "/gpon/remove/cable",
        { 
          gpon_id: gpon_id,
          cable_id: cable_id,
         }
      );
  
      if (response.status === 201) {
        
      dispatch({type: LOADED});
      dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
      return response.status;
      }
    } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
  };


  export const assignCore = (gpon_id,core_id, splitter) => async (dispatch) => {
    
    dispatch({type: LOADING});
   
    const object = {
      gpon_id: gpon_id,
      core_id: core_id,
      splitter: splitter
    };
  
    try {
      const response = await api(localStorage.getItem("token")).patch(
        "/gpon/assign/core",
        { ...object }
      );
  
      if (response.status === 201) {
        
      dispatch({type: LOADED});
      dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
      return response.status;
      }
    } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
  };
export const removeCore = (gpon_id,core_id, splitter) => async (dispatch) => {
  
    dispatch({type: LOADING});
   
    const object = {
      gpon_id: gpon_id,
      core_id: core_id,
      splitter: splitter,
    };
  
    try {
      const response = await api(localStorage.getItem("token")).patch(
        "/gpon/remove/core",
        { ...object }
      );
  
      if (response.status === 201) {
        
      dispatch({type: LOADED});
      dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
      return response.status;
      }
    } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
  };

  export const cableHandle = (gpon_id,cable, type) => async (dispatch) => {
  
    dispatch({type: LOADING});
   
    const object = {
      gpon_id: gpon_id,
      cable_id: cable,
      type: type,
    };
  
    try {
      const response = await api(localStorage.getItem("token")).patch(
        "/gpon/input-cable",
        { ...object }
      );
  
      if (response.status === 201) {
        
      dispatch({type: LOADED});
      dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
      return response.status;
      }
    } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
  };