import { Button, Col, Drawer, Form, Input,  Row, Select, Checkbox, Divider } from 'antd';
import React, { useEffect, useState } from 'react'
import { BottomBtn, PopWrapper } from './style'

import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { CaretRightOutlined } from '@ant-design/icons'
import {getPops, createPop, getPopDetails, getPopConnections} from '../../action/pop';
import { connect } from 'react-redux';
import PopDetails from './popDetails';
import { ScrollAble } from '../../styles/globalStyle';
const CheckboxGroup = Checkbox.Group;
const { Option } = Select;
const Pop = ({ position = [], setCreatefnc, createFnc, 
  createPop, getPops, getPopDetails, getPopConnections, fnc1, fnc2, 
  data1, refreshApi, isFocusFnc, popList,cableList, clientList, junctionList,
   mapData, allData,popConnection, setPopConnection, 
}) => {
    const [details, setDetails] = useState(null);
    const [visible, setVisible] = useState(false);
    const [allPops, setAllPops] = useState(null);
    const [allCables, setAllCables] = useState(null);
    const [allClient, setAllClient] = useState(null);
    const [form] = Form.useForm();

    const [checkedList, setCheckedList] = useState([]);
    const [indeterminate, setIndeterminate] = useState(true);
    const [checkAll, setCheckAll] = useState(false);

    const [checkedCableList, setCheckedCableList] = useState([]);
    const [cableindeterminate, setCableIndeterminate] = useState(true);
    const [cablecheckAll, setCableCheckAll] = useState(false);

    
    useEffect( () => {
      window.scrollTo(0, 0)
      getData('')
    }, [])
    const onChange = (list) => {
      setCheckedList(list);
      setIndeterminate(!!list.length && list.length < allClient.length);
      setCheckAll(list.length === allClient.length);
      const client = [];
      list.map(item => {
        const foundValue = popConnection?.client?.filter(obj=>obj.client_id===item?.split('&')[1])
        client.push(...foundValue)
      })
      clientList(client)
    };

    const onChangeCable = (list) => {
      setCheckedCableList(list);
      setCableIndeterminate(!!list.length && list.length < allCables.length);
      setCableCheckAll(list.length === allCables.length);
      const cable = [];
      list.map(item => {
        const foundValue = popConnection?.cable?.filter(obj=>obj.cable_id===item)
        cable.push(...foundValue)
      })
      cableList(cable)
    };

    const onCheckAllChange = (e) => {
      if(e.target.checked){
        const client = [];
        allClient.map(item => {
          const foundValue = popConnection?.client?.filter(obj=>obj.client_id===item?.split('&')[1])
          client.push(...foundValue)
        })
        clientList(client)
      }
      else{
        clientList([])
      }
      setCheckedList(e.target.checked ? allClient : []);
      setIndeterminate(false);
      setCheckAll(e.target.checked);
    };

    const onCheckAllChangeCable = (e) => {
      if(e.target.checked){
        const cable = [];
        allCables.map(item => {
          const foundValue = popConnection?.cable?.filter(obj=>obj.cable_id===item)
          cable.push(...foundValue)
        })
        cableList(cable)
      }
      else{
        cableList([])
      }
      setCheckedCableList(e.target.checked ? allCables : []);
      setCableIndeterminate(false);
      setCableCheckAll(e.target.checked);
    };


    const getData = async (search = '') => {
      const res = await getPops(search);
      if(res){
        setAllPops(res)
      }
    }
    const onFinish = async (values) => {

      const res = await createPop(values)
        if(res === 201){
          getData()
          refreshApi()
          form.resetFields()
          setCreatefnc(false)
        }
    };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleFocus = (item) => {
    fnc1(item); fnc2(item?.position)
  }
  const getDetails = async (id) => {
    const connections = await getPopConnections(id)
    const client = [];
    const cable = [];
    for (let index = 0; index < connections?.client.length; index++) {
      // console.log(connections?.client[index]?.name + '(' + connections?.client[index]?.client_id + ')' )
      client.push(connections?.client[index]?.name + '&' + connections?.client[index]?.client_id )
    }
    for (let index = 0; index < connections?.cable.length; index++) {
      // console.log(connections?.client[index]?.name + '(' + connections?.client[index]?.client_id + ')' )
      cable.push(connections?.cable[index]?.cable_id)
    }
    setCheckedList(client)
    setAllClient(client)
    setCheckedCableList(cable)
    setAllCables(cable)
    popList([connections?.pop])
    cableList([...connections?.cable])
    clientList([...connections?.client])
    junctionList([])
    setPopConnection(connections)
    //  const res = await getPopDetails(id)
    //     if(res){
    //       isFocusFnc(false)
    //       setDetails(res)
    //       setVisible(true)
    //     }
  }
  const refresh = async (id) => {
     const res = await getPopDetails(id)
        if(res){
          setDetails(res)
        }
  }
  const handleSearch = (e) => {
    getData(e)
  }
    return ( 
        <>
          <Drawer title="Create POP" placement="right" onClose={() => setCreatefnc(false)} visible={createFnc}>
              <h4 style={{textAlign: 'center', color: 'red'}}>*** Please click on the map to get coordinate**</h4>
              <br />
              <Form
                  name="basic"
                  labelCol={{
                  span: 8,
                  }}
                  wrapperCol={{
                  span: 16,
                  }}
                  initialValues={{
                  remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  form={form}
              >
                      <Form.Item
                          label="ID"
                          name="pop_id"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Input />
                      </Form.Item>
                      <Form.Item
                          label="Name"
                          name="name"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Input />
                      </Form.Item>
                      <Form.Item
                          label="Lat"
                          name="lat"
                          rules={[{ required: true, message: 'Required' }]}
                          
                      >
                          <Input disabled/>
                      </Form.Item>

                      <Form.Item
                          label="Long"
                          name="long"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Input disabled/>
                      </Form.Item>

                      <Form.Item
                          label="Address"
                          name="address"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Input.TextArea />
                      </Form.Item>

                      <Form.Item
                          label="Note"
                          name="note"
                      >
                          <Input.TextArea />
                      </Form.Item>

                      <Form.Item
                          label="Description"
                          name="description"
                      >
                          <Input.TextArea />
                      </Form.Item>

                      <Form.Item
                          wrapperCol={{
                          offset: 8,
                          span: 16,
                          }}
                      >
                          <Button type="primary" htmlType="submit">
                          Submit
                          </Button>
                      </Form.Item>
              </Form>
          </Drawer>
          
          {details ?
            <PopDetails 
            details={details}
            visible={visible}
            refreshApi={refreshApi}
            closeFnc={() => {setDetails(null); setVisible(false)}}
            // closeFnc={() => {setDetails(null); setVisible(false); fnc1(false)}}
            refresh={refresh}
            mapData={mapData}
            />
          :null}

          {allPops ?
            <>
              <Form.Item
                label=""
              >    
                <Input
                  placeholder="Search POP"
                  allowClear 
                  onChange={e => handleSearch(e.target.value)}
                />
              </Form.Item>
              
              <ScrollAble>
                <PopWrapper>
                    <Row onClick={() => {allData(); setPopConnection(null)}}>
                      <Col xs={3}>
                        <img src='./POP.png'style={{width: '15px', marginBottom: '8px'}}/>
                      </Col>
                      <Col xs={18} style={{}}>
                        <h6 style={{padding: '0px', fontWeight: 'bold'}}>
                          All
                        </h6>
                      </Col>
                      <Col xs={3}><CaretRightOutlined style={{color: '#000000'}} /></Col>
                    </Row>
                  </PopWrapper>
                  {allPops?.map((item,id) => (
                    <PopWrapper active={item} onClick={ () => {fnc1(item); fnc2(item?.position);  getDetails(item?.id)}}>
                      <Row>
                        <Col xs={3}>
                          <img src='./POP.png'style={{width: '15px', marginBottom: '8px'}}/>
                        </Col>
                        <Col xs={18} style={{}}>
                          <h6 style={{padding: '0px', fontWeight: 'bold',color: data1.pop_id === item?.pop_id ? 'red' : null}}>
                            {item.name}
                          </h6>
                          <h6 style={{}}>{item.text}</h6>
                        </Col>
                        <Col xs={3}><CaretRightOutlined style={{color: '#000000'}} /></Col>
                      </Row>
                    </PopWrapper>
                  ))}
                  {popConnection ?
                    <ScrollAble style={{ backgroundColor: '#FFF', width: '200px',height: '300px',
                        position: 'absolute',top: '250px', left: '200px', 
                        cursor: 'pointer', padding: '5px' }}>
                          <h4 style={{ textAlign: 'center', padding: '5px', fontWeight: 'bold' }}>
                          <img src='./cable.png'style={{width: '30px', marginBottom: '8px'}}/>
                          &nbsp; Cables</h4>
                      {popConnection?.cable?.length > 0 ?
                        <>
                          <Checkbox indeterminate={cableindeterminate} onChange={onCheckAllChangeCable} checked={cablecheckAll}>
                          Check all
                          </Checkbox>
                          <CheckboxGroup options={allCables} value={checkedCableList} onChange={onChangeCable} />
                        </>
                        // popConnection?.cable?.map((cable => (
                        //   <Row>
                        //     <Col xs={3}>
                        //       <img src='./cable.png'style={{width: '15px', marginBottom: '8px'}}/>
                        //     </Col>
                        //     <Col xs={18} style={{}}>
                        //       <h6 style={{padding: '0px', fontWeight: 'bold'}}>
                        //         {cable?.cable_id}
                        //       </h6>
                        //       {/* <h6 style={{}}>{item.text}</h6> */}
                        //     </Col>
                        //     <Col xs={3}><CaretRightOutlined style={{color: '#000000'}} /></Col>
                        //   </Row>
                        // )))
                        :
                        <h6 style={{padding: '0px', textAlign: 'center', fontWeight: 'bold'}}>
                          No cable connected
                        </h6>
                      }
                          <Divider />
                      <h4 style={{ textAlign: 'center' }}>
                          <img src='./client.png'style={{width: '30px', marginBottom: '8px', borderRadius: '50%'}}/>
                          &nbsp;Client</h4>
                      {popConnection?.client?.length > 0 ?
                        <>
                          <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                          Check all
                          </Checkbox>
                          {/* <Divider /> */}
                          <CheckboxGroup options={allClient} value={checkedList} onChange={onChange} />
                        </>
                        // popConnection?.client?.map((client => (
                        //   <Row onClick={() => {handleFocus(client)}}>
                        //     <Col xs={3}>
                        //       <img src='./client.png'style={{width: '15px', marginBottom: '8px'}}/>
                        //     </Col>
                        //     <Col xs={18} style={{}}>
                        //       <h6 style={{padding: '0px', fontWeight: 'bold'}}>
                        //         {client?.name} ({client.client_id})
                        //       </h6>
                        //     </Col>
                        //     <Col xs={3}><CaretRightOutlined style={{color: '#000000'}} /></Col>
                        //   </Row>
                        // )))
                      :
                        <h6 style={{padding: '0px', textAlign: 'center',fontWeight: 'bold'}}>
                          No client connected
                        </h6>
                      }
                    </ScrollAble>
                  : null}
              </ScrollAble>
            </>
            :null
          }
          <BottomBtn onClick={() => {
            form.setFieldsValue({
              lat : position[0],
              long : position[1],
            });
            setCreatefnc(true)
            }}>Create New</BottomBtn>
        </>
     );
}
 
export default connect(null,{ createPop, getPops, getPopDetails, getPopConnections })(Pop);