import React from 'react'
import { Button, Input, Select, Form, Modal } from 'antd';
import { useEffect } from 'react';
import {connect} from 'react-redux';
import {updateJunctionDetails} from '../../action/junction';


const { Option } = Select;
const JunctionDetailsUpdate = ({ 
    visible, onCancel, data, 
    updateJunctionDetails, refresh,
}) => {
    useEffect(() => {
        form.setFieldsValue({
            id : data?.junction_id,
            junction_id : data?.id,
            name : data?.name,
            address : data?.address,
            description : data?.description,
            note : data?.note,
          });
    }, [])
    
    const [form] = Form.useForm();

    const onFinish = async (values) => {
        const code = await updateJunctionDetails(values, data?.id)
        if(code === 201){
            onCancel(false)
            refresh(data?.id)
        }
      }
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
    return ( 
        <Modal title="Edit Junction" visible={visible} onCancel={() =>onCancel(false)}
            okButtonProps={{
                style: {
                display: "none",
                },
            }}
          >
            <Form
                  name="basic"
                  labelCol={{
                  span: 8,
                  }}
                  wrapperCol={{
                  span: 16,
                  }}
                  initialValues={{
                  remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  form={form}
              >
                      <Form.Item
                          label="ID"
                          name="id"
                          rules={[{ required: true, message: 'Required' }]}
                        //   style={{display: 'none'}}
                      >
                          <Input />
                      </Form.Item>
                      <Form.Item
                          label="Junction ID"
                          name="junction_id"
                          rules={[{ required: true, message: 'Required' }]}
                          style={{display: 'none'}}
                      >
                          <Input />
                      </Form.Item>
                      <Form.Item
                          label="Name"
                          name="name"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Input />
                      </Form.Item>
                      <Form.Item
                          label="Address"
                          name="address"
                          rules={[{ required: true, message: 'Required' }]}
                      >
                          <Input.TextArea />
                      </Form.Item>
                      
                      <Form.Item
                          label="Note"
                          name="note"
                      >
                          <Input.TextArea />
                      </Form.Item>
                      
                      <Form.Item
                          label="Description"
                          name="description"
                      >
                          <Input.TextArea />
                      </Form.Item>

                      <Form.Item
                          wrapperCol={{
                          offset: 8,
                          span: 16,
                          }}
                      >
                          <Button type="primary" htmlType="submit">
                          Submit
                          </Button>
                      </Form.Item>
              </Form>
        </Modal>
     );
}
 
export default connect(null,{updateJunctionDetails})(JunctionDetailsUpdate);