import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import cableCut from "../cable/cableCut";
import Dashboard from "../dashboard";
// import pop from "../pop";
// import ClientList from "../clients";
// import JunctionList from "../junction";
import networkPoint from "../networkPoint";
import planing from "../planing";
import AssignCore from "../pop/assignCore";
import UserList from "../user";

const Switcher = () => {
  return (
    <Switch>
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/user" component={UserList} />
      <Route path="/network-point" component={networkPoint} />
      <Route path="/cable-cut" component={cableCut} />
      <Route path="/assign-core" component={AssignCore} />
      <Route path="/planing" component={planing} />
      {/* <Route path="/clients" component={ClientList} />
      <Route path="/junctions" component={JunctionList} /> */}
      <Redirect from="/" exact to="/dashboard" /> 
      {/* <Redirect to="/404" /> */}
    </Switch>
  );
};

export default Switcher;
