import React, { useState, useRef } from 'react';
import { Button, Descriptions,Empty , Select, PageHeader, Form, Row, Col, Tabs, Modal, Tag, Popconfirm, Input, Popover } from 'antd';
import { CheckCircleOutlined, DeleteOutlined, EditOutlined, RightOutlined } from '@ant-design/icons';
import GponDetailsUpdate from './gponDetailsUpdate';
// import CableConnectionUpdate from './cableConnectionUpdate';
import {getGponDetails, updateGponLocation, deleteCable, deleteGpon, cableHandle} from '../../action/gpon'
import {connect}  from 'react-redux'
import * as L from "leaflet";
import {
    Marker,
    MapContainer,
    Popup,
    TileLayer,
    useMapEvents,
  } from 'react-leaflet'
// import { DetailBox, PointBox, ScrollAble } from '../../styles/globalStyle';
import { Content } from 'antd/lib/layout/layout';
import { assignCore, removeCore } from '../../action/gpon';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ConnectionBox, DetailBox, InputCore, PointBox, ScrollAble, Selected } from '../../styles/globalStyle';

import DeleteConfirmation from '../../components/deleteConfirmation';
import './style.css'

const { TabPane } = Tabs;
const { Option } = Select;

const GponOut = ({
    mapData,
    details, closeFnc, 
    visible, refresh, updateGponLocation, deleteCable,
    deleteFnc, refreshApi, deleteGpon, cableHandle, assignCore, removeCore,
    }) => {
        const LeafIcon = L.Icon.extend({
            options: {
              iconSize:     [40, 40],
           }
          });
        const icon = (data) => new LeafIcon({
            iconUrl:  `https://cdn.iconscout.com/icon/free/png-256/location-marker-1719247-1460733.png`,
            iconSize: [20, 20],
            iconAnchor: [12, 12],
            popupAnchor: [0, 0],
          })
    // const [id, setId] = useState(null);
    const [editDesData, setEditDesData] = useState(null);
    const [editDesModalVisible, setEditDesModalVisible] = useState(false);
    const [position, setPosition] = useState(null);
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const [splitter, setSplitter] = useState(null);
    const [core2, setCore2] = useState(null);
    const [cableId1, setCableId1] = useState(null);
    const [cableId2, setCableId2] = useState(null);
    const [keyNum, setKeyNum] = useState(null);
    const [refCable, setRefCable] = useState(null);
    const [refCore, setRefCore] = useState(null);
    const [coreDetails, setCoreDetails] = useState(null);
    const ref = useRef(null);
    const submitRef = useRef(null);

    const LocationFinderDummy = () => {
        const map = useMapEvents({
            click(e) {
                form.setFieldsValue({
                    gpon_id: details?.detail?.id,
                    lat : e.latlng.lat,
                    long : e.latlng.lng,
                }); 
              setPosition([e.latlng.lat, e.latlng.lng])
            },
        });
        return null;
    };
    
    const renderContent = (column = 3) => (
        <Descriptions size="small" column={column}>
          <Descriptions.Item label="Id"><strong>{details?.detail?.gpon_id}</strong></Descriptions.Item>
          <Descriptions.Item label="Address"><strong>{details?.detail?.address}</strong></Descriptions.Item> 
          <Descriptions.Item label="Description"><strong>{details?.detail?.description}</strong></Descriptions.Item>
        </Descriptions>
      );
      
    //   const Content = ({ children, extra }) => (
    //     <div className="content">
    //       <div className="main">{children}</div>
    //       <div className="extra">{extra}</div>
    //     </div>
    //   );
      const handleEdit = (data) => {
        setEditDesData(data?.detail)
        setEditDesModalVisible(true)
      }
    //   const handleCableConnectionEdit = (data, p) => {
    //     setPoint(p)
    //     setEditConnectionData(data)
    //     setEditConModalVisible(true)
    //   }
    //   const handleEdit = (id) => {
    //     setId(id); 
    //   }
    //   const handleDelete = async (id, point) => {
    //     const res =  await removeCableConnection(id, point)
    //     if(res === 201){
    //         refresh(id)
    //     }
    //   }
    const handleRemove = async (id) => {
        const res = await deleteCable(details?.detail?.id, id)
        if(res === 201){
            refresh(details?.detail?.id)
        }
    }
    const handleCableRemove = async (type) => {
        const res = await cableHandle(details?.detail?.id, details?.detail?.in_cable?.id, 'remove')
        if(res === 201){
            refresh(details?.detail?.id,)
        }
    }
    
      const onFinish = async(values) => {
        const res = await updateGponLocation(values)
        if(res === 201){
            refresh(details?.detail?.id)
            refreshApi()
        }
      }

    const handleCount = (item) => {
    let c = 0;
        c = c + item?._id?.cable_length
        item?.connection?.map((con) => {
            c =  c + con?.cable_length
        })
    
        return c
    }

    const capitalizeFirst = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    
    const handleData = (data) => {
        mapData(data)
        closeFnc(false)
    }

    const deleteContent = (id) => 
        ( <DeleteConfirmation id={id} fnc={deleteFnc} closeFnc={() => setOpen(false)} />
        )

    const constrolAssign = (id, name) => {
            if( name === 'gpon') {
                setSplitter(id)
                if(core2 !== null){
                    submitRef.current.focus();
                }
            }
                if( name === 'core' ){
                    
                setCore2(id)
                if(splitter !== null){
                    submitRef.current.focus();
                }
                
            }
    }

    const handleAssign = async (id, splitter, state) => {
        if(state === 'assign'){
            const res = await assignCore(details?.detail?.id, id, splitter)
            if(res === 201){
                refresh(details?.detail?.id)
                setCore2(null)
                setSplitter(null)
            }
        }
    
        if(state === 'remove'){ 
            const res = await removeCore(details?.detail?.id, id, splitter)
            if(res === 201){
                refresh(details?.detail?.id)
            }
        }
    }
      
    const removeAssign = async (data) => {
        const res = await removeCore(data?.id, data?.r?.id)
        if(res === 201){
            setSplitter(null)
            setCore2(null)
            setCableId1(null)
            setCableId2(null)
            setKeyNum(null)
            refresh(details?.detail?.id)
        }
        refreshApi()
    
    }
    const handleClick = () => {
        ref.current.focus();
        };
        const handleCableAdd = async (id) => {
            const res = await cableHandle(details?.detail?.id, id, 'add')
            if(res === 201){
                refresh(details?.detail?.id,)
            }
        }
    return ( 
        <>
       
       {details?.detail?.output ? 
                        <Row>
                            <Col xs={24} >
                                <Row style={{boxShadow: '1px 1px 4px 1px rgba(0,0,0,0.2)',padding: '5px', margin: '5px 0px'}}>
                                    <Col xs={24}>
                                        <PointBox>
                                            <div style={{ width: '100%'}}>
                                                <h4>Gpon Out 
                                                </h4>
                                            </div>
                                        </PointBox>
                                    </Col>
                                    <Col xs={12}>
                                        <PointBox>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                <h2 style={{ alignSelf: 'center'}}>Gpon Configuration: <strong>1X{details?.detail?.splitter}</strong></h2>
                                            </div>
                                        </PointBox>
                                    </Col>
                                    <Col xs={12}>
                                        {Object.keys(details?.detail?.output).map((outputCore, key) => (
                                           
                                                
                                                    <PointBox>
                                                        <div>
                                                            {details?.detail?.output[outputCore] ?
                                                            
                                                                <p 
                                                                    style={ 
                                                                        refCable === outputCore 
                                                                        ? 
                                                                        {backgroundColor: '#1abc9c', cursor: 'pointer'}
                                                                        : 
                                                                        refCore === details?.detail?.output[outputCore] ?
                                                                        {backgroundColor: '#1abc9c', cursor: 'pointer'}
                                                                        :
                                                                        {cursor: 'pointer'}
                                                                    }
                                                                    onClick={() => { setRefCable(outputCore); setRefCore(details?.detail?.output[outputCore]) ;handleClick(); }}
                                                                >
                                                                Output - {outputCore} &nbsp;  
                                                                <Tag >
                                                                     {refCable === outputCore ?
                                                                        <InputCore value=" " ref={ref} id="message"  autocomplete="off" />
                                                                    : null}
                                                            <span> Used</span></Tag>
                                                            <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => {handleAssign(details?.detail?.output[outputCore], outputCore, 'remove')}}>
                                                            <Button   size="small"  type="danger">Remove</Button>
                                                                </Popconfirm>
                                                                
                                                                </p>
                                                            :
                                                                splitter === null ?
                                                                    <p>  
                                                                    Output - {outputCore} &nbsp;  
                                                                    <Tag ><span> Unused</span></Tag>
                                                                    <Button onClick={() => {constrolAssign(outputCore, 'gpon')}}  size="small"  type="primary">Select</Button>
                                                                    </p>
                                                                : 
                                                                <Selected status = {splitter === outputCore} key={key}>
                                                                    
                                                                <p>  
                                                                    Output - {outputCore} &nbsp;  
                                                                    <Tag ><span> Unused</span></Tag>
                                                                    <Button onClick={() => constrolAssign(outputCore, 'gpon')}  size="small"  type="primary" disabled>select</Button>
                                                                </p>
                                                                </Selected>
                                                            }
                                                        {/* {details?.detail?.output[outputCore] ?
                                                            <Selected>
                                                                <p 
                                                                    style={ 
                                                                    refCable === details?.detail?.output[outputCore] 
                                                                    ? 
                                                                    {backgroundColor: '#1abc9c', cursor: 'pointer'}
                                                                    : 
                                                                    refCable === outputCore ?
                                                                    {backgroundColor: '#1abc9c', cursor: 'pointer'}
                                                                    :
                                                                    {cursor: 'pointer'}
                                                                    }
                                                                
                                                                    onClick={() => {setRefCable(outputCore);handleClick();}}>Output - {outputCore} &nbsp;
                                                                  
                                                                    <Tag ><span> Used</span></Tag>
                                                                    
                                                                    
                                                                
                                                                    {
                                                                    splitter ?
                                                                    <Button onClick={() => constrolAssign(outputCore, 'gpon')}  size="small"  type="primary" disabled>Select</Button>
                                                                    :
                                                                // <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => {handleAssign(item,'remove')}}>
                                                                    <Button onClick={() => constrolAssign(outputCore, 'gpon')}  size="small"  type="primary" >Remove</Button>
                                                                // </Popconfirm>
                                                                
                                                                }
                                                               
                                                                <br />
                                                                </p>
                                                                </Selected>
                                                            :
                                                                <Selected >
                                                                    <p 
                                                                        style={ 
                                                                        refCable === details?.detail?.output[outputCore] 
                                                                        ? 
                                                                        {backgroundColor: '#1abc9c', cursor: 'pointer'}
                                                                        : 
                                                                        refCable === outputCore ?
                                                                        {backgroundColor: '#1abc9c', cursor: 'pointer'}
                                                                        :
                                                                        {cursor: 'pointer'}
                                                                        }
                                                                    
                                                                        onClick={() => {setRefCable(outputCore);handleClick();}}>Output - {outputCore} &nbsp;
                                                                       
                                                                        <Tag className='glow-on-hover' ><span> Unused</span></Tag>
                                                                   
                                                                        {
                                                                    splitter ?
                                                                    <Button onClick={() => constrolAssign(outputCore, 'gpon')}  size="small"  type="primary" disabled>Select</Button>
                                                                    :
                                                                // <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => {handleAssign(item,'remove')}}>
                                                                    <Button onClick={() => constrolAssign(outputCore, 'gpon')}  size="small"  type="primary" >Select</Button>
                                                                // </Popconfirm>
                                                                
                                                                }
                                                                    <br />
                                                                    </p>
                                                                </Selected>
                                                        } */}
                                                            
                                                        </div>
                                                    </PointBox>
                                                
                                            
                                        ))}
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} >
                            <br />
                            </Col>
                            {splitter && core2 ?                                      
                            <Col xs={24} >
                                <h3 style={{ padding: '4px', width: '100%', textAlign: 'center', fontWeight: 'bold'}} className='glow-on-hover'> 
                                    Gpon spliter output no <span style={{ fontWeight: 'bolder', fontSize: '22px' }}>{splitter}</span> connect with core {coreDetails}
                                    <Button type='primary' onClick={() => handleAssign(core2, splitter, 'assign')}>Connect</Button>
                                    <Button type='primary' onClick={() => {setCore2(null);setSplitter(null)}}>Reset</Button>
                                </h3>
                            </Col>
                            : null}
                            
                            <Col xs={24} >
                            <br />
                            </Col>

                            <Col xs={24} >
                                <Row style={{boxShadow: '1px 1px 4px 1px rgba(0,0,0,0.2)',padding: '5px', margin: '5px 0px'}}>
                                    <Col xs={24}>
                                        <PointBox>
                                            <div style={{ width: '100%'}}>
                                                <h4>Outcable Cable

                                                </h4>
                                            </div>
                                        </PointBox>
                                    </Col>
                                    
                                    {details?.connection?.map((con, id) => {
                               
                                    if(con?.cable?.id !== details.detail?.in_cable?.id) {
                                    return (
                                        <Col xs={24}>
                                        <Row style={{boxShadow: '1px 1px 4px 1px rgba(0,0,0,0.2)',padding: '5px', margin: '5px 0px'}}>
                                        <Col xs={8}> 
                                                
                                            <PointBox>
                                                <div style={{ width: '100%'}}>
                                                    <h4>Cable Details</h4>
                                                    <h2>Cable Id: <strong>{con?.cable?.cable_id}</strong></h2>
                                                    <h2>Core: <strong>{con?.cable?.core}</strong></h2>
                                                    <Popconfirm title="Are you sure?" okText="Yes" cancelText='No' onConfirm={() => handleRemove(con?.cable?.id)}>
                                                        <Button danger type='primary'>Delete Cable</Button>
                                                    </Popconfirm>
                                                    
                                                        &nbsp;
                                                    {details?.detail?.in_cable ? null : 
                                                    <Popconfirm title="Are you sure?" okText="Yes" cancelText='No' onConfirm={() => handleCableAdd(con?.cable?.id)}>
                                                        <Button  type='primary'>Add as Input Cable</Button>
                                                    </Popconfirm>
                                                    }
                                                </div>
                                            </PointBox>
                                        </Col>
                                        {/* &nbsp; */}
                                        <Col xs={16}>
                                            <PointBox>
                                                <div style={{ width: '100%'}}>
                                                    <h4>Core Details &nbsp; </h4>
                                                    {}
                                                    {con?.cores?.map((item, key) => (
                                                        item?.gpon_output
                                                            ? 
                                                            <Selected >
                                                                <p
                                                                    style={ 
                                                                        refCable === item?.gpon_output 
                                                                        ? 
                                                                        {backgroundColor: '#1abc9c', cursor: 'pointer'}
                                                                        : 
                                                                        refCable === item?.r?.id ?
                                                                        {backgroundColor: '#1abc9c', cursor: 'pointer'}
                                                                        :
                                                                        {cursor: 'pointer'}
                                                                    } 
                                                                    onClick={() => {setRefCable(item?.gpon_output); setRefCore(item?.id);handleClick();}}>
                                                                        Tube -{item?.sub_cable_no}  &nbsp; 
                                                                    <Tag 
                                                                        color={item?.color}
                                                                        >
                                                                            {refCable === item?.id ?
                                                                                <InputCore value=" " ref={ref} id="message"  autocomplete="off" />
                                                                            : null
                                                                        }
                                                                    <span>Connected</span>
                                                                    </Tag>
                                                            

                                                                {splitter === null || core2 === null ?
                                                                    keyNum === id ?
                                                                    <Button size="small"disabled>Remove</Button>
                                                                    :
                                                                <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => {handleAssign(item?.id, item?.gpon_output, 'remove')}}>
                                                                    <Button size="small"  type="primary"danger >Remove</Button>
                                                                </Popconfirm>
                                                                :
                                                                null
                                                                }
                                                                
                                                                {splitter !== null && core2 !== null ?
                                                                // <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => {handleAssign(item,'remove')}}>
                                                                    <Button onClick={() => constrolAssign(item?.id, 'core')}  size="small"  type="primary" danger disabled >Select</Button>
                                                                // </Popconfirm>
                                                                :
                                                                null
                                                                }
                                                                
                                                                
                                                                
                                                                <span style={{ float: 'right'}}>
                                                                    {item?.connection[0] ?
                                                                    <>
                                                                    Connected with - <strong>{ item?.connection[0]?.connection_model} ({ item?.connection[0]?.connection_name})</strong> 
                                                                    </>
                                                                    : 'Not Connected'}
                                                                </span>
                                                                <br />
                                                                </p>
                                                            
                                                                
                                                            </Selected>
                                                            :
                                                            core2 === null ?
                                                                <p>Tube -{item?.sub_cable_no}  &nbsp; 
                                                                {item?.connected ? 
                                                                    <Tag color={item?.color} ><span> Used</span></Tag>
                                                                :
                                                                <Tag color={item?.color} className='glow-on-hover' style={{ color: item?.color === 'white' ? 'black' : item?.color}}><span> Unused</span></Tag>
                                                                }
                                                                  
                                                                <Button onClick={() => {constrolAssign(item?.id, 'core'); 
                                                                setCoreDetails(`Cable ID ${con?.cable?.cable_id} , ${item?.color} core`)}}  size="small"  type="primary" >Select</Button>
                                                                
                                                                
                                                                <span style={{ float: 'right'}}>
                                                                    {item?.connection[0] ?
                                                                    <>
                                                                    Connected with - <strong>{ item?.connection[0]?.connection_model} ({ item?.connection[0]?.connection_name}) </strong> 
                                                                    </>
                                                                    : 'Not Connected'}
                                                                </span>
                                                                <br />
                                                                </p>
                                                            :
                                                            <Selected status = { core2 === item?.id} key={key}>
                                                                <p>Tube -{item?.sub_cable_no}  &nbsp; 
                                                                {item?.connected ? 
                                                                    <Tag color={item?.color} ><span> Used</span></Tag>
                                                                :
                                                                <Tag color={item?.color} className='glow-on-hover' style={{ color: item?.color === 'white' ? 'black' : item?.color}}><span> Unused</span></Tag>
                                                                }
                                                                
                                                                <Button onClick={() => constrolAssign(item?.id, 'core')}  size="small"  type="primary"  disabled >Select</Button>
                                                            
                                                                
                                                                <span style={{ float: 'right'}}>
                                                                    {item?.connection[0] ?
                                                                    <>
                                                                    Connected with - <strong>{ item?.connection[0]?.connection_model} ({ item?.connection[0]?.connection_name}) </strong> 
                                                                    </>
                                                                    : 'Not Connected'}
                                                                </span>
                                                                <br />
                                                                </p>
                                                                
                                                            </Selected>
                                                                    
                                                        
                                                    ))}
                                                </div>
                                                
                                            </PointBox>
                                        </Col>
                                        </Row>
                                        </Col>
                                
                                    )
                                }})
                            }
                        
                                </Row>
                            </Col>
                            
                            
                        </Row>
                    : 
                    
                    <Row>
                    <Col xs={24} >
                        <Row style={{boxShadow: '1px 1px 4px 1px rgba(0,0,0,0.2)',padding: '5px', margin: '5px 0px'}}>
                        <Col xs={24}>
                            <PointBox>
                                <div>
                                    <h4>Input Cable 
                                   
                                    </h4>
                                </div>
                            </PointBox>
                        </Col>
                        <Col xs={24}>
                            <Empty />
                        </Col>
                    
                        </Row>
                    </Col>
                    
                    
                </Row>}
                
       
        </>
    );
    }
 
export default connect(null,{getGponDetails, updateGponLocation, assignCore, removeCore, deleteCable, deleteGpon, cableHandle})(GponOut);